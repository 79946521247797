import {
    TextField,
    Button,
    Box,
    Typography
} from "@mui/material";
import { ParamTypes } from "../../../utilities/types";
import { useNavigate, useParams } from "react-router-dom";
import {
    Product,
    ProductsService,
    UpdateProductDto,
} from "../../../services/openapi";
import { CancelButton } from "../../common/CancelButton";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Spinner from "../../common/spinner";

export default function CreateAttribute() {
    const { providerId, productId } = useParams<
        keyof ParamTypes
    >() as ParamTypes;
    const [attribute, setAttribute] = useState("");
    const [product, setProduct] = useState<Product | null>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [serverError, setServerError] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        ProductsService.productControllerFind(
            productId,
        )
            .then((result) => {
                setProduct(result);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setServerError(error.message);
                setIsLoading(false);
            });
    }, [productId]);


    const onAttributeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
            
            setAttribute(e.target.value);
};

const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) : Promise<void> => {
    e.preventDefault();
    if (!attribute) {
        setError(true);
        setHelperText("This field is required");
        return;
    }
    if (product) {
        const existingAttribute = product.productAttrs.find(attr => attr.name === attribute);
        if (existingAttribute) {
            setError(true);
            setHelperText("An attribute with the same name already exists")
            return
        }
        let updatedProduct: UpdateProductDto = {
            name: product.name,
            brand: product.brand,
            shortDescription: product.shortDescription,
            longDescription: product.longDescription,
            productAttrs: [...product.productAttrs, {name: attribute, value: ""}],
            priceTypes: product.priceTypes,
            categories: product.productCategories.map(category => category.categoryId)
        };   
        try {
            await ProductsService.productControllerUpdate(productId, updatedProduct);
            navigate(`/providers/${providerId}/products/${productId}/attributes`);
        } catch (error: any) {
            console.error(error);
            setServerError(error.message);
        }
    }
};
const onCancelClickHandler = () => {
    navigate(`/providers/${providerId}/products/${productId}/attributes`);
};
    return (
        <>
        {serverError && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{serverError}</i>
                    </p>
                </div>
            )}
        {isLoading && <Spinner />}
        {!isLoading && !serverError && (
            <>
        <Box
            component="form"
            noValidate
            sx={{
                maxWidth: "400px",
            }}
            action="POST"
            onSubmit={onSubmitHandler}
        >
            <Typography
                component="h1"
                variant="h6"
                fontWeight={"bold"}
                mx={2}
                my={2}
            >
                Add Product Attribute
            </Typography>

            <Box mx={2} my={4}>
                <TextField
                    // sx={{ width: "50%" }}
                    fullWidth
                    required
                    label="Attribute name"
                    name="attribute"
                    id="attribute-name"
                    value={attribute}
                    onChange={onAttributeChangeHandler}
                    error={error}
                    helperText={helperText}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    mt: 2,
                }}
            >
                <CancelButton onCancelClickHandler={onCancelClickHandler} />
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        bgcolor: "#3e97ff",
                        fontWeight: "bold",
                    }}
                >
                    Save
                </Button>
            </Box>
        </Box>
        </>
            )}
        </>
    );
}
