/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataConsumer } from './DataConsumer';
import type { DataProvider } from './DataProvider';
import type { ImageMetadata } from './ImageMetadata';
import type { ObjectId } from './ObjectId';

export type User = {
    _id: ObjectId;
    authId: string;
    firstName: string;
    lastName: string;
    profileImage: ImageMetadata;
    email: string;
    primaryPhone: string;
    userRole: User.userRole;
    dataConsumer: DataConsumer;
    dataProvider: DataProvider;
    created: string;
    modified: string;
    deleted: string;
};

export namespace User {

    export enum userRole {
        NONE = 'none',
        PLATFORM_ADMINISTRATOR = 'PlatformAdministrator',
    }


}
