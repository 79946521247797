import { Link } from "react-router-dom";
import { Toolbar, Button, TextField } from "@mui/material";
import { ISearchCompanyToolbarProps } from "../../utilities/types";

export function SearchProviderToolbar({
    onSearchChangeHandler
}: ISearchCompanyToolbarProps) {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
                gap: 2,
                alignItems: "center"
                // justifyContent: "space-between",
            }}
        >
            <TextField
                id="outlined-search"
                label="Search"
                type="search"
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#eeedf1',
                    },
                  }}
                onChange={onSearchChangeHandler}
            />
            <Link to="/providers/create">
            <Button
                variant="contained"
                sx={{
                    bgcolor: "#3e97ff",
                    fontWeight: "bold",
                    // py: "6px",
                    // lineHeight: "38px",
                    // height: "40px",
                    // fontSize: "30px",
                    // display: "inline-block"
                }}
            >
                Add provider
            </Button>
            </Link>
        </Toolbar>
    );
};
