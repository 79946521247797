import {
    TextField,
    Button,
    Box,
    Typography,
    Autocomplete,
    createFilterOptions,
} from "@mui/material";
import { useFormCreateProvider } from "../../hooks/useFormCreateProvider";
import { useNavigate } from "react-router-dom";
import {
    CreateDataProviderDto,
    DataProvidersService,
    Price,
} from "../../services/openapi";
import { CancelButton } from "../common/CancelButton";
import { countries } from "../../utilities/utils";
import { useRef, useState } from "react";
import { providerElementRefs } from "../../utilities/types";

export default function CreateProvider() {
    const [error, setError] = useState<string>("");

    const initialValues: CreateDataProviderDto = {
        companyName: "",
        primaryAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            zipCode: "",
            city: "",
            countryCode: "",
        },
        webUrl: "",
        vatNumber: "",
        primaryPhone: "",
        primaryEmail: "",
        billingCurrency: "",
    };
    const navigate = useNavigate();
    const currencyArr = Object.values(Price.currency);
    const elementReferences: providerElementRefs = {
        nameRef: useRef<HTMLDivElement>(null),
        homePageRef: useRef<HTMLDivElement>(null),
        emailRef: useRef<HTMLDivElement>(null),
        phoneRef: useRef<HTMLDivElement>(null),
        addressOneRef: useRef<HTMLDivElement>(null),
        cityRef: useRef<HTMLDivElement>(null),
        countryRef: useRef<HTMLDivElement>(null),
        vatNumberRef: useRef<HTMLDivElement>(null),
        currencyRef: useRef<HTMLDivElement>(null),
    }

    const onCreateSubmitHandler = async (
        data: CreateDataProviderDto
    ): Promise<void> => {
        try {
            await DataProvidersService.dataProviderControllerCreate(data);
            navigate("/providers");
        } catch (error: any) {
            console.error(error);
            setError(error.message);
        }
    };

    const {
        values,
        onChangeHandler,
        formErrors,
        onCountryChangeHandler,
        onCurrencySelectHandler,
        onSubmit,
    } = useFormCreateProvider(initialValues, onCreateSubmitHandler, elementReferences);

    const onCancelClickHandler = () => {
        navigate("/providers");
    };

    return (
        <>
        {error && (
            <div
                id="error-page"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error}</i>
                </p>
            </div>
        )}
        {!error && <Box
            component="form"
            noValidate
            // sx={{
            //     maxWidth: "60%",
            // }}
            width="100%"
            action="POST"
            onSubmit={onSubmit}
        >
            <Typography
                component="h1"
                variant="h6"
                fontWeight="bold"
                sx={{ mb: 2 }}
            >
                Add Provider
            </Typography>
        <Box  sx={{border: "1px solid #0000001f", width: "100%", p: 3, borderRadius: "12px", mb: 3}}>
            <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            General information
                        </Typography>
            
            <TextField
     
            sx={{width: "60%"}}
            ref={elementReferences.nameRef}
                required
                label="Company name"
                name="companyName"
                variant="outlined"
                margin="dense"
                value={values.companyName}
                onChange={onChangeHandler("")}
                error={formErrors.companyName.error}
                helperText={
                    formErrors.companyName.error
                        ? formErrors.companyName.errorMessage
                        : ""
                }
            />
            <TextField
              sx={{width: "60%"}}
              ref={elementReferences.homePageRef}
                label="Homepage"
                name="webUrl"
                required
                variant="outlined"
                margin="dense"
                value={values.webUrl}
                onChange={onChangeHandler("")}
                error={formErrors.webUrl.error}
                helperText={
                    formErrors.webUrl.error
                        ? formErrors.webUrl.errorMessage
                        : "The url should match the format: www.example.example"
                }
            />
            </Box>
            <Box sx={{border: "1px solid #0000001f", width: "100%", p: 3, borderRadius: "12px", mb: 3}}>
            <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Contact information
                        </Typography>
            <TextField
             sx={{width: "60%"}}
             ref={elementReferences.emailRef}
                label="Company email"
                name="primaryEmail"
                variant="outlined"
                required
                margin="dense"
                value={values.primaryEmail}
                onChange={onChangeHandler("")}
                error={formErrors.primaryEmail.error}
                helperText={
                    formErrors.primaryEmail.error
                        ? formErrors.primaryEmail.errorMessage
                        : ""
                }
            />
            <TextField
             sx={{width: "60%"}}
             ref={elementReferences.phoneRef}
                label="Company main phone number"
                name="primaryPhone"
                variant="outlined"
                margin="dense"
                value={values.primaryPhone}
                onChange={onChangeHandler("")}
                error={formErrors.primaryPhone.error}
                helperText={
                    formErrors.primaryPhone.error
                        ? formErrors.primaryPhone.errorMessage
                        : ''
                }
            />
            </Box>
            <Box   sx={{border: "1px solid #0000001f", width: "100%", p: 3, borderRadius: "12px", mb: 3}}>
            <Typography

                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Address information
                        </Typography>
            <TextField
            sx={{width: "60%"}}
            ref={elementReferences.addressOneRef}
                label="Address line 1"
                name="addressLine1"
                variant="outlined"
                required
                margin="dense"
                value={values.primaryAddress.addressLine1}
                onChange={onChangeHandler("primaryAddress")}
                error={formErrors.addressLine1.error}
                helperText={
                    formErrors.addressLine1.error
                        ? formErrors.addressLine1.errorMessage
                        : ""
                }
            />
            <TextField
            sx={{width: "60%"}}
                label="Address line 2"
                name="addressLine2"
                variant="outlined"
                margin="dense"
                value={values.primaryAddress.addressLine2}
                onChange={onChangeHandler("primaryAddress")}
            />
            <TextField
            sx={{width: "60%"}}
                label="Address line 3"
                name="addressLine3"
                variant="outlined"
                margin="dense"
                value={values.primaryAddress.addressLine3}
                onChange={onChangeHandler("primaryAddress")}
            />
            <TextField
            sx={{width: "60%"}}
                label="ZIP code"
                name="zipCode"
                variant="outlined"
                margin="dense"
                value={values.primaryAddress.zipCode}
                onChange={onChangeHandler("primaryAddress")}
            />
            <TextField
            sx={{width: "60%"}}
            ref={elementReferences.cityRef}
                label="City"
                name="city"
                variant="outlined"
                required
                margin="dense"
                value={values.primaryAddress.city}
                onChange={onChangeHandler("primaryAddress")}
                error={formErrors.city.error}
                helperText={
                    formErrors.city.error ? formErrors.city.errorMessage : ""
                }
            />
            <Autocomplete
                id="country-select"
                ref={elementReferences.countryRef}
                sx={{ mt: 1, mb: 1 }}
                options={countries}
                autoHighlight
                autoSelect
                aria-required
                disableClearable={true}
                getOptionLabel={(option) => option.label}
                filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option.label,
                })}
                onChange={onCountryChangeHandler}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Country"
                        sx={{width: "60%"}}
                        required
                        inputProps={{
                            ...params.inputProps,
                        }}
                        error={formErrors.countryCode.error}
                        helperText={
                            formErrors.countryCode.error
                                ? formErrors.countryCode.errorMessage
                                : ""
                        }
                    />
                )}
            />
            </Box>
            <Box sx={{border: "1px solid #0000001f", width: "100%", p: 3, borderRadius: "12px", mb: 3}}>
            <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Billing information
                        </Typography>
                        <TextField
                         sx={{width: "60%"}}
                label="VAT number"
                ref={elementReferences.vatNumberRef}
                name="vatNumber"
                required
                variant="outlined"
                margin="dense"
                value={values.vatNumber}
                onChange={onChangeHandler("")}
                error={formErrors.vatNumber.error}
                helperText={
                    formErrors.vatNumber.error
                        ? formErrors.vatNumber.errorMessage
                        : "The VAT number should start with two capital letters"
                }
            />       
            <Autocomplete
                sx={{ mb: 0.5, }}
                id="currencies-select"
                ref={elementReferences.currencyRef}
                options={currencyArr.sort((a, b) => a.localeCompare(b))}
                autoHighlight
                autoSelect
                getOptionLabel={(option) => option}
                disableClearable={true}
                filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option,
                })}
                onChange={onCurrencySelectHandler}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Billing currency"
                        sx={{width: "60%"}}
                        required
                        inputProps={{
                            ...params.inputProps,
                        }}
                        error={formErrors.billingCurrency.error}
                        helperText={
                            formErrors.billingCurrency.error
                                ? formErrors.billingCurrency.errorMessage
                                : ""
                        }
                    />
                )}
            />

            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    mt: 2,
                }}
            >
                <CancelButton onCancelClickHandler={onCancelClickHandler} />
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        bgcolor: "#3e97ff",
                        fontWeight: "bold",
                    }}
                >
                    Save
                </Button>
            </Box>
        </Box>}
        </>
    );
}
