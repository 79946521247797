import { Category } from "../services/openapi";
import { Order } from "./types";

export function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getComparator<T>(
    order: Order,
    orderBy: keyof T 
): (
    a: T,
    b: T
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type CategoryWithChildren = Category & {
    subRows: CategoryWithChildren[]; // Recursive subarray of CategoryWithChildren
};

export function sortCategories(categories: CategoryWithChildren[]): CategoryWithChildren[] {
    // Sort the categories
    categories.sort((a, b) => a.name.localeCompare(b.name));

    // Sort the subcategories
    categories.forEach(category => {
        if (category?.subRows && category.subRows.length > 0) {
            category.subRows = sortCategories(category.subRows);
        }
    });

    return categories;
}
