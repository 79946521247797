/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateInLanguageDto = {
    lang: CreateInLanguageDto.lang;
    val: string;
};

export namespace CreateInLanguageDto {

    export enum lang {
        ZH = 'ZH',
        ES = 'ES',
        EN = 'EN',
        HI = 'HI',
        UR = 'UR',
        AR = 'AR',
        BN = 'BN',
        PT = 'PT',
        KO = 'KO',
        JA = 'JA',
        LA = 'LA',
        DE = 'DE',
        FR = 'FR',
        IT = 'IT',
        TR = 'TR',
        NL = 'NL',
        PL = 'PL',
        SV = 'SV',
        CS = 'CS',
        FI = 'FI',
        RO = 'RO',
        EL = 'EL',
        DA = 'DA',
        BG = 'BG',
        SL = 'SL',
        LT = 'LT',
        SK = 'SK',
        LV = 'LV',
        ET = 'ET',
        HR = 'HR',
        HU = 'HU',
        GA = 'GA',
        CY = 'CY',
        SQ = 'SQ',
        SR = 'SR',
        IS = 'IS',
        MT = 'MT',
        MK = 'MK',
        EU = 'EU',
        GD = 'GD',
        NO = 'NO',
        UK = 'UK',
        FO = 'FO',
        KU = 'KU',
        BS = 'BS',
        UX = 'UX',
    }


}
