import { Typography } from "@mui/material";

export default function AboutPage() {
    return (
        <>
            <Typography variant="h6"><b>Contact Sales:</b></Typography>
            <Typography variant="h6">+4526377477
            <br />
            Hello@arkforms.com</Typography>
            <br />
            <Typography variant="h6"><b>Office Address:</b></Typography>
            <Typography variant="h6">Arkforms
            <br />
            Frederiksgade 1
            <br />
            1265 Copenhagen K
            <br />
            Denmark</Typography>
                        
        </>
    );
}
