/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportResult } from '../models/ExportResult';
import type { ShopSettingsTest } from '../models/ShopSettingsTest';
import type { WooCommerceConnTest } from '../models/WooCommerceConnTest';
import type { WooCommerceProductCategoryClass } from '../models/WooCommerceProductCategoryClass';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShopExportService {

    /**
     * @param dataConsumerId 
     * @param dataAgreements 
     * @returns ExportResult 
     * @throws ApiError
     */
    public static shopExportControllerEksportProducts(
dataConsumerId: string,
dataAgreements: Array<string>,
): CancelablePromise<ExportResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/shop-export/StartExport',
            query: {
                'dataConsumerId': dataConsumerId,
                'dataAgreements': dataAgreements,
            },
        });
    }

    /**
     * @param dataConsumerId 
     * @param dataAgreements 
     * @returns any 
     * @throws ApiError
     */
    public static shopExportControllerRemoveShopExport(
dataConsumerId: string,
dataAgreements: Array<string>,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/shop-export/RemoveShopExport',
            query: {
                'dataConsumerId': dataConsumerId,
                'dataAgreements': dataAgreements,
            },
        });
    }

    /**
     * @param dataAgreementId 
     * @returns WooCommerceProductCategoryClass 
     * @throws ApiError
     */
    public static shopExportControllerGetShopCategories(
dataAgreementId: string,
): CancelablePromise<Array<WooCommerceProductCategoryClass>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/shop-export/shopCategories/{dataAgreementId}',
            path: {
                'dataAgreementId': dataAgreementId,
            },
        });
    }

    /**
     * @param dataAgreementId 
     * @returns WooCommerceConnTest 
     * @throws ApiError
     */
    public static shopExportControllerTestConsumerConnection(
dataAgreementId: string,
): CancelablePromise<WooCommerceConnTest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/shop-export/testConsumerConnection/{dataAgreementId}',
            path: {
                'dataAgreementId': dataAgreementId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns WooCommerceConnTest 
     * @throws ApiError
     */
    public static shopExportControllerTestConsumerConnectionRaw(
requestBody: ShopSettingsTest,
): CancelablePromise<WooCommerceConnTest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/shop-export/testConsumerConnectionWoo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
