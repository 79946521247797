import {
    TextField,
    Button,
    Box,
    Typography,
    Avatar,
    Grid,
    Paper,
} from "@mui/material";
import { ParamTypes } from "../../utilities/types";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, ChangeEvent } from "react";
import {
    UpdateUserDto,
    UploadImageInfo,
    UploadService,
    User,
    UsersService,
} from "../../services/openapi";
import Spinner from "../common/spinner";
import { CancelButton } from "../common/CancelButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import "../../utilities/import-sweet-alert.css";
import { useFormEditUser } from "../../hooks/useFormEditUser";

const imageButtonStyles = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "30px",
    height: "30px",
    minWidth: "0",
    // minHeight: "0",
    borderRadius: "50%",
    border: "grey",
    bgcolor: "white",
    boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
    "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
};
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const imageRegex = /^[a-zA-Z0-9_#-]+$/;

export default function EditUser() {
    const { userId, consumerId, providerId } = useParams<
        keyof ParamTypes
    >() as ParamTypes;
    const [isLoading, setIsLoading] = useState(true);
    const [currentImage, setCurrentImage] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState("");
    const [imageId, setImageId] = useState("");
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();
    const initialValues: UpdateUserDto = {
        firstName: "",
        lastName: "",
        email: "",
        primaryPhone: "",
        userRole: UpdateUserDto.userRole.NONE,
    };

    const onEditSubmitHandler = async (data: UpdateUserDto): Promise<void> => {
        try {
            setIsLoading(true);
            const user: User = await UsersService.usersControllerUpdate(
                userId,
                data
            );
            if (isImageChanged && imageId) {
                // delete image
                await UsersService.usersControllerDeleteProfileImage(userId);
            }
            // upload image
            if (currentImage && isImageChanged) {
                const imageExtension = currentImage.name.substring(currentImage.name.lastIndexOf(".") + 1);
                const uploadImageInfo: UploadImageInfo =
                    await UploadService.uploadControllerUploadImage(
                        currentImage.size, imageExtension
                    );
                await fetch(uploadImageInfo.uploadUrl, {
                    method: "PUT",
                    body: currentImage,
                    headers: { "Content-Type": currentImage.type },
                });
                await UsersService.usersControllerAddUserProfileImage(
                    user._id.toString(),
                    {
                        imageId: uploadImageInfo.imageId,
                        name: currentImage.name,
                        fileName: currentImage.name,
                        order: 0,
                        width: 0,
                        height: 0,
                        extension: "",
                    }
                );
            }

            if (providerId) {
                navigate(`/providers/${providerId}/users`);
            } else if (consumerId) {
                navigate(`/consumers/${consumerId}/users`);
            } else {
                navigate("/users");
            }
            setIsLoading(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };
    const { values, onChangeHandler, onSubmit, changeValues, formErrors } =
        useFormEditUser(initialValues, onEditSubmitHandler);

    useEffect(() => {
        UsersService.usersControllerFindOne(userId)
            .then((result) => {
                changeValues(result);
                setIsLoading(false);
                if (result.profileImage?.imageId) {
                    setPreviewImage(
                        `https://ik.imagekit.io/arkforms/datahubdev/tr:w-200,h-200/${result.profileImage.storageFilePath}`
                    );
                    setImageId(result.profileImage?.imageId);
                }
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [userId, changeValues]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (
                !imageRegex.test(file.name.slice(0, file.name.lastIndexOf(".")))
            ) {
                Swal.fire({
                    icon: "error",
                    text: 'The image name should contain only latin letters, numbers or the symbols "_", "#" and "-". No other characters or empty spaces allowed',
                });
                e.target.value = "";
                return;
            }
            if (file.size > 3500000) {
                Swal.fire({
                    icon: "error",
                    text: "The image size cannot exceed 3500 KB",
                });
                e.target.value = "";
                return;
            }
            setIsImageChanged(true);
            setCurrentImage(file);
            setPreviewImage(URL.createObjectURL(file));
            e.target.value = "";
        }
    };

    const onDeleteImageClick = () => {
        setIsImageChanged(true);
        setPreviewImage("");
        setCurrentImage(null);
    };

    const onCancelClickHandler = () => {
        if (providerId) {
            navigate(`/providers/${providerId}/users`);
        } else if (consumerId) {
            navigate(`/consumers/${consumerId}/users`);
        } else {
            navigate("/users");
        }
    };

    return (
        <>
         {error && (
            <div
                id="error-page"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error}</i>
                </p>
            </div>
        )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <Box
                    component="form"
                    noValidate
                    // sx={{
                    //     width: "30%",
                    // }}
                    action="POST"
                    onSubmit={onSubmit}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                    >
                        Edit User
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    position: "relative",
                                    width: 200,
                                    height: 200,
                                    mb: 2,
                                }}
                            >
                                <Avatar
                                    alt=""
                                    src={previewImage}
                                    sx={{
                                        bgcolor: "#eeedf1",
                                        width: "100%",
                                        height: "100%",
                                        boxShadow:
                                            "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                    }}
                                ></Avatar>
                                <Button
                                    component="label"
                                    aria-label="add-edit"
                                    sx={imageButtonStyles}
                                >
                                    {previewImage ? (
                                        <EditIcon
                                            fontSize="small"
                                            sx={{ color: "grey" }}
                                        />
                                    ) : (
                                        <AddIcon
                                            fontSize="small"
                                            sx={{ color: "grey" }}
                                        />
                                    )}
                                    <VisuallyHiddenInput
                                        type="file"
                                        accept=".jpg, .jpeg, .webp"
                                        onChange={handleChange}
                                    />
                                </Button>
                                {previewImage && (
                                    <Button
                                        onClick={onDeleteImageClick}
                                        component="label"
                                        aria-label="remove"
                                        sx={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            width: "30px",
                                            height: "30px",
                                            minWidth: "0",
                                            // minHeight: "0",
                                            borderRadius: "50%",
                                            border: "grey",
                                            bgcolor: "white",
                                            boxShadow:
                                                "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
                                            "&:hover": {
                                                backgroundColor: "#ffffff",
                                                boxShadow: "none",
                                            },
                                        }}
                                    >
                                        <DeleteIcon
                                            fontSize="small"
                                            sx={{ color: "grey" }}
                                        />
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={2}>
                                <Box
                                    // display={"flex"}
                                    sx={{ p: 3 }}
                                >
                                    <Typography
                                        component="h6"
                                        variant="h6"
                                        fontSize={"16px"}
                                        fontWeight={"bold"}
                                        // mx={2}
                                        mb={2}
                                    >
                                        Personal information
                                    </Typography>
                                    <TextField
                                        label="First Name"
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        sx={{ width: "60%", mb: 1 }}
                                        margin="dense"
                                        value={values.firstName}
                                        onChange={onChangeHandler}
                                        error={formErrors.firstName.error}
                                        helperText={
                                            formErrors.firstName.error
                                                ? formErrors.firstName
                                                      .errorMessage
                                                : ""
                                        }
                                    />
                                    <TextField
                                        label="Last Name"
                                        name="lastName"
                                        variant="outlined"
                                        required
                                        sx={{ width: "60%", mb: 1 }}
                                        margin="dense"
                                        value={values.lastName}
                                        onChange={onChangeHandler}
                                        error={formErrors.lastName.error}
                                        helperText={
                                            formErrors.lastName.error
                                                ? formErrors.lastName
                                                      .errorMessage
                                                : ""
                                        }
                                    />
                                    <TextField
                                        label="Phone"
                                        name="primaryPhone"
                                        variant="outlined"
                                        required
                                        sx={{ width: "60%", mb: 1 }}
                                        margin="dense"
                                        value={values.primaryPhone}
                                        onChange={onChangeHandler}
                                        error={formErrors.primaryPhone.error}
                                        helperText={
                                            formErrors.primaryPhone.error
                                                ? formErrors.primaryPhone
                                                      .errorMessage
                                                : ""
                                        }
                                    />
                                    <TextField
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        // required
                                        sx={{ width: "60%" }}
                                        margin="dense"
                                        value={values.email}
                                        disabled
                                        // onChange={onChangeHandler}
                                        // error={formErrors.email.error}
                                        // helperText={
                                        //     formErrors.email.error
                                        //         ? formErrors.email.errorMessage
                                        //         : ""
                                        // }
                                    />
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2,
                                    mt: 2,
                                }}
                            >
                                <CancelButton
                                    onCancelClickHandler={onCancelClickHandler}
                                />
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        bgcolor: "#3e97ff",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Save changes
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
