import { useState, useCallback, useEffect, MouseEvent, ChangeEvent } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
    Checkbox,
    TablePagination,
    TableFooter,
    Button
} from "@mui/material";
import { IHeadCell, Order, ValueOf } from "../../utilities/types";
import { EnhancedTableHead } from "./ProvidersListHead";
import { DeleteProvidersToolbar } from "./DeleteProvidersToolbar";
import { SearchProviderToolbar } from "./SearchProviderToolbar";
import { Link, useNavigate } from "react-router-dom";
import {
    DataProvider,
    DataProvidersService,
} from "../../services/openapi";
import Spinner from "../common/spinner";
import ActionButtonCompanies from "../common/ActionButtonCompanies";
import { countries } from "../../utilities/utils";
import { InfoIcon } from "../../utilities/InfoIcon/InfoIcon";

const collection = "providers";

export const ProvidersList = () => {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<ValueOf<IHeadCell>>("companyName");
    const [selected, setSelected] = useState<string[]>([]);
    const [data, setData] = useState<DataProvider[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [skipped, setSkipped] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();
    useEffect(() => {
        DataProvidersService.dataProviderControllerFindAllv1(
            searchQuery,
            rowsPerPage,
            skipped,
            order,
            orderBy
        )
            .then((result) => {
                setData(result.data);
                setTotalItems(result.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [
        rowsPerPage,
        skipped,
        order,
        orderBy,
        totalItems,
        searchQuery,
    ]);

    const onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setSkipped(0);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setSkipped(newPage * rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setSkipped(0);
    };

    const handleRequestSort = useCallback(
        (event: MouseEvent<unknown>, property: ValueOf<IHeadCell>) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );
    const handleCloseClick = () => {
        setSelected([]);
    };
    const handleDeleteClick = async () => {
        setIsLoading(true);
        const promises = selected.map((id) => DataProvidersService.dataProviderControllerRemove(id));
        try {
            await Promise.all(promises);
            // setSelected((state) => state.filter((x) => !selected.includes(x)));
            setTotalItems((state) => state - selected.length);
            setSelected([]);
            setPage(0);
            setSkipped(0);
            setSearchQuery("");
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
        
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n._id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onAddProviderButtonHandler = () => {
        navigate("/providers/create");
    }

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mx={2}
                        my={2}
                    >
                        Providers
                    </Typography>
                    {data.length === 0 && (
                        <Box
                            sx={{
                                mx: 2,
                                mt: 8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <InfoIcon />
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 24,
                                }}
                            >
                                Empty List
                            </Typography>
                            <Button onClick={onAddProviderButtonHandler}
                                variant="contained"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Add provider
                            </Button>
                        </Box>
                    )}
                        {data.length !== 0 && (
                        <>
                    {selected.length > 0 ? (
                        <DeleteProvidersToolbar
                            numSelected={selected.length}
                            selected={selected}
                            handleDeleteClick={handleDeleteClick}
                            handleCloseClick={handleCloseClick}
                        />
                    ) : (
                        <SearchProviderToolbar 
                        onSearchChangeHandler={onSearchChangeHandler}
                        />
                    )}
                    <Table
                        aria-label="simple table"
                        // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={data.length}
                            onSelectAllClick={handleSelectAllClick}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />

                        <TableBody>

                            {data.map((row, index) => {
                                const rowId = row._id.toString();
                                const isItemSelected = isSelected(rowId);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        key={rowId}
                                        hover
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        // tabIndex={-1}
                                        // selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                key={rowId}
                                                id={labelId}
                                                color="primary"
                                                size="small"
                                                checked={isItemSelected}
                                                onClick={(event) =>
                                                    handleClick(event, rowId)
                                                }
                                                sx={{ cursor: "pointer" }}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            key={rowId}
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            
                                            sx={{
                                                // p: 0.3,
                                                height: 60,
                                                position: "relative",
                                                width: "25%"
                                            }}
                                        >
                                            {/* <Box
                                            > */}
                                                     <Link
                                                    to={`/providers/${rowId}/products`}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign={"left"}
                                                        color={"#4b5679"}
                                                        sx={{
                                                            fontSize: 14,
                                                            cursor: "pointer",
                                                            ":hover": {
                                                                color: "#3e97ff",
                                                            },
                                                            fontWeight: "bold",
                                                            position: "absolute",
                                                            top: "50%",
                                                            transform: "translatey(-50%)"
                                                        }}
                                                    >
                                                   
                                                        {row.companyName}
                                                       
                                                    </Typography>
                                                    </Link>
                                            {/* </Box> */}
                                        </TableCell>
                                        <TableCell padding="none" sx={{ position: "relative", width: "20%" }} >
                                            {/* <Box > */}
                                                <Typography
                                                    textAlign={"left"}
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                        position: "absolute",
                                                        top: "50%",
                                                        transform: "translatey(-50%)"
                                                    }}
                                                >
                                                    {row.primaryAddress.city}
                                                </Typography>
                                            {/* </Box> */}
                                        </TableCell>
                                        <TableCell  padding="none" sx={{ position: "relative",  width: "20%" }}>
                                            {/* <Box  sx={{ position: "relative", }}> */}
                                                <Typography
                                                    textAlign={"left"}
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                        position: "absolute",
                                                        top: "50%",
                                                        transform: "translatey(-50%)"
                                                    }}
                                                >
                                                    {row.primaryAddress.countryCode && countries.filter(country => country.code === row.primaryAddress.countryCode)[0]?.label}
                                                </Typography>
                                            {/* </Box> */}
                                        </TableCell>
                                   
                                        <TableCell padding="none" sx={{ position: "relative",  width: "20%" }}>
                                            {/* <Box sx={{ position: "relative" }}>  */}
                                                <Typography
                                                    textAlign={"left"}
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                        position: "absolute",
                                                        top: "50%",
                                                        transform: "translatey(-50%)"
                                                    }}
                                                >
                                                    {row.webUrl}
                                                </Typography>
                                            {/* </Box> */}
                                        </TableCell>
                                        <TableCell padding="none" >
                                            <ActionButtonCompanies
                                                id={rowId}
                                                collection={collection}
                                                handleCheckClick={handleClick}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                             sx={{borderBottom: 0,
                                "& .MuiTablePagination-spacer": {
                                    display: "none",
                                  }}}
                            count={totalItems}
                            rowsPerPage={rowsPerPage}
                             page={page}
                            onPageChange={handleChangePage}
                             onRowsPerPageChange={handleChangeRowsPerPage}
                             showFirstButton
                             showLastButton
                             />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    </>
                    )}
                </>
            )}
        </>
    );
};
