import {
    TextField,
    Button,
    Box,
    Typography,
    Autocomplete,
    createFilterOptions,
} from "@mui/material";
import { ParamTypes } from "../../../utilities/types";
import { useNavigate, useParams } from "react-router-dom";
import {
    Price,
    Product,
    ProductsService,
    UpdateProductDto,
} from "../../../services/openapi";
import { CancelButton } from "../../common/CancelButton";
import {
    ChangeEvent,
    FormEvent,
    SyntheticEvent,
    useEffect,
    useState,
} from "react";
import Spinner from "../../common/spinner";

const currencyArr = Object.values(Price.currency);

export default function EditPriceType() {
    const { providerId, productId, priceTypeId } = useParams<
        keyof ParamTypes
    >() as ParamTypes;
    const [product, setProduct] = useState<Product | null>();
    const [isLoading, setIsLoading] = useState(true);
    const [priceType, setPriceType] = useState("");
    const [currency, setCurrency] = useState<Price.currency | null>(null);
    const [currencyError, setCurrencyError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        ProductsService.productControllerFind(productId)
            .then((result) => {
                setProduct(result);
                setCurrency(
                    result.priceTypes
                        .filter((x) => x === priceTypeId)[0]
                        .slice(0, 3) as Price.currency
                );
                setPriceType(
                    result.priceTypes
                        .filter((x) => x === priceTypeId)[0]
                        .slice(4)
                );
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [productId, priceTypeId]);

    const onPriceTypeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setPriceType(e.target.value);
    };

    const onCurrencySelectHandler = (
        e: SyntheticEvent,
        selectedValue: Price.currency | null
    ) => {
        if (selectedValue) {
            setCurrency(selectedValue);
        } else {
            setCurrency(null);
        }
    };

    const onSubmitHandler = async (
        e: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();
        if (!priceType && !currency) {
            setNameError(true);
            setCurrencyError(true);
            setHelperText("Both fields are required");
            return;
        } else if (!currency) {
            setCurrencyError(true);
            setNameError(false);
            setHelperText("Currency field is required");
            return;
        } else if (!priceType) {
            setNameError(true);
            setCurrencyError(false);
            setHelperText("Price type field is required");
            return;
        }
        if (product) {
            if (currency + " " + priceType === priceTypeId) {
                navigate(
                    `/providers/${providerId}/products/${productId}/price-types`
                );
                return;
            }
            const existingPriceType = product.priceTypes.find(
                (type) => type === currency + " " + priceType
            );
            if (existingPriceType) {
                setCurrencyError(true);
                setNameError(true);
                setHelperText("A price type with the same name already exists");
                return;
            }
            let updatedProduct: UpdateProductDto = {
                name: product.name,
                brand: product.brand,
                shortDescription: product.shortDescription,
                longDescription: product.longDescription,
                productAttrs: product.productAttrs,
                categories: product.productCategories.map(
                    (category) => category.categoryId
                ),
                priceTypes: product.priceTypes.map((type) =>
                    type === priceTypeId ? currency + " " + priceType : type
                ),
            };
            try {
                await ProductsService.productControllerUpdate(
                    productId,
                    updatedProduct
                );
                navigate(
                    `/providers/${providerId}/products/${productId}/price-types`
                );
            } catch (error: any) {
                console.error(error);
                setError(error.message);
            }
        }
    };
    const onCancelClickHandler = () => {
        navigate(`/providers/${providerId}/products/${productId}/price-types`);
    };
    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            maxWidth: "500px",
                        }}
                        action="POST"
                        onSubmit={onSubmitHandler}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={"bold"}
                            mx={2}
                            my={2}
                        >
                            Edit Price Type
                        </Typography>

                        <Box
                            display={"flex"}
                            // maxWidth="600px"
                            alignItems="center"
                            gap={1}
                            sx={{ mt: 4, mb: 1, mx: 2 }}
                        >
                            {/* <Box> */}
                            <Autocomplete
                                sx={{ width: "30%" }}
                                id="currencies-select"
                                options={currencyArr.sort((a, b) =>
                                    a.localeCompare(b)
                                )}
                                autoHighlight
                                autoSelect
                                getOptionLabel={(option) => option}
                                // disableClearable={true}
                                value={currency}
                                filterOptions={createFilterOptions({
                                    matchFrom: "start",
                                    stringify: (option) => option,
                                })}
                                onChange={onCurrencySelectHandler}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Currency"
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        error={currencyError}
                                    />
                                )}
                            />
                            <TextField
                                sx={{ width: "70%" }}
                                label="Price type"
                                name="priceType"
                                id="priceType"
                                required
                                value={priceType}
                                onChange={onPriceTypeChangeHandler}
                                error={nameError}
                            />
                        </Box>
                        <Box sx={{ mt: 1, mb: 4, mx: 2 }}>
                            <Typography
                                sx={{
                                    ml: "14px",
                                    color: "#d32f2f",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                }}
                            >
                                {helperText ? helperText : ""}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <CancelButton
                                onCancelClickHandler={onCancelClickHandler}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}
