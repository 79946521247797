/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateImageMetadataDto } from '../models/CreateImageMetadataDto';
import type { CreateProductDto } from '../models/CreateProductDto';
import type { DeleteImageMetadataDto } from '../models/DeleteImageMetadataDto';
import type { Product } from '../models/Product';
import type { ProductPaginatedResult } from '../models/ProductPaginatedResult';
import type { UpdateProductDto } from '../models/UpdateProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * @param search name,brand
     * @param limit default: 20
     * @param skip 
     * @param sortby1Direction 
     * @param filterDataProviderId 
     * @param sortby1 name,brand
     * @returns ProductPaginatedResult 
     * @throws ApiError
     */
    public static productControllerFindAll(
search?: string,
limit?: number,
skip?: number,
sortby1Direction?: 'asc' | 'desc',
filterDataProviderId?: any,
sortby1?: any,
): CancelablePromise<ProductPaginatedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/product',
            query: {
                'search': search,
                'limit': limit,
                'skip': skip,
                'sortby1Direction': sortby1Direction,
                'filterDataProviderId': filterDataProviderId,
                'sortby1': sortby1,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Product 
     * @throws ApiError
     */
    public static productControllerCreate(
requestBody: CreateProductDto,
): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Product 
     * @throws ApiError
     */
    public static productControllerFind(
id: string,
): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/product/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Product 
     * @throws ApiError
     */
    public static productControllerUpdate(
id: string,
requestBody: UpdateProductDto,
): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/product/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any 
     * @throws ApiError
     */
    public static productControllerDelete(
id: string,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/product/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param dataProviderId 
     * @param requestBody 
     * @returns Product 
     * @throws ApiError
     */
    public static productControllerCreateProductImage(
id: string,
dataProviderId: any,
requestBody: CreateImageMetadataDto,
): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/product/createImage/{id}',
            path: {
                'id': id,
            },
            query: {
                'dataProviderId': dataProviderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Product 
     * @throws ApiError
     */
    public static productControllerDeleteProductImage(
id: string,
requestBody: DeleteImageMetadataDto,
): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/product/deleteImage/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
