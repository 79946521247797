import { DataGrid, GridColDef, GridValidRowModel, GridCellModesModel, GridCellParams, GridCellModes } from '@mui/x-data-grid';
import * as React from 'react';
import {
  GridRowModesModel,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

// const initialRows: GridRowsProp = [
//   {
//     id: 1,
//     name: "John",
//     age: 25,
//     role: "Officer",
//   },
//   {
//     id: 2,
//     name: "Albert",
//     age: 25,
//     role: "Policeman",
//   },
//   {
//     id: 3,
//     name: "Smith",
//     age: 23,
//     role: "Broker",
//   },
//   {
//     id: 4,
//     name: "Mike",
//     age: 26,
//     role: "Dentist",
//   },
//   {
//     id: 5,
//     name: "Michael",
//     age: 33,
//     role: "Man",
//   },
// ];
type IPriceGridPros = {
    priceTypes: string[];
    onPriceTypesChange: (newPriceTypes: GridValidRowModel[]) => void;
}

  
export default function PriceGrid(props: IPriceGridPros) {
  const {priceTypes, onPriceTypesChange} = props;

  const initialRows: GridValidRowModel[] = priceTypes.map((priceType, index) => {
    return {
      id: index,
      priceType: priceType,
      currency: priceType.slice(0, 3),
      price: "",
    }
  });

  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [],
  );

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    [],
  );
  React.useEffect(() => {
    onPriceTypesChange(rows);
  }, [rows, onPriceTypesChange]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    // const handleEditClick = (id: GridRowId) => () => {
    //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    // };
  
    // const handleSaveClick = (id: GridRowId) => () => {
    //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    // };
  
    // const handleDeleteClick = (id: GridRowId) => () => {
    //   setRows(rows.filter((row) => row.id !== id));
    // };
  
    // const handleCancelClick = (id: GridRowId) => () => {
    //   setRowModesModel({
    //     ...rowModesModel,
    //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //   });
  
    //   const editedRow = rows.find((row) => row.id === id);
    //   if (editedRow!.isNew) {
    //     setRows(rows.filter((row) => row.id !== id));
    //   }
    // };
  
    const processRowUpdate = (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
  
    const columns: GridColDef[] = [
      {
        field: 'priceType',
        headerName: 'Price type',
        minWidth: 300,
      },
      {
        field: 'currency',
        headerName: 'Currency',
        minWidth: 150,
  
      },
      {
        field: 'price',
        headerName: 'Price',
        // type: 'number',
        minWidth: 150,
        editable: true,
      },
      // {
      //   field: 'actions',
      //   type: 'actions',
      //   headerName: 'Actions',
      //   // maxWidth: 100,
      //   cellClassName: 'actions',
      //   getActions: ({ id }) => {
      //     const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
      //     if (isInEditMode) {
      //       return [
      //         <GridActionsCellItem
      //           icon={<SaveIcon />}
      //           label="Save"
      //           sx={{
      //             color: 'primary.main',
      //           }}
      //           onClick={handleSaveClick(id)}
      //         />,
      //         <GridActionsCellItem
      //           icon={<CancelIcon />}
      //           label="Cancel"
      //           className="textPrimary"
      //           onClick={handleCancelClick(id)}
      //           color="inherit"
      //         />,
      //       ];
      //     }
  
      //     return [
      //       <GridActionsCellItem
      //         icon={<EditIcon />}
      //         label="Edit"
      //         className="textPrimary"
      //         onClick={handleEditClick(id)}
      //         color="inherit"
      //       />
      //       // <GridActionsCellItem
      //       //   icon={<DeleteIcon />}
      //       //   label="Delete"
      //       //   onClick={handleDeleteClick(id)}
      //       //   color="inherit"
      //       // />,
      //     ];
      //   },
      // },
    ];
    // const columns: GridColDef[] = [
    //   { field: 'name', headerName: 'Name', width: 180, editable: true },
    //   {
    //     field: 'age',
    //     headerName: 'Age',
    //     type: 'number',
    //     width: 80,
    //     align: 'left',
    //     headerAlign: 'left',
    //     editable: true,
    //   },
    // //   {
    // //     field: 'joinDate',
    // //     headerName: 'Join date',
    // //     type: 'date',
    // //     width: 180,
    // //     editable: true,
    // //   },
    //   {
    //     field: 'role',
    //     headerName: 'Department',
    //     width: 220,
    //     editable: true,
    //     type: 'singleSelect',
    //     valueOptions: ['Market', 'Finance', 'Development'],
    //   },
    //   {
    //     field: 'actions',
    //     type: 'actions',
    //     headerName: 'Actions',
    //     width: 100,
    //     cellClassName: 'actions',
    //     getActions: ({ id }) => {
    //       const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
    //       if (isInEditMode) {
    //         return [
    //           <GridActionsCellItem
    //             icon={<SaveIcon />}
    //             label="Save"
    //             sx={{
    //               color: 'primary.main',
    //             }}
    //             onClick={handleSaveClick(id)}
    //           />,
    //           <GridActionsCellItem
    //             icon={<CancelIcon />}
    //             label="Cancel"
    //             className="textPrimary"
    //             onClick={handleCancelClick(id)}
    //             color="inherit"
    //           />,
    //         ];
    //       }
  
    //       return [
    //         <GridActionsCellItem
    //           icon={<EditIcon />}
    //           label="Edit"
    //           className="textPrimary"
    //           onClick={handleEditClick(id)}
    //           color="inherit"
    //         />,
    //         <GridActionsCellItem
    //           icon={<DeleteIcon />}
    //           label="Delete"
    //           onClick={handleDeleteClick(id)}
    //           color="inherit"
    //         />,
    //       ];
    //     },
    //   },
    // ];
  
    return (
      // <Box
      //   sx={{
      //     height: 500,
      //     width: '100%',
      //     '& .actions': {
      //       color: 'text.secondary',
      //     },
      //     '& .textPrimary': {
      //       color: 'text.primary',
      //     },
      //   }}
      // >
        <DataGrid
        sx={{p: 2}}
          rows={rows}
          columns={columns}
          disableColumnMenu={true}
          initialState={{
            sorting: {
              sortModel:[{field: 'priceType', sort: 'asc'}],
            },
            pagination: { paginationModel: { pageSize: 5 }  }
          }}
          // editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          pageSizeOptions={[5, 10, 25]}
          hideFooterSelectedRowCount={true}
          // editMode="row"
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          
          // slots={{
          //   toolbar: EditToolbar,
          // }}
          // slotProps={{
          //   toolbar: { setRows, setRowModesModel },
          // }}
          
        />
      // </Box>
    );
  }