/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateImageMetadataDto } from '../models/CreateImageMetadataDto';
import type { CreatePriceDto } from '../models/CreatePriceDto';
import type { CreateProductVariantDto } from '../models/CreateProductVariantDto';
import type { DeleteImageMetadataDto } from '../models/DeleteImageMetadataDto';
import type { DeletePriceDto } from '../models/DeletePriceDto';
import type { ProductVariant } from '../models/ProductVariant';
import type { ProductVariantPaginatedResult } from '../models/ProductVariantPaginatedResult';
import type { UpdateImageMetadataDto } from '../models/UpdateImageMetadataDto';
import type { UpdatePriceDto } from '../models/UpdatePriceDto';
import type { UpdateProductVariantDto } from '../models/UpdateProductVariantDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductVariantsService {

    /**
     * @param id 
     * @returns ProductVariant 
     * @throws ApiError
     */
    public static productVariantControllerFind(
id: string,
): CancelablePromise<ProductVariant> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/product-variant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any 
     * @throws ApiError
     */
    public static productVariantControllerDeleteProductVariant(
id: string,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/product-variant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param filterProductId 
     * @param limit default: 20
     * @param skip 
     * @param search name
     * @returns ProductVariantPaginatedResult 
     * @throws ApiError
     */
    public static productVariantControllerGetProductVariants(
filterProductId: any,
limit?: number,
skip?: number,
search?: string,
): CancelablePromise<ProductVariantPaginatedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/product-variant',
            query: {
                'limit': limit,
                'skip': skip,
                'search': search,
                'filterProductId': filterProductId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProductVariant 
     * @throws ApiError
     */
    public static productVariantControllerCreateProductVariant(
requestBody: CreateProductVariantDto,
): CancelablePromise<ProductVariant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/product-variant/createProductVariant',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param productId Needed as we need to lookup the product for validation
     * @param requestBody 
     * @returns ProductVariant 
     * @throws ApiError
     */
    public static productVariantControllerUpdateProductVariant(
id: string,
productId: string,
requestBody: UpdateProductVariantDto,
): CancelablePromise<ProductVariant> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/product-variant/updateVariant/{id}',
            path: {
                'id': id,
            },
            query: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static productVariantControllerCreatePriceOnProductVariant(
id: string,
requestBody: CreatePriceDto,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/product-variant/createPrice/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static productVariantControllerUpdatePriceOnProductVariant(
id: string,
requestBody: UpdatePriceDto,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/product-variant/updatePrice/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static productVariantControllerDeletePriceOnProductVariant(
id: string,
requestBody: DeletePriceDto,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/product-variant/deletePrice/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param dataProviderId 
     * @param requestBody 
     * @returns ProductVariant 
     * @throws ApiError
     */
    public static productVariantControllerCreateProductVariantImage(
id: string,
dataProviderId: any,
requestBody: CreateImageMetadataDto,
): CancelablePromise<ProductVariant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/product-variant/createImage/{id}',
            path: {
                'id': id,
            },
            query: {
                'dataProviderId': dataProviderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductVariant 
     * @throws ApiError
     */
    public static productVariantControllerUpdateProductVariantImage(
id: string,
requestBody: UpdateImageMetadataDto,
): CancelablePromise<ProductVariant> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/product-variant/updateImage/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductVariant 
     * @throws ApiError
     */
    public static productVariantControllerDeleteProductVariantImage(
id: string,
requestBody: DeleteImageMetadataDto,
): CancelablePromise<ProductVariant> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/product-variant/deleteImage/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
