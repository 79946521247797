import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
    DataAgreementService,
    UpdateShopSettingsDto,
} from "../../services/openapi";
import { CancelButton } from "../common/CancelButton";
import { useState } from "react";
import { ParamTypes } from "../../utilities/types";
import Spinner from "../common/spinner";
import { useFormUpdateCredentials } from "../../hooks/useFormUpdateCredentials";

export default function ShopCredentials() {
    const [isLoading, setIsLoading] = useState(false);
    const { agreementId } = useParams<keyof ParamTypes>() as ParamTypes;
    const [error, setError] = useState<string>("");

    const initialValues: UpdateShopSettingsDto = {
        url1: "",
        key1: "",
        secret1: "",
        url2: "",
        key2: "",
        secret2: "",
    };
    const onCreateSubmitHandler = async (
        data: UpdateShopSettingsDto
    ): Promise<void> => {
        setIsLoading(true);
        try {
            await DataAgreementService.dataAgreementControllerUpdateShopSettings(
                agreementId,
                data
            );
            navigate("/agreements");
            setIsLoading(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const { onChangeHandler, onSubmit, formErrors, wooCommerceRef } =
        useFormUpdateCredentials(initialValues, onCreateSubmitHandler);

    const navigate = useNavigate();

    const onCancelClickHandler = () => {
        navigate("/agreements");
    };
    return (
        <>
         {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <Box
                    component="form"
                    noValidate
                    // sx={{
                    //     maxWidth: "60%",
                    // }}
                    width="100%"
                    action="POST"
                    onSubmit={onSubmit}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                    >
                        WooCommerce API Keys
                    </Typography>
                    <Box
                        ref={wooCommerceRef}
                        sx={{
                            border: "1px solid #0000001f",
                            width: "100%",
                            p: 3,
                            borderRadius: "12px",
                            mb: 3,
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            WooCommerce API Credentials
                        </Typography>

                        <TextField
                            sx={{ width: "60%" }}
                            label="WooCommerce URL"
                            name="url1"
                            variant="outlined"
                            required
                            margin="dense"
                            onChange={onChangeHandler}
                            error={formErrors.url1.error}
                            helperText={
                                formErrors.url1.error
                                    ? formErrors.url1.errorMessage
                                    : ""
                            }
                        />
                        <TextField
                            sx={{ width: "60%" }}
                            label="Consumer Key"
                            name="key1"
                            variant="outlined"
                            required
                            margin="dense"
                            onChange={onChangeHandler}
                            error={formErrors.key1.error}
                            helperText={
                                formErrors.key1.error
                                    ? formErrors.key1.errorMessage
                                    : ""
                            }
                        />
                        <TextField
                            sx={{ width: "60%" }}
                            label="Consumer Secret"
                            name="secret1"
                            variant="outlined"
                            required
                            margin="dense"
                            onChange={onChangeHandler}
                            error={formErrors.secret1.error}
                            helperText={
                                formErrors.secret1.error
                                    ? formErrors.secret1.errorMessage
                                    : ""
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            border: "1px solid #0000001f",
                            width: "100%",
                            p: 3,
                            borderRadius: "12px",
                            mb: 3,
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            WordPress API Credentials
                        </Typography>

                        <TextField
                            sx={{ width: "60%" }}
                            label="WordPress URL"
                            name="url2"
                            variant="outlined"
                            required
                            margin="dense"
                            onChange={onChangeHandler}
                            error={formErrors.url2.error}
                            helperText={
                                formErrors.url2.error
                                    ? formErrors.url2.errorMessage
                                    : ""
                            }
                        />
                        <TextField
                            sx={{ width: "60%" }}
                            label="Username"
                            name="key2"
                            variant="outlined"
                            required
                            margin="dense"
                            onChange={onChangeHandler}
                            error={formErrors.key2.error}
                            helperText={
                                formErrors.key2.error
                                    ? formErrors.key2.errorMessage
                                    : ""
                            }
                        />
                        <TextField
                            sx={{ width: "60%" }}
                            label="Application password"
                            name="secret2"
                            variant="outlined"
                            required
                            margin="dense"
                            onChange={onChangeHandler}
                            error={formErrors.secret2.error}
                            helperText={
                                formErrors.secret2.error
                                    ? formErrors.secret2.errorMessage
                                    : ""
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <CancelButton
                            onCancelClickHandler={onCancelClickHandler}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                bgcolor: "#3e97ff",
                                fontWeight: "bold",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
}
