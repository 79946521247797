/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ObjectId } from './ObjectId';

export type CreateDataConsumerUserDto = {
    userId: ObjectId;
    userRole: CreateDataConsumerUserDto.userRole;
};

export namespace CreateDataConsumerUserDto {

    export enum userRole {
        USER = 'USER',
        ADMIN = 'ADMIN',
    }


}
