import SuperTokens from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

SuperTokens.init({
    languageTranslations: {
        translations: { 
          en: {
            EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK: "Change password",
          },
        },
    },
    appInfo: {
        appName: "Arkforms",
        apiDomain: process.env.REACT_APP_BASE_URL as string,
        websiteDomain: process.env.REACT_APP_URL as string,
        // websiteDomain: "http://localhost:3000",
        apiBasePath: "/auth",
        websiteBasePath: "/auth",
    },
    
    recipeList: [
        EmailPassword.init({
            signInAndUpFeature: {
                signInForm: {
                    style: `
                        [data-supertokens~=headerSubtitle] {
                            display: none;
                        }

                        [data-supertokens~=button] {
                            background-color: #000000;
                            border-color: #000000;
                        }
                    `,
                },
                
            },
            resetPasswordUsingTokenFeature: {
                enterEmailForm: {
                    style: `  [data-supertokens~=button] {
                        background-color: #000000;
                        border-color: #000000;
                    }
                     `
                },
                submitNewPasswordForm: {
                    style: `  [data-supertokens~=button] {
                        background-color: #000000;
                        border-color: #000000;
                    }
                     `
                }
            },
            
        }),
        Session.init()
    ]
});

export {};