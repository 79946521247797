/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateImageMetadataDto } from '../models/CreateImageMetadataDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { User } from '../models/User';
import type { UserPaginatedResult } from '../models/UserPaginatedResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param search 
     * @param limit 
     * @param skip 
     * @param sortby1Direction 
     * @param sortby1 firstName,email
     * @returns UserPaginatedResult 
     * @throws ApiError
     */
    public static usersControllerFindAllv1(
search?: string,
limit?: number,
skip?: number,
sortby1Direction?: 'asc' | 'desc',
sortby1?: any,
): CancelablePromise<UserPaginatedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users',
            query: {
                'search': search,
                'limit': limit,
                'skip': skip,
                'sortby1Direction': sortby1Direction,
                'sortby1': sortby1,
            },
        });
    }

    /**
     * @param search 
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerFindAll(
search?: string,
): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerCreate(
requestBody: CreateUserDto,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerFindOne(
id: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerUpdate(
id: string,
requestBody: UpdateUserDto,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerRemove(
id: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerGetMe(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/me/getme',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static usersControllerAddUserProfileImage(
id: string,
requestBody: CreateImageMetadataDto,
): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{id}/profileImage',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns User 
     * @throws ApiError
     */
    public static usersControllerDeleteProfileImage(
id: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/deleteProfileImage/{id}',
            path: {
                'id': id,
            },
        });
    }

}
