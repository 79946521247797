import {
    useState,
    useCallback,
    useEffect,
    MouseEvent,
    ChangeEvent,
} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
    Checkbox,
    Button,
} from "@mui/material";
import { Order, ParamTypes } from "../../../utilities/types";
import {
    Product,
    ProductsService,
    UpdateProductDto,
} from "../../../services/openapi";
import Spinner from "../../common/spinner";
import { AddPriceTypeToolbar } from "./AddPriceTypeToolbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeletePriceTypesToolbar } from "./DeletePriceTypesToolbar";
import {  PriceTypesListHead } from "./PriceTypesListHead";
import ActionButtonPriceTypes from "../../common/ActionButtonPriceTypes";
import { InfoIcon } from "../../../utilities/InfoIcon/InfoIcon";

export const PriceTypesList = () => {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("priceTypes");
    const [selected, setSelected] = useState<string[]>([]);
    const [product, setProduct] = useState<Product | null>();
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortedPriceTypes, setSortedPriceTypes] = useState<string[]>([]); 
    const [error, setError] = useState<string>("");

    const { providerId, productId } = useParams<
        keyof ParamTypes
    >() as ParamTypes;

    const navigate = useNavigate();

    useEffect(() => {
        ProductsService.productControllerFind(productId)
            .then((result) => {
                setProduct(result);
                if(searchQuery) {
                    const queryResult = result.priceTypes.filter((type) => {
                        return type.toLowerCase().startsWith(searchQuery.toLowerCase());
                    });
                    order === "asc"
                    ? queryResult.sort((a, b) => a.localeCompare(b))
                    : queryResult.sort((a, b) => b.localeCompare(a))
                    setSortedPriceTypes(queryResult);
                } else {
                    order === "asc"
                    ? result.priceTypes.sort((a, b) => a.localeCompare(b))
                    : result.priceTypes.sort((a, b) => b.localeCompare(a))
                    setSortedPriceTypes(result.priceTypes);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [productId, order, searchQuery]);

    const onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleRequestSort = useCallback(
        (event: MouseEvent<unknown>, property: string) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );

    const handleCloseClick = () => {
        setSelected([]);
    };
    const handleDeleteClick = () => {
        // selected.forEach((id) => {

        if (product) {
            let updatedProduct: UpdateProductDto = {
                ...product,
                categories: product.productCategories.map(category => category.categoryId),
                priceTypes: product.priceTypes.filter((type) => {
                    return selected.indexOf(type) === -1;
                }),
            };
            ProductsService.productControllerUpdate(productId, updatedProduct)
                .then(() => {
                    setSortedPriceTypes((state) => {
                        const newState = [...state];
                        return newState.filter((type) => {
                            return selected.indexOf(type) === -1;
                        });
                    });
                    setSelected([]);
                })
                .catch((error) => {
                    console.error(error);
                    setError(error.message);
                });
        }

        // });
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = sortedPriceTypes.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onCancelClickHandler = () => {
        navigate(`/providers/${providerId}/products`);
    };
    const onAddPriceTypeButtonHandler = () => {
        navigate(`/providers/${providerId}/products/${productId}/price-types/create`);
    }
    // const visibleRows = useMemo(
    //     () =>
    //       stableSort(attributes, getComparator(order, orderBy))
    //     //   .slice(
    //         // page * rowsPerPage,
    //         // page * rowsPerPage + rowsPerPage,
    //     //   )
    //       ,
    //     [order, orderBy],
    //   );
    return (
        <>
         {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mx={2}
                        my={2}
                    >
                        Price Types
                    </Typography>
                    {sortedPriceTypes.length === 0 && (
                        <Box
                            sx={{
                                mx: 2,
                                mt: 8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <InfoIcon />
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 24,
                                }}
                            >
                                Empty List
                            </Typography>
                            <Button onClick={onAddPriceTypeButtonHandler}
                                variant="contained"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Add Price Type
                            </Button>
                        </Box>
                    )}
                       {sortedPriceTypes.length !== 0 && (
                        <>
                    {selected.length > 0 ? (
                        <DeletePriceTypesToolbar
                            numSelected={selected.length}
                            selected={selected}
                            handleDeleteClick={handleDeleteClick}
                            handleCloseClick={handleCloseClick}
                        />
                    ) : (
                        <AddPriceTypeToolbar
                            id={providerId}
                            productId={productId}
                            onSearchChangeHandler={onSearchChangeHandler}
                           
                        />
                    )}
                    <Table
                        aria-label="simple table"
                        // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
                    >
                        <PriceTypesListHead
                            numSelected={selected.length}
                            rowCount={sortedPriceTypes.length}
                            onSelectAllClick={handleSelectAllClick}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />

                        <TableBody>
                            {sortedPriceTypes.map((row, index) => {
                                const rowId: string = row;
                                const isItemSelected = isSelected(rowId);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        key={rowId}
                                        hover

                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        // tabIndex={-1}
                                        // selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                key={rowId}
                                                id={labelId}
                                                color="primary"
                                                size="small"
                                                checked={isItemSelected}
                                                onClick={(event) =>
                                                    handleClick(event, rowId)
                                                }
                                                sx={{ cursor: "pointer" }}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            key={rowId}
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                // p: 0.3,
                                                height: 60,
                                                position: "relative",
                                                width: "75%",
                                            }}
                                        >
                                            {/* <Box
                                            > */}
                                            <Link
                                                to={`/providers/${providerId}/products/${productId}/price-types/${rowId}/edit`}
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <Typography
                                                    textAlign={"left"}
                                                    color={"#4b5679"}
                                                    sx={{
                                                        fontSize: 14,
                                                        cursor: "pointer",
                                                        ":hover": {
                                                            color: "#3e97ff",
                                                        },
                                                        fontWeight: "bold",
                                                        position: "absolute",
                                                        top: "50%",
                                                        transform:
                                                            "translatey(-50%)",
                                                    }}
                                                >
                                                    {row}
                                                </Typography>
                                            </Link>
                                            {/* </Box> */}
                                        </TableCell>

                                        <TableCell
                                            sx={{ p: 0.3, maxWidth: "10%" }}
                                        >
                                            <ActionButtonPriceTypes
                                                productId={productId}
                                                providerId={providerId}
                                                priceType={rowId}
                                                handleCheckClick={handleClick}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow >
                                <TableCell sx={{  border: 0 }}></TableCell>
                                <TableCell sx={{  border: 0 }}></TableCell>
                                <TableCell
                                    sx={{ p: 0.3, maxWidth: "10%", border: 0, height: 60 }}
                                >
                    <Box >
                        <Button
                            onClick={onCancelClickHandler}
                            variant="text"
                            sx={{
                                bgcolor: "#e5e5e5",
                                color: "black",
                                textTransform: "none",
                                fontWeight: "bold",
                                // mt: 2,
                                // mr: 10,
                                // px: 2,
                                // py: 1,
                            }}
                        >
                            Return to Products
                        </Button>
                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </>
                    )}
                </>
            )}
        </>
    );
};
