import { Typography } from "@mui/material";
import { useState, useMemo } from "react";
import { Category, CategoryHierarchyService, CategoryService, ObjectId } from "../../../services/openapi";

import {
  MaterialReactTable,
  createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
} from 'material-react-table';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Swal from 'sweetalert2'
import "../../../utilities/sweet-alert.css";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

type CategoryWithChildren = Category & {
    subRows: CategoryWithChildren[]; // Recursive subarray of CategoryWithChildren
  };
  type MappedArray = { [key: string]: CategoryWithChildren };
    
export const CategoryList = () => {
  const [creatingRowIndex, setCreatingRowIndex] = useState<
    number | undefined
  >();
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const [hierarchyId, setHierarchyId] = useState<ObjectId | undefined>("");

    //READ hook (get users from api)
function useGetUsers() {
    return useQuery<CategoryWithChildren[]>({
      queryKey: ['categories'],
      queryFn: async () => {
        const categoryHierarchies = await CategoryHierarchyService.categoryHierarchyControllerFindall()
        const categoryHierarchyId = categoryHierarchies[0]._id.toString();
        setHierarchyId(categoryHierarchyId);
        const categories = await CategoryService.categoryControllerFindall(categoryHierarchyId);

        function unflatten(items: Category[]) {
            const tree = [],
                mappedArr: MappedArray = {}
                
            // Build a hash table and map items to objects
            items.forEach((item) => {
              const id = item._id.toString();
              if (!mappedArr.hasOwnProperty(id)) { // in case of duplicates
                mappedArr[id] = item as CategoryWithChildren; // the extracted id as key, and the item as value
                mappedArr[id].subRows = [];  // under each item, add a key "children" with an empty array as value
              }
            })
            
            // Loop over hash table
            for (let id in mappedArr) { 
              if (mappedArr.hasOwnProperty(id)) {
                let mappedElem = mappedArr[id];
                
                // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
                if (mappedElem.parentId) { 
                  const parentId = mappedElem.parentId.toString();
                //   check if the parent exists until the backend issue with deleting parent is fixed
                if(mappedArr[parentId]) {
                  mappedArr[parentId].subRows.push(mappedElem); 
                }
                }
                
                // If the element is at the root level, directly push to the tree
                else { 
                  tree.push(mappedElem);
                } 
              }
            }
            
            return tree;
            
          }
          const result = unflatten(categories);
          return result
      },
      refetchOnWindowFocus: false,
    });
  }
//    call READ hook
  const {
    data: fetchedCategories = [],
    isError: isLoadingCategoriesError,
    isFetching: isFetchingCategories,
    isLoading: isLoadingCategories,
  } = useGetUsers();

  //CREATE hook (post new user to api)
function useCreateCategory() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (category: CategoryWithChildren) => {
        if(!hierarchyId) {
            throw new Error('HierarchyId is undefined!');
        }
        //send api update request here
        await CategoryService.categoryControllerCreate({...category, categoryHierarchyId: hierarchyId});
      },
      //client side optimistic update
      onMutate: (newCategoryInfo: CategoryWithChildren) => {
        queryClient.setQueryData(['categories'], (_prevCategories: CategoryWithChildren[]) => {
          const prevCategories: CategoryWithChildren[] = JSON.parse(JSON.stringify(_prevCategories));
          newCategoryInfo.subRows = [];
          if (newCategoryInfo.parentId) {
            const parent = findCategoryInTree(newCategoryInfo.parentId.toString(), _prevCategories);
            if (parent) {
                parent.subRows = [
                ...(parent.subRows || []),
                {
                  ...newCategoryInfo,
                  _id: `${parent._id}.${(parent.subRows?.length || 0) + 1}`,
                },
              ];
            }
          } else {
            prevCategories.push({
              ...newCategoryInfo,
              _id: `${prevCategories.length + 1}`,
            });
          }
          return [...prevCategories];
        });
      },
      onSettled: () => queryClient.invalidateQueries({ queryKey: ['categories'] }), //refetch users after mutation
    });
  }
  
  // //UPDATE hook (put user in api)
function useUpdateCategory() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (category: CategoryWithChildren) => {
    //   //send api update request here
      await CategoryService.categoryControllerUpdate(category._id.toString(), {name: category.name});
    },
    //client side optimistic update
    // onMutate: (newCategoryInfo: CategoryWithChildren) => {
    //   queryClient.setQueryData(['categories'], (prevCategories: any) => {
    //     let category = findCategoryInTree(newCategoryInfo._id.toString(), prevCategories);
    //     category = { ...category, ...newCategoryInfo };
    //     return [...prevCategories];
    //   });
    // },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['categories'] }), //refetch users after mutation, disabled for demo
  });
}
// //DELETE hook (delete user in api)
function useDeleteCategory() {
    
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (categoryId: string) => {
      //send api update request here
      await CategoryService.categoryControllerDelete(categoryId);
    },
    onMutate: (categoryId: string) => {
        queryClient.setQueryData(['categories'], (prevCategories: any) => {
          const newCategories: CategoryWithChildren[] = JSON.parse(JSON.stringify(prevCategories));
          //remove category
          const category = findCategoryInTree(categoryId, newCategories);
          if (category) {
            const parent = findCategoryInTree(category.parentId?.toString(), newCategories);
            if (parent) {
              parent.subRows = parent.subRows?.filter(
                (subCategory) => subCategory._id !== category._id,
              );
            } else {
              return newCategories.filter((category) => category._id !== categoryId);
            }
          }
          return [...newCategories];
        });
      },
      onSettled: () => queryClient.invalidateQueries({ queryKey: ['categories'] }), //refetch users after mutation, disabled for demo
    });
}
const validateRequired = (value: string) => !!value.length;

function validateCategory(category: CategoryWithChildren) {
  return {
    categoryName: !validateRequired(category.name)
      ? 'Category Name is Required'
      : ''
      };
}

  const columns = useMemo<MRT_ColumnDef<CategoryWithChildren>[]>(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        enableEditing: false,
        // size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Category Name',
        // size: 80,
        muiTableHeadCellProps: {
            style: {
              fontWeight: 'bold', // Set the font weight as needed
              // Add more styles as needed
              color: "#99a1b7",
              fontSize: 12,
              textTransform: "uppercase", 
            },
            
          },
        muiEditTextFieldProps: {
        autoFocus: true,
          required: true,
          inputProps: {
            style: {
              fontSize: '14px',            },
          },
          error: !!validationErrors?.categoryName,
          helperText: validationErrors?.categoryName,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              categoryName: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
    }
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createCategory, isPending: isCreatingCategory } =
    useCreateCategory();

//   //call UPDATE hook
  const { mutateAsync: updateCategory, isPending: isUpdatingCategory } =
    useUpdateCategory();
//   //call DELETE hook
  const { mutateAsync: deleteCategory, isPending: isDeletingCategory } =
    useDeleteCategory();

  //CREATE action
  const handleCreateCategory: MRT_TableOptions<CategoryWithChildren>['onCreatingRowSave'] = async ({
    values,
    row,
    table,
  }) => {
    const newValidationErrors = validateCategory(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createCategory({ ...values, parentId: row.original.parentId });
    table.setCreatingRow(null); //exit creating mode
  };

//   //UPDATE action
  const handleSaveUser: MRT_TableOptions<CategoryWithChildren>['onEditingRowSave'] = async ({
    values,
    row,
    table,
  }) => {
    const newValidationErrors = validateCategory(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateCategory({...values, _id: row.original._id});
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<CategoryWithChildren>) => {
    Swal.fire({
        title: "Are you sure you want to delete selected category?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, cancel",
        confirmButtonText: "Yes, delete!"
      }).then((result) => {
        if (result.isConfirmed) {
            deleteCategory(row.original._id.toString());
        }
      });
    // if (window.confirm('Are you sure you want to delete this category?')) {
    //   deleteCategory(row.original._id.toString());
    // }
  };
  function findCategoryInTree(parentId: string | null, categories: CategoryWithChildren[]): CategoryWithChildren | null {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i]._id === parentId) {
      return categories[i];
    }
    if (categories[i].subRows) {
      const found = findCategoryInTree(parentId, categories[i].subRows!);
      if (found) return found;
    }
  }
  return null;
}


  const table = useMaterialReactTable({
    columns,
    data: fetchedCategories,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableColumnPinning: true,
    enableSortingRemoval: false,
    enablePagination: false,
    enableEditing: true,
    enableExpanding: true,
    enableColumnActions: false,
    positionCreatingRow: creatingRowIndex, //index where new row is inserted before
    getRowId: (row) => row?._id?.toString(),
    muiToolbarAlertBannerProps: isLoadingCategoriesError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    icons: {
      //change sort icon, connect internal props so that it gets styled correctly

      ArrowDownwardIcon: KeyboardArrowDown, //best practice
    },
    renderToolbarInternalActions: ({ table }) => (
        <>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_ToggleGlobalFilterButton table={table} />
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_ToggleFiltersButton table={table} /> 
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_ToggleDensePaddingButton table={table} />
        </>
      ),
    muiTableBodyRowProps: ({ row }) => ({
      //conditional styling based on row depth
      sx: (theme) => ({
        td: {
            backgroundColor: (() => {
                if (row.depth === 0) {
                  return "#b2b2b2";
                } else if (row.depth === 1){
                  return "#cccccc";
                } else if (row.depth === 2){
                    return "#e5e5e5";
                } else {
                    return "#ffffff";
                }
              })(),
        },
      }),
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateCategory,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, staticRowIndex, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Subcategory">
          <IconButton
            onClick={() => {
              setCreatingRowIndex((staticRowIndex || 0) + 1);
              table.setCreatingRow(
                createRow(
                  table,
                  {
                    _id: null!,
                    name: '',
                    categoryHierarchyId: '',
                    created: '',
                    modified: '',
                    deleted: "",
                    parentId: row.id,
                    subRows: [],
                  },
                  -1,
                  row.depth + 1,
                ),
              );
            }}
          >
            <LibraryAddIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        sx={{bgcolor: "#3e97ff",
        fontWeight: "bold", ":hover": {backgroundColor: "#1565c0"}}}
        variant="contained"
        onClick={() => {
          setCreatingRowIndex(table.getRowModel().rows.length); //create new row at bottom of table
          table.setCreatingRow(true);
        }}
      >
        Add New Top Level Category
      </Button>
    ),
    initialState: {
      columnPinning: { left: ['mrt-row-actions'], right: [] },
      columnVisibility: { _id: false },
      sorting: [{ id: 'name', desc: false }],
    },
    state: {
      isLoading: isLoadingCategories,
      isSaving: isCreatingCategory || isUpdatingCategory || isDeletingCategory,
      showAlertBanner: isLoadingCategoriesError,
      showProgressBars: isFetchingCategories,
    },
  });

  return (
    <Box>
         <Typography
                component="h1"
                variant="h6"
                fontWeight={"bold"}
                mx={2}
                my={2}
            >
                Product Categories
            </Typography>
        <MaterialReactTable 
     
        table={table} />
    </Box>
  )

};