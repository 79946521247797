/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ImageMetadata } from './ImageMetadata';
import type { ObjectId } from './ObjectId';

export type Import = {
    _id: ObjectId;
    dataProviderId: ObjectId;
    fileId: string;
    fileName: string;
    storageFilePath: string;
    docImageNames: Array<string>;
    imageMetadatas: Array<ImageMetadata>;
    importDocumentVersion: string;
    docImportStatus: Import.docImportStatus;
    imgImportStatus: Import.imgImportStatus;
    importStatus: Import.importStatus;
    created: string;
    modified: string;
    deleted: string;
};

export namespace Import {

    export enum docImportStatus {
        DOCRECEIVED = 'DOCRECEIVED',
        DOCERROR = 'DOCERROR',
        DOCAPPROVED = 'DOCAPPROVED',
    }

    export enum imgImportStatus {
        IMGMISSING = 'IMGMISSING',
        IMGERROR = 'IMGERROR',
        IMGRECEIVED = 'IMGRECEIVED',
    }

    export enum importStatus {
        NOTIMPORTET = 'NOTIMPORTET',
        IMPORTET = 'IMPORTET',
    }


}
