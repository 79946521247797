import {
    ChangeEvent,
    useState,
    FormEvent,
    SyntheticEvent,
    useRef,
} from "react";
import {
    CreateDataAgreementDto,
    DataConsumer,
    DataProvider,
    DataProvidersService,
} from "../services/openapi";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc"; // import utc plugin
import { AgreementLanguageType, WebshopSolutionType } from "../utilities/utils";
import { agreementsElementRefs } from "../utilities/types";
dayjs.extend(utc);

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
    dataProviderId: ErrorFields;
    dataConsumerId: ErrorFields;
    agreementStartDate: ErrorFields;
    agreementEndDate: ErrorFields;
    priceType: ErrorFields;
    webshopSolution: ErrorFields;
    language: ErrorFields;
    priceFactor: ErrorFields;
}

export const useFormCreateAgreement = (
    initialValues: CreateDataAgreementDto,
    onSubmitHandler: (data: CreateDataAgreementDto) => void
) => {
    const [values, setValues] = useState<CreateDataAgreementDto>(initialValues);
    const [priceTypes, setPriceTypes] = useState<string[]>([]);
    const [formErrors, setFormErrors] = useState<Errors>({
        dataProviderId: {
            error: false,
            errorMessage: "",
        },
        dataConsumerId: {
            error: false,
            errorMessage: "",
        },
        agreementStartDate: {
            error: false,
            errorMessage: "",
        },
        agreementEndDate: {
            error: false,
            errorMessage: "",
        },
        priceType: {
            error: false,
            errorMessage: "",
        },
        webshopSolution: {
            error: false,
            errorMessage: "",
        },
        language: {
            error: false,
            errorMessage: "",
        },
        priceFactor: {
            error: false,
            errorMessage: "",
        },
    });

    const elementReferences: agreementsElementRefs = {
        companiesRef: useRef<HTMLInputElement>(null),
        datesRef: useRef<HTMLInputElement>(null),
    };
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    };

    const onProviderSelectHandler = async (
        e: SyntheticEvent,
        value: DataProvider | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                dataProviderId: value._id.toString(),
                priceType: "",
            }));
            try {
                const priceTypes =
                    await DataProvidersService.dataProviderControllerGetDataProviderUniquePriceTypesV1(
                        value._id.toString()
                    );
                setPriceTypes([...priceTypes, ""]);
            } catch (error) {
                console.error(error);
            }
        }
    };
    const onConsumerSelectHandler = (
        e: SyntheticEvent,
        value: DataConsumer | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                dataConsumerId: value._id.toString(),
            }));
        }
    };
    const onLanguageChangeHandler = (
        e: SyntheticEvent,
        value: AgreementLanguageType | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                language: value.code,
            }));
        }
    };
    const onTargetSelectHandler = (
        e: SyntheticEvent,
        value: WebshopSolutionType | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                webshopSolution: value.name,
            }));
        }
    };
    const onPriceTypeChangeHandler = (
        e: SyntheticEvent,
        value: string | undefined
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                priceType: value,
            }));
        }
    };
    const onDimensionsChangeHandler = (
        e: SyntheticEvent,
        value: string | undefined
    ) => {
        if (value) {
            value === "Metric (cm)"
                ? setValues((state) => ({ ...state, isMetric: true }))
                : setValues((state) => ({ ...state, isMetric: false }));
        }
    };

    const onStartDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                agreementStartDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };
    const onEndDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                agreementEndDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };
    const validateValues = () => {
        const priceFactorRegex = /^\d+([.,]\d{1,2})?$/;
        const isStartDateBeforeEndDate = dayjs(
            values.agreementStartDate
        ).isBefore(dayjs(values.agreementEndDate));

        const newErrors: Errors = {
            dataProviderId: {
                error: false,
                errorMessage: "",
            },
            dataConsumerId: {
                error: false,
                errorMessage: "",
            },
            agreementStartDate: {
                error: false,
                errorMessage: "",
            },
            agreementEndDate: {
                error: false,
                errorMessage: "",
            },
            priceType: {
                error: false,
                errorMessage: "",
            },
            webshopSolution: {
                error: false,
                errorMessage: "",
            },
            language: {
                error: false,
                errorMessage: "",
            },
            priceFactor: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;
        let firstErrorField = null;
        if (!values.dataProviderId) {
            newErrors.dataProviderId.error = true;
            newErrors.dataProviderId.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.companiesRef;
        }
        if (!values.dataConsumerId) {
            newErrors.dataConsumerId.error = true;
            newErrors.dataConsumerId.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.companiesRef;
        }
        if (!values.webshopSolution) {
            newErrors.webshopSolution.error = true;
            newErrors.webshopSolution.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.companiesRef;
        }
        if (!isStartDateBeforeEndDate) {
            newErrors.agreementEndDate.error = true;
            newErrors.agreementEndDate.errorMessage =
                "Agreement end date must be greater than agreement start date";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.datesRef;
        }
        if (!values.priceType) {
            newErrors.priceType.error = true;
            newErrors.priceType.errorMessage = "This field is required";
            isValid = false;
        }
        if (!priceFactorRegex.test(values.priceFactor.toString())) {
            newErrors.priceFactor.error = true;
            newErrors.priceFactor.errorMessage =
                "Please enter a valid price factor format. Only numbers with max 2 decimal places";
            isValid = false;
        }

        if (values.language === "UX") {
            newErrors.language.error = true;
            newErrors.language.errorMessage = "This field is required";
            isValid = false;
        }

        setFormErrors(newErrors);
        if (firstErrorField && firstErrorField.current) {
            // firstErrorField.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
            const elementRect = firstErrorField.current.getBoundingClientRect();
            const absoluteElementTop = window.scrollY + elementRect.top;
            const middlePosition = absoluteElementTop - window.innerHeight / 2;
            window.scrollTo({ top: middlePosition + 64, behavior: "smooth" });
        }
        return isValid;
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateValues()) {
            return;
        }
        onSubmitHandler(values);
    };

    return {
        values,
        onSubmit,
        onChangeHandler,
        onProviderSelectHandler,
        onConsumerSelectHandler,
        onTargetSelectHandler,
        onStartDateChangeHandler,
        onEndDateChangeHandler,
        onLanguageChangeHandler,
        onDimensionsChangeHandler,
        priceTypes,
        onPriceTypeChangeHandler,
        formErrors,
        elementReferences,
    };
};
