import {
    TextField,
    Button,
    Box,
    Typography,
    Autocomplete,
    createFilterOptions,
    Avatar,
    Grid,
    Paper,
} from "@mui/material";
import { ParamTypes } from "../../utilities/types";
import {
    CreateDataProviderUserDto,
    CreateUserDto,
    DataProvidersService,
    UploadImageInfo,
    UploadService,
    UsersService,
} from "../../services/openapi";
import { useNavigate, useParams } from "react-router-dom";
import { CancelButton } from "../common/CancelButton";
import { useFormCreateProviderUser } from "../../hooks/useFormCreateProviderUser";
import { ChangeEvent, useState } from "react";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import Spinner from "../common/spinner";

const imageButtonStyles = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "30px",
    height: "30px",
    minWidth: "0",
    // minHeight: "0",
    borderRadius: "50%",
    border: "grey",
    bgcolor: "white",
    boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
    "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
};

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const imageRegex = /^[a-zA-Z0-9_#-]+$/;
const userRoles = Object.values(CreateDataProviderUserDto.userRole);

export default function CreateProviderUser() {
    const { providerId } = useParams<keyof ParamTypes>() as ParamTypes;
    const [currentImage, setCurrentImage] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const initialUserValues: CreateUserDto = {
        firstName: "",
        lastName: "",
        email: "",
        primaryPhone: "",
        userRole: CreateUserDto.userRole.NONE,
        authId: "",
    };

    const initialProviderUserValues: CreateDataProviderUserDto = {
        userRole: CreateDataProviderUserDto.userRole.USER,
        userId: "",
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (
                !imageRegex.test(file.name.slice(0, file.name.lastIndexOf(".")))
            ) {
                Swal.fire({
                    icon: "error",
                    text: 'The image name should contain only latin letters, numbers or the symbols "_", "#" and "-". No other characters or empty spaces allowed',
                });
                e.target.value = "";
                return;
            }
            if (file.size > 3500000) {
                Swal.fire({
                    icon: "error",
                    text: "The image size cannot exceed 3500 KB",
                });
                e.target.value = "";
                return;
            }

            setCurrentImage(file);
            setPreviewImage(URL.createObjectURL(file));
            e.target.value = "";
        }
    };
    const onDeleteImageClick = () => {
        setPreviewImage("");
        setCurrentImage(null);
    };
    const navigate = useNavigate();

    const onCreateSubmitHandler = async (
        userData: CreateUserDto,
        providerUserData: CreateDataProviderUserDto
    ): Promise<void> => {
        const { userRole: providerUserRole } = providerUserData;
        try {
            setIsLoading(true);
            const supertokensResponse = await fetch("https://datahub-backend.arkforms.com/auth/signup", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "formFields": [
                        {
                          "id": "email",
                          "value": userValues.email
                        },
                        {
                          "id": "password",
                          "value": password
                        }
                      ]
                }),
            });
            const supertokensUser = await supertokensResponse.json();
            const supertokensUserId = supertokensUser.user.id;
            const user = await UsersService.usersControllerCreate({...userData, authId: supertokensUserId});
            const userId = user._id.toString();
            await DataProvidersService.dataProviderControllerAddDataProviderUserV1(
                providerId,
                userId,
                providerUserRole
            );
            if (currentImage) {
                const imageExtension = currentImage.name.substring(currentImage.name.lastIndexOf(".") + 1);
                const uploadImageInfo: UploadImageInfo =
                    await UploadService.uploadControllerUploadImage(
                        currentImage.size, imageExtension
                    );
                await fetch(uploadImageInfo.uploadUrl, {
                    method: "PUT",
                    body: currentImage,
                    headers: { "Content-Type": currentImage.type },
                });
                await UsersService.usersControllerAddUserProfileImage(userId, {
                    imageId: uploadImageInfo.imageId,
                    name: currentImage.name,
                    fileName: currentImage.name,
                    order: 0,
                    width: 0,
                    height: 0,
                    extension: "",
                });
            }
            navigate(`/providers/${providerId}/users`);
            setIsLoading(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const {
        userValues,
        providerUserValues,
        onUserChangeHandler,
        onUserRoleSelectHandler,
        formErrors,
        onSubmit,
        password,
        onPasswordChangeHandler
    } = useFormCreateProviderUser(
        initialUserValues,
        initialProviderUserValues,
        onCreateSubmitHandler
    );

    const onCancelClickHandler = () => {
        navigate(`/providers/${providerId}/users`);
    };

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Box
                        component="form"
                        noValidate
                        // sx={{
                        //     width: "30%",
                        // }}
                        action="POST"
                        onSubmit={onSubmit}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            fontWeight="bold"
                            sx={{ mb: 2 }}
                        >
                            Add User
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Box
                                    sx={{
                                        position: "relative",
                                        width: 200,
                                        height: 200,
                                        mb: 2,
                                    }}
                                >
                                    <Avatar
                                        alt=""
                                        src={previewImage}
                                        sx={{
                                            bgcolor: "#eeedf1",
                                            width: "100%",
                                            height: "100%",
                                            boxShadow:
                                                "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                        }}
                                    ></Avatar>
                                    <Button
                                        component="label"
                                        aria-label="add-edit"
                                        sx={imageButtonStyles}
                                    >
                                        {previewImage ? (
                                            <EditIcon
                                                fontSize="small"
                                                sx={{ color: "grey" }}
                                            />
                                        ) : (
                                            <AddIcon
                                                fontSize="small"
                                                sx={{ color: "grey" }}
                                            />
                                        )}
                                        <VisuallyHiddenInput
                                            type="file"
                                            accept=".jpg, .jpeg, .webp"
                                            onChange={handleChange}
                                        />
                                    </Button>
                                    {previewImage && (
                                        <Button
                                            onClick={onDeleteImageClick}
                                            component="label"
                                            aria-label="remove"
                                            sx={{
                                                position: "absolute",
                                                bottom: 0,
                                                left: 0,
                                                width: "30px",
                                                height: "30px",
                                                minWidth: "0",
                                                // minHeight: "0",
                                                borderRadius: "50%",
                                                border: "grey",
                                                bgcolor: "white",
                                                boxShadow:
                                                    "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
                                                "&:hover": {
                                                    backgroundColor: "#ffffff",
                                                    boxShadow: "none",
                                                },
                                            }}
                                        >
                                            <DeleteIcon
                                                fontSize="small"
                                                sx={{ color: "grey" }}
                                            />
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={2}>
                                    <Box
                                        // display={"flex"}
                                        sx={{ p: 3 }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={2}
                                        >
                                            Personal information
                                        </Typography>
                                        <TextField
                                            label="First Name"
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            sx={{ width: "60%", mb: 1  }}
                                            margin="dense"
                                            value={userValues.firstName}
                                            onChange={onUserChangeHandler}
                                            error={formErrors.firstName.error}
                                            helperText={
                                                formErrors.firstName.error
                                                    ? formErrors.firstName
                                                          .errorMessage
                                                    : ""
                                            }
                                        />
                                        <TextField
                                            label="Last Name"
                                            name="lastName"
                                            variant="outlined"
                                            required
                                            sx={{ width: "60%", mb: 1  }}
                                            margin="dense"
                                            value={userValues.lastName}
                                            onChange={onUserChangeHandler}
                                            error={formErrors.lastName.error}
                                            helperText={
                                                formErrors.lastName.error
                                                    ? formErrors.lastName
                                                          .errorMessage
                                                    : ""
                                            }
                                        />
                                        <TextField
                                            label="Phone"
                                            name="primaryPhone"
                                            variant="outlined"
                                            required
                                            sx={{ width: "60%", mb: 1  }}
                                            margin="dense"
                                            value={userValues.primaryPhone}
                                            onChange={onUserChangeHandler}
                                            error={formErrors.primaryPhone.error}
                                            helperText={
                                                formErrors.primaryPhone.error
                                                    ? formErrors.primaryPhone
                                                          .errorMessage
                                                    : ""
                                            }
                                        />
                                        <TextField
                                            label="Email"
                                            name="email"
                                            variant="outlined"
                                            required
                                            sx={{ width: "60%" }}
                                            margin="dense"
                                            value={userValues.email}
                                            onChange={onUserChangeHandler}
                                            error={formErrors.email.error}
                                            helperText={
                                                formErrors.email.error
                                                    ? formErrors.email
                                                          .errorMessage
                                                    : ""
                                            }
                                        />
                                         <TextField
                                            label="Password"
                                            name="password"
                                            required
                                            variant="outlined"
                                            sx={{ width: "60%" }}
                                            margin="dense"
                                            value={password}
                                            onChange={onPasswordChangeHandler}
                                            error={formErrors.password.error}
                                            helperText={
                                                formErrors.password.error
                                                    ? formErrors.password
                                                          .errorMessage
                                                    : ""
                                            }
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={2}>
                                    <Box
                                        // display={"flex"}
                                        sx={{ p: 3 }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={2}
                                        >
                                            User role
                                        </Typography>
                                        <Autocomplete
                                            id="userRole-select"
                                            sx={{ mt: 2 }}
                                            options={userRoles.sort((a, b) =>
                                                a.localeCompare(b)
                                            )}
                                            autoHighlight
                                            autoSelect
                                            aria-required
                                            value={userRoles.find(
                                                (role) =>
                                                    role ===
                                                    providerUserValues.userRole
                                            )}
                                            disableClearable={true}
                                            getOptionLabel={(option) => option}
                                            filterOptions={createFilterOptions({
                                                matchFrom: "start",
                                                stringify: (option) => option,
                                            })}
                                            onChange={onUserRoleSelectHandler}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label="User Role"
                                                    sx={{ width: "60%" }}
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    <CancelButton
                                        onCancelClickHandler={
                                            onCancelClickHandler
                                        }
                                    />
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            bgcolor: "#3e97ff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
}
