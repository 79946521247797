import { useState, useCallback, useEffect, MouseEvent } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
    Checkbox,
    Button,
} from "@mui/material";
import { IHeadCell, Order, ValueOf } from "../../utilities/types";
import { EnhancedTableHead } from "./AgreementsListHead";
import { SearchAgreementToolbar } from "./SearchAgreementToolbar";
import { Link, useNavigate } from "react-router-dom";
import { DataAgreement, DataAgreementService } from "../../services/openapi";
import { InfoIcon } from "../../utilities/InfoIcon/InfoIcon";
import { DeleteAgreementsToolbar } from "./DeleteAgreementsToolbar";
import ActionButtonAgreements from "../common/ActionButtonAgreements";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the utc plugin
import Spinner from "../common/spinner";

dayjs.extend(utc); // Use the utc plugin

export const AgreementsList = () => {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<ValueOf<IHeadCell>>("provider");
    const [selected, setSelected] = useState<string[]>([]);
    const [data, setData] = useState<DataAgreement[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAgreementDeleted, setIsAgreementDeleted] = useState(false);
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        DataAgreementService.dataAgreementControllerGetDataAgreements()
            .then((result) => {
                setData(result);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [isAgreementDeleted]);

    // const onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    //     setSearchQuery(e.target.value);
    //     setSkipped(0);
    //     setPage(0);
    // };

    const handleRequestSort = useCallback(
        (event: MouseEvent<unknown>, property: ValueOf<IHeadCell>) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );
    const handleCloseClick = () => {
        setSelected([]);
    };
    const handleDeleteClick = async () => {
        setIsLoading(true);
        const promises = selected.map((id) =>
            DataAgreementService.dataAgreementControllerDeleteDataAgreement(id)
        );
        try {
            await Promise.all(promises);
            setIsAgreementDeleted(!isAgreementDeleted);
            setSelected([]);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n._id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onAddAgreementButtonHandler = () => {
        navigate("/agreements/create");
    };

    return (
        <>
            {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mx={2}
                        my={2}
                    >
                        Data Access Agreements
                    </Typography>
                    {data.length === 0 && (
                        <Box
                            sx={{
                                mx: 2,
                                mt: 8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <InfoIcon />
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 24,
                                }}
                            >
                                Empty List
                            </Typography>
                            <Button
                                onClick={onAddAgreementButtonHandler}
                                variant="contained"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Add Agreement
                            </Button>
                        </Box>
                    )}
                    {data.length !== 0 && (
                        <>
                            {selected.length > 0 ? (
                                <DeleteAgreementsToolbar
                                    numSelected={selected.length}
                                    selected={selected}
                                    handleDeleteClick={handleDeleteClick}
                                    handleCloseClick={handleCloseClick}
                                />
                            ) : (
                                <SearchAgreementToolbar
                                // onSearchChangeHandler={onSearchChangeHandler}
                                />
                            )}
                            <Table
                                aria-label="simple table"
                                // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    rowCount={data.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />

                                <TableBody>
                                    {data.map((row, index) => {
                                        const rowId = row?._id.toString();
                                        const isItemSelected =
                                            isSelected(rowId);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow key={rowId} hover>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        key={rowId}
                                                        id={labelId}
                                                        color="primary"
                                                        size="small"
                                                        checked={isItemSelected}
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                rowId
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    key={rowId}
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                    sx={{
                                                        // p: 0.3,
                                                        height: 60,
                                                        position: "relative",
                                                        width: "20%",
                                                    }}
                                                >
                                                    <Link
                                                        to={`/agreements/${rowId}/edit`}
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"#4b5679"}
                                                            sx={{
                                                                fontSize: 14,
                                                                cursor: "pointer",
                                                                ":hover": {
                                                                    color: "#3e97ff",
                                                                },
                                                                fontWeight:
                                                                    "bold",
                                                                position:
                                                                    "absolute",
                                                                top: "50%",
                                                                transform:
                                                                    "translatey(-50%)",
                                                            }}
                                                        >
                                                            {
                                                                row
                                                                    ?.dataProvider
                                                                    ?.companyName
                                                            }
                                                        </Typography>
                                                    </Link>
                                                </TableCell>
                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        position: "relative",
                                                        width: "20%",
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign={"left"}
                                                        sx={{
                                                            fontSize: 14,
                                                            color: "#78829d",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            transform:
                                                                "translatey(-50%)",
                                                        }}
                                                    >
                                                        {
                                                            row?.dataConsumer
                                                                ?.companyName
                                                        }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        position: "relative",
                                                        width: "20%",
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign={"left"}
                                                        sx={{
                                                            fontSize: 14,
                                                            color: "#78829d",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            transform:
                                                                "translatey(-50%)",
                                                        }}
                                                    >
                                                        {row?.webshopSolution ===
                                                        "WOOCOMMERCE"
                                                            ? "WooCommerce"
                                                            : "None"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        position: "relative",
                                                        width: "10%",
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign={"left"}
                                                        sx={{
                                                            fontSize: 14,
                                                            color: "#78829d",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            transform:
                                                                "translatey(-50%)",
                                                        }}
                                                    >
                                                        {dayjs(
                                                            row?.agreementStartDate
                                                        )?.format("DD/MM/YYYY")}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        position: "relative",
                                                        width: "10%",
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign={"left"}
                                                        sx={{
                                                            fontSize: 14,
                                                            color: "#78829d",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            transform:
                                                                "translatey(-50%)",
                                                        }}
                                                    >
                                                        {dayjs(
                                                            row?.agreementEndDate
                                                        )?.format("DD/MM/YYYY")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell padding="none">
                                                    <ActionButtonAgreements
                                                        agreementId={rowId}
                                                        handleCheckClick={
                                                            handleClick
                                                        }
                                                        webshopSolution={
                                                            row?.webshopSolution
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </>
            )}
        </>
    );
};
