import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { RouteGuardProps } from "./types";

export const RouteGuardProviders = ({ children }: RouteGuardProps) => {
    const currentUser = useContext(AuthContext);

    return (
        <>
            {!currentUser?.dataProvider &&
            currentUser?.userRole !== "PlatformAdministrator" ? (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, you are not authorized to visit this page.</p>
                </div>
            ) : (
                children
            )}
        </>
    );
};
