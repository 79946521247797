import { ChangeEvent, useState, FormEvent } from "react";
import { CreateUserDto } from "../services/openapi";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
        firstName: ErrorFields;
        lastName: ErrorFields
        email: ErrorFields
        primaryPhone: ErrorFields
        password: ErrorFields
    };
export const useFormCreateUser = (
    initialValues: CreateUserDto,
    onSubmitHandler: (data: CreateUserDto) => void
) => {
    const [values, setValues] = useState<CreateUserDto>(initialValues);
    const [password, setPassword] = useState("");
    const [formErrors, setFormErrors] = useState<Errors>({
        firstName: {
            error: false,
            errorMessage: ""
        },
        lastName: {
            error: false,
            errorMessage: ""
        },
        email: {
            error: false,
            errorMessage: ""
        },
        primaryPhone: {
            error: false,
            errorMessage: ""
        },
        password: {
            error: false,
            errorMessage: ""
        }
})
    const onPasswordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    };
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    };

    const validateValues = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/gm;
        const passwordRegex = /^(?=.*\d).{8,}$/;
        const newErrors: Errors = {
            firstName: {
                    error: false,
                    errorMessage: ""
                },
                lastName: {
                    error: false,
                    errorMessage: ""
                },
                email: {
                    error: false,
                    errorMessage: ""
                },
                primaryPhone: {
                    error: false,
                    errorMessage: ""
                },
                password: {
                    error: false,
                    errorMessage: ""
                }
        }
        let isValid = true;
        if (!values.firstName) {  
            newErrors.firstName.error = true;
            newErrors.firstName.errorMessage = "This field is required"
            isValid = false;
        }
        if (!values.lastName) {  
            newErrors.lastName.error = true;
            newErrors.lastName.errorMessage = "This field is required"
            isValid = false;
        }
        if (!values.primaryPhone) {  
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage = "This field is required"
            isValid = false;
        }
        if (values.primaryPhone && !values.primaryPhone.startsWith("+")) {  
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage = 'Please enter a valid phone number. The phone number should start with "+" followed by country code'
            isValid = false;
        }
        if (!emailRegex.test(values.email)) {  
            newErrors.email.error = true;
            newErrors.email.errorMessage = "Please enter a valid email address"
            isValid = false;
        }
        if (!passwordRegex.test(password)) {  
            newErrors.password.error = true;
            newErrors.password.errorMessage = "The password must contain at least 8 characters, including a number"
            isValid = false;
        }

        setFormErrors(newErrors)
        return isValid
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateValues()) {
            return
        }
        onSubmitHandler(values);
    };
    
    return {
        values,
        onSubmit,
        onChangeHandler,
        formErrors,
        password,
        onPasswordChangeHandler
    };
};
