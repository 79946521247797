import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    Box,
    Typography,
} from "@mui/material";
import { IEnhancedTableProps, IHeadCell } from "../../utilities/types";

export const ConsumerUsersListHead = ({
    onSelectAllClick,
    numSelected,
    rowCount,
}: IEnhancedTableProps) => {
    const headCells: readonly IHeadCell[] = [
        {
            id: "firstName",
            label: "Name",
        },
        {
            id: "email",
            label: "Email/login ID",
        },
        {
            id: "primaryPhone",
            label: "Phone",
        },
        {
            id: "role",
            label: "Role",
        },
        {
            id: "status",
            label: "Status",
        },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        id="checkbox-select-all"
                        size="small"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align='left'
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        // sortDirection={orderBy === id ? order : "asc"}
                        sx={{
                            p: 0.2,
                            textAlign: "left",
                        }}
                    >
                        {/* <TableSortLabel
                                active={orderBy === id}
                                direction={orderBy === id ? order : "asc"}
                                onClick={createSortHandler(id)}
                            > */}
                        <Box>
                            <Typography
                                variant="body2"
                                textTransform={"uppercase"}
                                sx={{
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 12,
                                }}
                            >
                                {headCell.label}
                            </Typography>
                        </Box>
                        {/* </TableSortLabel> */}
                    </TableCell>
                ))}
                <TableCell
                    sx={{ p: 0.2, textAlign: "left",}}
                >
                    <Box>
                        <Typography
                            variant="body2"
                            textTransform={"uppercase"}
                            sx={{
                                fontWeight: "bold",
                                color: "#99a1b7",
                                fontSize: 12,
                            }}
                        >
                            Actions
                        </Typography>
                    </Box>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
