import {
    TextField,
    Button,
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Avatar,
    Autocomplete,
    InputAdornment,
    createFilterOptions,
    Grid,
    Paper,
} from "@mui/material";
import { ParamTypes } from "../../../utilities/types";
import { useNavigate, useParams } from "react-router-dom";
import {
    CreateInLanguageDto,
    CreatePriceDto,
    CreateProductVariantDto,
    InLanguage,
    Product,
    ProductAttr,
    ProductVariant,
    ProductVariantsService,
    ProductsService,
    UploadImageInfo,
    UploadService,
} from "../../../services/openapi";
import { CancelButton } from "../../common/CancelButton";
import { useState, ChangeEvent, useEffect, useMemo } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import photoPlaceholder from "../../../assets/image_placeholder.6e3610d.jpg";
import Spinner from "../../common/spinner";
import { useFormCreateVariant } from "../../../hooks/useFormCreateVariant";
import { countryList, languages } from "../../../utilities/utils";
import PriceGrid from "./PriceGrid";
import AttributesGrid from "./AttributesGrid";
import Swal from "sweetalert2";
import "../../../utilities/import-sweet-alert.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const years = Array.from(
    { length: new Date().getFullYear() - 1899 },
    (_, index) => new Date().getFullYear() - index
);

const bigImageButtonStyles = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "30px",
    height: "30px",
    minWidth: "0",
    borderRadius: "50%",
    border: "grey",
    bgcolor: "white",
    boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
    "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
};
const smallImageButtonStyles = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "20px",
    height: "20px",
    minWidth: "0",
    borderRadius: "50%",
    border: "grey",
    bgcolor: "white",
    boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
    "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
};

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const dimensionsRegex = /^\d+([.,]\d{1,2})?$/;
const imageRegex = /^[a-zA-Z0-9_#-]+$/;

export default function CreateVariant() {
    const { providerId, productId } = useParams<
        keyof ParamTypes
    >() as ParamTypes;
    const [previewImage, setPreviewImage] = useState<string[]>([]);
    const [product, setProduct] = useState<Product | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [attributesList, setAttributesList] = useState<ProductAttr[]>([]);
    const [priceTypes, setPriceTypes] = useState<string[]>([]);
    const [productLanguages, setProductLanguages] = useState<InLanguage.lang[]>(
        []
    );
    const [files, setFiles] = useState<(File | null)[]>([]);
    const [error, setError] = useState<string>("");

    const statusOptions = Object.values(CreateProductVariantDto.status);

    const initialValues: CreateProductVariantDto = useMemo(() => {
        return {
            productId: productId,
            dataProviderId: providerId,
            name: [{ lang: CreateInLanguageDto.lang.EN, val: "" }],
            status: CreateProductVariantDto.status.DRAFT,
            shortDescription: [{ lang: CreateInLanguageDto.lang.EN, val: "" }],
            longDescription: [{ lang: CreateInLanguageDto.lang.EN, val: "" }],
            images: [],
            SKU: "",
            EAN: "",
            UPC: "",
            designer: "",
            designYear: new Date().getFullYear(),
            HSTarrifCode: "",
            countryOfOrigin: CreateProductVariantDto.countryOfOrigin.UX,
            itemDimensions: {
                widthCm: 0,
                lengthCm: 0,
                heightCm: 0,
                widthIn: 0,
                lengthIn: 0,
                heightIn: 0,
            },
            shippingDimensions: {
                lengthCm: 0,
                widthCm: 0,
                heightCm: 0,
                netWeightKg: 0,
                taraWeightKg: 0,
                grossWeightKg: 0,
                shippingVolumeM3: 0,
                lengthIn: 0,
                widthIn: 0,
                heightIn: 0,
                shippingVolumeIn3: 0,
                netWeightLb: 0,
                taraWeightLb: 0,
                grossWeightLb: 0,
            },
            productAttrs: [{ name: "", value: "" }],
            prices: [],
            categories: [],
            publishedStartDate: "",
            publishedEndDate: "",
            discontinuedStartDate: "",
            leadTimeBegin: 0,
            leadTimeEnd: 0,
        };
    }, [productId, providerId]);

    useEffect(() => {
        ProductsService.productControllerFind(productId)
            .then((result) => {
                setProduct(result);
                setIsLoading(false);
                initialValues.name = result.name;
                initialValues.shortDescription = result.shortDescription;
                initialValues.longDescription = result.longDescription;
                initialValues.productAttrs = result.productAttrs;
                initialValues.prices = result.priceTypes.map((priceType) => {
                    return {
                        priceType: priceType,
                        currency: priceType.slice(
                            0,
                            3
                        ) as CreatePriceDto.currency,
                        price: 0,
                    };
                });
                setProductLanguages(result.name.map((item) => item.lang));
                setAttributesList(result.productAttrs);
                setPriceTypes(result.priceTypes);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [productId, initialValues]);
    const navigate = useNavigate();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        const imagePlaceNumber = Number(e.target.id);
        if (file) {
            if (
                !imageRegex.test(file.name.slice(0, file.name.lastIndexOf(".")))
            ) {
                Swal.fire({
                    icon: "error",
                    text: 'The image name should contain only latin letters, numbers or the symbols "_", "#" and "-". No other characters or empty spaces allowed',
                });
                e.target.value = "";
                return;
            }
            if (file.size > 3500000) {
                Swal.fire({
                    icon: "error",
                    text: "The image size cannot exceed 3500 KB",
                });
                e.target.value = "";
                return;
            }
            setPreviewImage((prevState) => {
                const newState = [...prevState];
                newState[imagePlaceNumber] = URL.createObjectURL(file);
                return newState;
            });
            setFiles((prevState) => {
                const newState = [...prevState];
                newState[imagePlaceNumber] = file;
                return newState;
            });
        }
    };
    const onDeleteImageClick = (e: React.MouseEvent<HTMLElement>) => {
        const deleteButtonIndex = Number(e.currentTarget.id);
        setPreviewImage((prevState) => {
            const newState = [...prevState];
            newState[deleteButtonIndex] = "";
            return newState;
        });
        setFiles((prevState) => {
            const newState = [...prevState];
            newState[deleteButtonIndex] = null;
            return newState;
        });
    };

    const onCreateSubmitHandler = async (data: CreateProductVariantDto) => {
        setIsLoading(true);

        const shippingVolumeM3 =
            (Number(
                data.shippingDimensions.lengthCm.toString().replace(",", ".")
            ) *
                Number(
                    data.shippingDimensions.heightCm
                        .toString()
                        .replace(",", ".")
                ) *
                Number(
                    data.shippingDimensions.widthCm.toString().replace(",", ".")
                )) /
            1000000;
        const grossWeightKg =
            Number(
                data.shippingDimensions.netWeightKg.toString().replace(",", ".")
            ) +
            Number(
                data.shippingDimensions.taraWeightKg
                    .toString()
                    .replace(",", ".")
            );
        const shippingVolumeIn3 =
            Number(
                data.shippingDimensions.lengthIn.toString().replace(",", ".")
            ) *
            Number(
                data.shippingDimensions.heightIn.toString().replace(",", ".")
            ) *
            Number(
                data.shippingDimensions.widthIn.toString().replace(",", ".")
            );
        const grossWeightLb =
            Number(
                data.shippingDimensions.netWeightLb.toString().replace(",", ".")
            ) +
            Number(
                data.shippingDimensions.taraWeightLb
                    .toString()
                    .replace(",", ".")
            );
        const updatedData = {
            ...data,
            leadTimeBegin: Number(data.leadTimeBegin),
            leadTimeEnd: Number(data.leadTimeEnd),
            itemDimensions: {
                ...data.itemDimensions,
                widthCm: Number(
                    data.itemDimensions.widthCm.toString().replace(",", ".")
                ),
                heightCm: Number(
                    data.itemDimensions.heightCm.toString().replace(",", ".")
                ),
                lengthCm: Number(
                    data.itemDimensions.lengthCm.toString().replace(",", ".")
                ),
                widthIn: Number(
                    data.itemDimensions.widthIn.toString().replace(",", ".")
                ),
                heightIn: Number(
                    data.itemDimensions.heightIn.toString().replace(",", ".")
                ),
                lengthIn: Number(
                    data.itemDimensions.lengthIn.toString().replace(",", ".")
                ),
            },
            shippingDimensions: {
                ...data.shippingDimensions,
                widthCm: Number(
                    data.shippingDimensions.widthCm.toString().replace(",", ".")
                ),
                heightCm: Number(
                    data.shippingDimensions.heightCm
                        .toString()
                        .replace(",", ".")
                ),
                lengthCm: Number(
                    data.shippingDimensions.lengthCm
                        .toString()
                        .replace(",", ".")
                ),
                shippingVolumeM3: Number(shippingVolumeM3.toFixed(3)),
                netWeightKg: Number(
                    data.shippingDimensions.netWeightKg
                        .toString()
                        .replace(",", ".")
                ),
                taraWeightKg: Number(
                    data.shippingDimensions.taraWeightKg
                        .toString()
                        .replace(",", ".")
                ),
                grossWeightKg: Number(grossWeightKg.toFixed(2)),
                widthIn: Number(
                    data.shippingDimensions.widthIn.toString().replace(",", ".")
                ),
                heightIn: Number(
                    data.shippingDimensions.heightIn
                        .toString()
                        .replace(",", ".")
                ),
                lengthIn: Number(
                    data.shippingDimensions.lengthIn
                        .toString()
                        .replace(",", ".")
                ),
                shippingVolumeIn3: Number(shippingVolumeIn3.toFixed(3)),
                netWeightLb: Number(
                    data.shippingDimensions.netWeightLb
                        .toString()
                        .replace(",", ".")
                ),
                taraWeightLb: Number(
                    data.shippingDimensions.taraWeightLb
                        .toString()
                        .replace(",", ".")
                ),
                grossWeightLb: Number(grossWeightLb.toFixed(2)),
            },
            // prices: prices,
            // productAttrs: attributes,
        };
        try {
            const variant =
                await ProductVariantsService.productVariantControllerCreateProductVariant(
                    updatedData
                );
            const processImage = async (image: File, index: number) => {
                // if (image?.imageId)
                //  {
                const imageExtension = image.name.substring(image.name.lastIndexOf(".") + 1);
                const uploadImageInfo: UploadImageInfo =
                    await UploadService.uploadControllerUploadImage(image.size, imageExtension);
                await fetch(uploadImageInfo.uploadUrl, {
                    method: "PUT",
                    body: image,
                    headers: { "Content-Type": image.type },
                });
                const result =
                    await ProductVariantsService.productVariantControllerCreateProductVariantImage(
                        variant._id.toString(),
                        product?.dataProviderId,
                        {
                            imageId: uploadImageInfo.imageId,
                            name: image.name,
                            fileName: image.name,
                            order: index,
                            width: 0,
                            height: 0,
                            extension: "",
                        }
                    );
                return result;
            };
            const filteredImages = files.filter((image): image is File =>
                Boolean(image)
            );
            const imagesPromises: Promise<ProductVariant>[] =
                filteredImages.map(processImage);

            await Promise.all(
                imagesPromises
            );
            navigate(`/providers/${providerId}/products/${productId}/variants`);
            setIsLoading(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const {
        values,
        onChangeHandler,
        onCountryChangeHandler,
        onlanguageSelectHandler,
        formErrors,
        onDimensionsChangeHandler,
        elementReferences,
        handleAttributesChange,
        handlePriceTypesChange,
        onSubmit,
        handleAddFieldPair,
        handleRemoveFieldPair,
        onStatusChangeHandler,
        onStartDateChangeHandler,
        onEndDateChangeHandler,
        onDiscontinuedDateChangeHandler,
    } = useFormCreateVariant(initialValues, onCreateSubmitHandler, files);

    const onCancelClickHandler = () => {
        navigate(`/providers/${providerId}/products/${productId}/variants`);
    };
    const shippingVolumeCalculatorCm = () => {
        if (
            values.shippingDimensions.heightCm &&
            dimensionsRegex.test(
                values.shippingDimensions.heightCm.toString()
            ) &&
            values.shippingDimensions.widthCm &&
            dimensionsRegex.test(
                values.shippingDimensions.widthCm.toString()
            ) &&
            values.shippingDimensions.lengthCm &&
            dimensionsRegex.test(values.shippingDimensions.lengthCm.toString())
        ) {
            return (
                (Number(
                    values.shippingDimensions.heightCm
                        .toString()
                        .replace(",", ".")
                ) *
                    Number(
                        values.shippingDimensions.widthCm
                            .toString()
                            .replace(",", ".")
                    ) *
                    Number(
                        values.shippingDimensions.lengthCm
                            .toString()
                            .replace(",", ".")
                    )) /
                1000000
            );
        } else {
            return 0;
        }
    };
    const shippingVolumeCalculatorIn = () => {
        if (
            values.shippingDimensions.heightIn &&
            dimensionsRegex.test(
                values.shippingDimensions.heightIn.toString()
            ) &&
            values.shippingDimensions.widthIn &&
            dimensionsRegex.test(
                values.shippingDimensions.widthIn.toString()
            ) &&
            values.shippingDimensions.lengthIn &&
            dimensionsRegex.test(values.shippingDimensions.lengthIn.toString())
        ) {
            return (
                Number(
                    values.shippingDimensions.heightIn
                        .toString()
                        .replace(",", ".")
                ) *
                Number(
                    values.shippingDimensions.widthIn
                        .toString()
                        .replace(",", ".")
                ) *
                Number(
                    values.shippingDimensions.lengthIn
                        .toString()
                        .replace(",", ".")
                )
            );
        } else {
            return 0;
        }
    };
    const grossWeightCalculatorKg = () => {
        if (
            values.shippingDimensions.netWeightKg &&
            dimensionsRegex.test(
                values.shippingDimensions.netWeightKg.toString()
            ) &&
            values.shippingDimensions.taraWeightKg &&
            dimensionsRegex.test(
                values.shippingDimensions.taraWeightKg.toString()
            )
        ) {
            return (
                Number(
                    values.shippingDimensions.netWeightKg
                        .toString()
                        .replace(",", ".")
                ) +
                Number(
                    values.shippingDimensions.taraWeightKg
                        .toString()
                        .replace(",", ".")
                )
            );
        } else {
            return 0;
        }
    };
    const grossWeightCalculatorLb = () => {
        if (
            values.shippingDimensions.netWeightLb &&
            dimensionsRegex.test(
                values.shippingDimensions.netWeightLb.toString()
            ) &&
            values.shippingDimensions.taraWeightLb &&
            dimensionsRegex.test(
                values.shippingDimensions.taraWeightLb.toString()
            )
        ) {
            return (
                Number(
                    values.shippingDimensions.netWeightLb
                        .toString()
                        .replace(",", ".")
                ) +
                Number(
                    values.shippingDimensions.taraWeightLb
                        .toString()
                        .replace(",", ".")
                )
            );
        } else {
            return 0;
        }
    };

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Box
                    // component="form"
                    // sx={{
                    //     // width: "50%",
                    // }}
                    // action="POST"
                    // onSubmit={onSubmit}
                    >
                        {/* <Grid item xs={12} > */}
                        <Box>
                            <Typography
                                component="h1"
                                variant="h6"
                                fontWeight={"bold"}
                                color={"black"}
                                // mx={4}
                                mb={4}
                            >
                                {`${product?.name[0].val} - Add Variant`}
                            </Typography>
                        </Box>
                        {/* </Grid> */}
                        <Grid
                            container
                            spacing={3}

                            // component="form"
                            // sx={{
                            //     // width: "50%",
                            // }}
                            // action="POST"
                            // onSubmit={onSubmit}
                        >
                            <Grid item xs={3}>
                                {/* <Paper elevation={2} > */}
                                <Box
                                    display={"flex"}
                                    sx={{ flexDirection: "column" }}
                                    ref={elementReferences.imageRef}
                                >
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: {
                                                xs: "115px",
                                                md: "230px",
                                                lg: "460px",
                                            },
                                            height: {
                                                xs: "115px",
                                                md: "230px",
                                                lg: "460px",
                                            },
                                            mb: 1,
                                            // mx: 2,
                                            outline: "1px solid lightgrey",
                                        }}
                                    >
                                        <Avatar
                                            variant="square"
                                            alt=""
                                            src={
                                                previewImage[0]
                                                    ? previewImage[0]
                                                    : photoPlaceholder
                                            }
                                            sx={{
                                                bgcolor: "#eeedf1",
                                                width: "100%",
                                                height: "100%",

                                                // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                            }}
                                        ></Avatar>
                                        {!previewImage[0] && (
                                            <Button
                                                component="label"
                                                aria-label="add-edit"
                                                sx={bigImageButtonStyles}
                                            >
                                                <AddIcon
                                                    fontSize="small"
                                                    sx={{ color: "grey" }}
                                                />
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    accept=".jpg, .jpeg, .webp"
                                                    id="0"
                                                    onChange={handleChange}
                                                />
                                            </Button>
                                        )}
                                        {previewImage[0] && (
                                            <Button
                                                // id="0"
                                                // onClick={onDeleteImageClick}
                                                component="label"
                                                aria-label="edit"
                                                sx={bigImageButtonStyles}
                                            >
                                                <EditIcon
                                                    fontSize="small"
                                                    sx={{ color: "grey" }}
                                                />
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    accept=".jpg, .jpeg, .webp"
                                                    id="0"
                                                    onChange={handleChange}
                                                />
                                            </Button>
                                        )}
                                    </Box>
                                    {formErrors.image?.error && (
                                        <Box
                                            sx={{
                                                color: "#D81A48",
                                                fontSize: "12px",
                                                mb: "10px",
                                            }}
                                        >
                                            Please upload a main image
                                        </Box>
                                    )}
                                    <Box
                                        display="flex"
                                        // gap={"5px"}
                                        justifyContent={"space-between"}
                                        // sx={{ mb: 2, mx: 2 }}
                                        sx={{
                                            width: {
                                                xs: "115px",
                                                md: "230px",
                                                lg: "460px",
                                            },
                                            height: {
                                                xs: "25px",
                                                md: "55px",
                                                lg: "110px",
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[1]
                                                        ? previewImage[1]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[1] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="1"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[1] && (
                                                <Button
                                                    id="1"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[2]
                                                        ? previewImage[2]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[2] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="2"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[2] && (
                                                <Button
                                                    id="2"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[3]
                                                        ? previewImage[3]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[3] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="3"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[3] && (
                                                <Button
                                                    id="3"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[4]
                                                        ? previewImage[4]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[4] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="4"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[4] && (
                                                <Button
                                                    id="4"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* </Paper> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            maxWidth: "600px",
                                        }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            mb={2}
                                        >
                                            Status
                                        </Typography>
                                        <Box>
                                            <Autocomplete
                                                id="status"
                                                sx={{ minWidth: "600px" }}
                                                options={statusOptions}
                                                autoHighlight
                                                autoSelect
                                                disableClearable={true}
                                                getOptionLabel={(option) =>
                                                    option[0] +
                                                    option
                                                        .slice(1)
                                                        .toLowerCase()
                                                }
                                                value={values.status}
                                                onChange={onStatusChangeHandler}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                    >
                                                        {option[0] +
                                                            option
                                                                .slice(1)
                                                                .toLowerCase()}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Status"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            gap: 2,
                                            maxWidth: "600px",
                                        }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mb={2}
                                        >
                                            Dates
                                        </Typography>
                                        {/* <Box > */}
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                sx={{ minWidth: "600px" }}
                                                label="Published Start Date"
                                                value={dayjs(
                                                    values.publishedStartDate
                                                )}
                                                onChange={
                                                    onStartDateChangeHandler
                                                }
                                                slotProps={{
                                                    textField: {
                                                        error: formErrors
                                                            .startDate.error,
                                                        helperText: formErrors
                                                            .startDate.error
                                                            ? formErrors
                                                                  .startDate
                                                                  .errorMessage
                                                            : "",
                                                        inputProps: {
                                                            // not InputProps
                                                            disabled: true,
                                                        },
                                                    },
                                                }}
                                                format="DD/MM/YYYY"
                                                disabled={
                                                    values.status === "DRAFT" ||
                                                    values.status === "READY" ||
                                                    values.status ===
                                                        "DISCONTINUED"
                                                }
                                            />
                                        </LocalizationProvider>
                                        {/* </Box> */}
                                        {/* <Box> */}
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                ref={elementReferences.datesRef}
                                                sx={{ minWidth: "600px" }}
                                                label="Published End Date"
                                                value={dayjs(
                                                    values.publishedEndDate
                                                )}
                                                onChange={
                                                    onEndDateChangeHandler
                                                }
                                                slotProps={{
                                                    textField: {
                                                        error: formErrors
                                                            .endDate.error,
                                                        helperText: formErrors
                                                            .endDate.error
                                                            ? formErrors.endDate
                                                                  .errorMessage
                                                            : "",
                                                        inputProps: {
                                                            // not InputProps
                                                            disabled: true,
                                                        },
                                                    },
                                                }}
                                                format="DD/MM/YYYY"
                                                disabled={
                                                    values.status === "DRAFT" ||
                                                    values.status === "READY" ||
                                                    values.status ===
                                                        "DISCONTINUED"
                                                }
                                            />
                                        </LocalizationProvider>
                                        {/* </Box> */}
                                        {/* <Box> */}
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                sx={{ minWidth: "600px" }}
                                                label="Discontinued Start Date"
                                                value={dayjs(
                                                    values.discontinuedStartDate
                                                )}
                                                onChange={
                                                    onDiscontinuedDateChangeHandler
                                                }
                                                slotProps={{
                                                    textField: {
                                                        error: formErrors
                                                            .discontinuedDate
                                                            .error,
                                                        helperText: formErrors
                                                            .discontinuedDate
                                                            .error
                                                            ? formErrors
                                                                  .discontinuedDate
                                                                  .errorMessage
                                                            : "",
                                                        //   inputProps: { // not InputProps
                                                        //     disabled: true,
                                                        // },
                                                    },
                                                    actionBar: {
                                                        actions: ["clear"],
                                                    },
                                                    field: {
                                                        clearable: true,
                                                    },
                                                }}
                                                format="DD/MM/YYYY"
                                                disabled={
                                                    values.status === "DRAFT" ||
                                                    values.status === "READY"
                                                }
                                            />
                                        </LocalizationProvider>
                                        {/* </Box> */}
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{ flexDirection: "column", p: 3 }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={2}
                                        >
                                            Variant Description
                                        </Typography>
                                        {values["name"].map((pair, index) => (
                                            <Box
                                                display={"flex"}
                                                gap={1}
                                                key={`container-${index}`}
                                                sx={{ mb: 2, mt: 1 }}
                                            >
                                                <Box
                                                    display={"flex"}
                                                    key={`langContainer-${index}`}
                                                    gap={1}
                                                    sx={{ mb: 2 }}
                                                >
                                                    <Autocomplete
                                                        sx={{ minWidth: 200 }}
                                                        id={`nameLanguage-${index}`}
                                                        key={`nameLanguage-${index}`}
                                                        ref={
                                                            elementReferences
                                                                .nameRef[index]
                                                        }
                                                        options={languages
                                                            .filter((lang) =>
                                                                productLanguages.includes(
                                                                    lang.code
                                                                )
                                                            )
                                                            .sort((a, b) =>
                                                                a.label.localeCompare(
                                                                    b.label
                                                                )
                                                            )}
                                                        disabled={index === 0}
                                                        getOptionDisabled={(
                                                            option
                                                        ) =>
                                                            values["name"]
                                                                .map(
                                                                    (pair) =>
                                                                        pair.lang
                                                                )
                                                                .includes(
                                                                    option.code
                                                                ) &&
                                                            option.code !==
                                                                pair.lang
                                                        }
                                                        forcePopupIcon={
                                                            index === 0
                                                                ? false
                                                                : true
                                                        }
                                                        autoHighlight
                                                        autoSelect
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.label}
                                                        value={
                                                            index === 0
                                                                ? languages.find(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.code ===
                                                                          "EN"
                                                                  )
                                                                : languages.find(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.code ===
                                                                          pair.lang
                                                                  )
                                                        }
                                                        disableClearable={true}
                                                        filterOptions={createFilterOptions(
                                                            {
                                                                matchFrom:
                                                                    "start",
                                                                stringify: (
                                                                    option
                                                                ) =>
                                                                    option.label,
                                                            }
                                                        )}
                                                        onChange={(
                                                            event,
                                                            value
                                                        ) =>
                                                            onlanguageSelectHandler(
                                                                event,
                                                                value.code,
                                                                "name",
                                                                index
                                                            )
                                                        }
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                            >
                                                                {option.label}
                                                            </Box>
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label={
                                                                    index === 0
                                                                        ? "Language"
                                                                        : "Choose language"
                                                                }
                                                                // sx={{width: "60%"}}
                                                                required={
                                                                    index === 0
                                                                        ? false
                                                                        : true
                                                                }
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                }}
                                                                error={
                                                                    formErrors
                                                                        .name[
                                                                        index
                                                                    ]?.lang
                                                                        .error
                                                                }
                                                                helperText={
                                                                    formErrors
                                                                        .name[
                                                                        index
                                                                    ]?.lang
                                                                        .error
                                                                        ? formErrors
                                                                              .name[
                                                                              index
                                                                          ]
                                                                              ?.lang
                                                                              .errorMessage
                                                                        : ""
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    {index !== 0 ? (
                                                        <Button
                                                            sx={{
                                                                height: "56px",
                                                            }}
                                                            onClick={() =>
                                                                handleRemoveFieldPair(
                                                                    "name",
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <ClearIcon fontSize="medium" />
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    gap={1}
                                                    key={`descriptionContainer-${index}`}
                                                    sx={{
                                                        flexDirection: "column",
                                                        mb: 2,
                                                        // mt: 1,
                                                    }}
                                                    // ref={elementReferences.nameRef}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        gap={1}
                                                        key={`nameDescContainer-${index}`}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                minWidth: 600,
                                                            }}
                                                            label="Variant Name"
                                                            inputProps={{
                                                                maxLength: 40,
                                                            }}
                                                            ref={
                                                                elementReferences
                                                                    .nameRef[
                                                                    index
                                                                ]
                                                            }
                                                            name="val"
                                                            required
                                                            id={`name-input-${index}`}
                                                            key={`nameInput-${index}`}
                                                            value={pair.val}
                                                            onChange={(e) =>
                                                                onChangeHandler(
                                                                    e,
                                                                    "name",
                                                                    index
                                                                )
                                                            }
                                                            error={
                                                                formErrors.name[
                                                                    index
                                                                ]?.val.error
                                                            }
                                                            helperText={
                                                                formErrors.name[
                                                                    index
                                                                ]?.val.error
                                                                    ? formErrors
                                                                          .name[
                                                                          index
                                                                      ]?.val
                                                                          .errorMessage
                                                                    : "Maximum name length 40 characters"
                                                            }
                                                        />
                                                    </Box>
                                                    <Box
                                                        display={"flex"}
                                                        gap={1}
                                                        key={`shortDescContainer-${index}`}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                minWidth: 600,
                                                            }}
                                                            rows={6}
                                                            label="Short Description"
                                                            multiline
                                                            ref={
                                                                elementReferences
                                                                    .shortDescRef[
                                                                    index
                                                                ]
                                                            }
                                                            inputProps={{
                                                                maxLength: 2000,
                                                            }}
                                                            name="val"
                                                            required
                                                            id={`shortDescInput-${index}`}
                                                            key={`shortDescInput-${index}`}
                                                            value={
                                                                values[
                                                                    "shortDescription"
                                                                ][index].val
                                                            }
                                                            onChange={(e) =>
                                                                onChangeHandler(
                                                                    e,
                                                                    "shortDescription",
                                                                    index
                                                                )
                                                            }
                                                            error={
                                                                formErrors
                                                                    .shortDescription[
                                                                    index
                                                                ]?.val.error
                                                            }
                                                            helperText={
                                                                formErrors
                                                                    .shortDescription[
                                                                    index
                                                                ]?.val.error
                                                                    ? formErrors
                                                                          .shortDescription[
                                                                          index
                                                                      ]?.val
                                                                          .errorMessage
                                                                    : "Maximum text length 2000 characters"
                                                            }
                                                        />
                                                    </Box>
                                                    <Box
                                                        display={"flex"}
                                                        gap={1}
                                                        key={`longDescContainer-${index}`}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                minWidth: 600,
                                                            }}
                                                            rows={10}
                                                            label="Long Description"
                                                            multiline
                                                            ref={
                                                                elementReferences
                                                                    .longDescRef[
                                                                    index
                                                                ]
                                                            }
                                                            inputProps={{
                                                                maxLength: 5000,
                                                            }}
                                                            name="val"
                                                            required
                                                            id={`longDescInput-${index}`}
                                                            key={`longDescInput-${index}`}
                                                            value={
                                                                values[
                                                                    "longDescription"
                                                                ][index].val
                                                            }
                                                            onChange={(e) =>
                                                                onChangeHandler(
                                                                    e,
                                                                    "longDescription",
                                                                    index
                                                                )
                                                            }
                                                            error={
                                                                formErrors
                                                                    .longDescription[
                                                                    index
                                                                ]?.val.error
                                                            }
                                                            helperText={
                                                                formErrors
                                                                    .longDescription[
                                                                    index
                                                                ]?.val.error
                                                                    ? formErrors
                                                                          .longDescription[
                                                                          index
                                                                      ]?.val
                                                                          .errorMessage
                                                                    : "Maximum text length 5000 characters"
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                        {values.name.length !==
                                            productLanguages.length && (
                                            <Box>
                                                <Button
                                                    onClick={(e) =>
                                                        handleAddFieldPair(
                                                            e,
                                                            "name"
                                                        )
                                                    }
                                                    sx={{ mt: 1 }}
                                                >
                                                    Add language
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        sx={{ p: 3, maxWidth: "800px" }}
                                        ref={elementReferences.attributesRef}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            // mb={1}
                                        >
                                            Attributes
                                        </Typography>
                                        <Box sx={{ fontSize: "12px", mb: 1 }}>
                                            *All attribute value fields are
                                            required
                                        </Box>
                                        <AttributesGrid
                                            onAttributesChange={
                                                handleAttributesChange
                                            }
                                            productAttributes={attributesList}
                                        />
                                        {formErrors.attributes?.error && (
                                            <Box
                                                sx={{
                                                    color: "#D81A48",
                                                    fontSize: "12px",
                                                    mt: "10px",
                                                }}
                                            >
                                                {
                                                    formErrors.attributes
                                                        ?.errorMessage
                                                }
                                            </Box>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            maxWidth: "600px",
                                        }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={2}
                                        >
                                            Additional information
                                        </Typography>
                                        <Autocomplete
                                            id="country-select"
                                            sx={{ mb: 1, minWidth: "600px" }}
                                            options={countryList.sort((a, b) =>
                                                a.label.localeCompare(b.label)
                                            )}
                                            autoHighlight
                                            autoSelect
                                            disableClearable={true}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            filterOptions={createFilterOptions({
                                                matchFrom: "start",
                                                stringify: (option) =>
                                                    option.label,
                                            })}
                                            onChange={(e, value) =>
                                                onCountryChangeHandler(
                                                    e,
                                                    value.code
                                                )
                                            }
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Country of origin"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                        <Box ref={elementReferences.skuRef}>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="SKU"
                                                name="SKU"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                margin="dense"
                                                value={values.SKU}
                                                onChange={(e) =>
                                                    onChangeHandler(e)
                                                }
                                                error={formErrors.SKU.error}
                                                helperText={
                                                    formErrors.SKU.error
                                                        ? formErrors.SKU
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="EAN"
                                                name="EAN"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={values.EAN}
                                                onChange={(e) =>
                                                    onChangeHandler(e)
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="UPC"
                                                name="UPC"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={values.UPC}
                                                onChange={(e) =>
                                                    onChangeHandler(e)
                                                }
                                            />
                                        </Box>

                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 2,
                                                    minWidth: "600px",
                                                }}
                                                label="Designer"
                                                name="designer"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={values.designer}
                                                onChange={(e) =>
                                                    onChangeHandler(e)
                                                }
                                            />
                                        </Box>
                                        <FormControl
                                            sx={{ mb: 1, minWidth: "600px" }}
                                            fullWidth
                                        >
                                            <InputLabel id="designYear">
                                                Design year
                                            </InputLabel>
                                            <Select
                                                labelId="designYear"
                                                name="designYear"
                                                id="designYear"
                                                label="Design year"
                                                value={String(
                                                    values.designYear
                                                )}
                                                onChange={(e) =>
                                                    onChangeHandler(e)
                                                }
                                            >
                                                {years.map((year) => {
                                                    return (
                                                        <MenuItem
                                                            key={year}
                                                            value={year}
                                                        >
                                                            {year}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <Box>
                                            <TextField
                                                sx={{ minWidth: "600px" }}
                                                label="HS Tarrif Code"
                                                name="HSTarrifCode"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={values.HSTarrifCode}
                                                onChange={(e) =>
                                                    onChangeHandler(e)
                                                }
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            maxWidth: "600px",
                                        }}
                                        ref={
                                            elementReferences.metricDimensionsRef
                                        }
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={1}
                                        >
                                            Product dimensions - Metric
                                        </Typography>

                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Length"
                                                name="lengthCm"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            cm
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.itemDimensions
                                                        .lengthCm === 0
                                                        ? ""
                                                        : values.itemDimensions
                                                              .lengthCm
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "itemDimensions"
                                                )}
                                                error={
                                                    formErrors.productLengthCm
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.productLengthCm
                                                        .error
                                                        ? formErrors
                                                              .productLengthCm
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Width"
                                                name="widthCm"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            cm
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.itemDimensions
                                                        .widthCm === 0
                                                        ? ""
                                                        : values.itemDimensions
                                                              .widthCm
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "itemDimensions"
                                                )}
                                                error={
                                                    formErrors.productWidthCm
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.productWidthCm
                                                        .error
                                                        ? formErrors
                                                              .productWidthCm
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{ minWidth: "600px" }}
                                                label="Height"
                                                name="heightCm"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            cm
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.itemDimensions
                                                        .heightCm === 0
                                                        ? ""
                                                        : values.itemDimensions
                                                              .heightCm
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "itemDimensions"
                                                )}
                                                error={
                                                    formErrors.productHeightCm
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.productHeightCm
                                                        .error
                                                        ? formErrors
                                                              .productHeightCm
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            maxWidth: "600px",
                                        }}
                                        ref={
                                            elementReferences.imperialDimensionsRef
                                        }
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={1}
                                        >
                                            Product dimensions - Imperial
                                        </Typography>

                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Length"
                                                name="lengthIn"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.itemDimensions
                                                        .lengthIn === 0
                                                        ? ""
                                                        : values.itemDimensions
                                                              .lengthIn
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "itemDimensions"
                                                )}
                                                error={
                                                    formErrors.productLengthIn
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.productLengthIn
                                                        .error
                                                        ? formErrors
                                                              .productLengthIn
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Width"
                                                name="widthIn"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.itemDimensions
                                                        .widthIn === 0
                                                        ? ""
                                                        : values.itemDimensions
                                                              .widthIn
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "itemDimensions"
                                                )}
                                                error={
                                                    formErrors.productWidthIn
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.productWidthIn
                                                        .error
                                                        ? formErrors
                                                              .productWidthIn
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{ minWidth: "600px" }}
                                                label="Height"
                                                name="heightIn"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.itemDimensions
                                                        .heightIn === 0
                                                        ? ""
                                                        : values.itemDimensions
                                                              .heightIn
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "itemDimensions"
                                                )}
                                                error={
                                                    formErrors.productHeightIn
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.productHeightIn
                                                        .error
                                                        ? formErrors
                                                              .productHeightIn
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            maxWidth: "600px",
                                        }}
                                        ref={
                                            elementReferences.metricshippingDimensionsRef
                                        }
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={1}
                                        >
                                            Shipping dimensions - Metric
                                        </Typography>

                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Length"
                                                name="lengthCm"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            cm
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .lengthCm === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .lengthCm
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors.shippingLengthCm
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.shippingLengthCm
                                                        .error
                                                        ? formErrors
                                                              .shippingLengthCm
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Width"
                                                name="widthCm"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            cm
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .widthCm === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .widthCm
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors.shippingWidthCm
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.shippingWidthCm
                                                        .error
                                                        ? formErrors
                                                              .shippingWidthCm
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Height"
                                                name="heightCm"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            cm
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .heightCm === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .heightCm
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors.shippingHeightCm
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.shippingHeightCm
                                                        .error
                                                        ? formErrors
                                                              .shippingHeightCm
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Shipping volume"
                                                name="shippingVolumeM3"
                                                variant="outlined"
                                                disabled
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            m<sup>3</sup>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={shippingVolumeCalculatorCm()
                                                    .toFixed(3)
                                                    .replace(".", ",")}
                                                // onChange={onDimensionsChangeHandler("shippingDimensions")}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Net weight"
                                                name="netWeightKg"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            kg
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .netWeightKg === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .netWeightKg
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors
                                                        .shippingNetWeightKg
                                                        .error
                                                }
                                                helperText={
                                                    formErrors
                                                        .shippingNetWeightKg
                                                        .error
                                                        ? formErrors
                                                              .shippingNetWeightKg
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Tara weight"
                                                name="taraWeightKg"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            kg
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .taraWeightKg === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .taraWeightKg
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors
                                                        .shippingTaraWeightKg
                                                        .error
                                                }
                                                helperText={
                                                    formErrors
                                                        .shippingTaraWeightKg
                                                        .error
                                                        ? formErrors
                                                              .shippingTaraWeightKg
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{ minWidth: "600px" }}
                                                label="Gross weight"
                                                name="grossWeightKg"
                                                variant="outlined"
                                                disabled
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            kg
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={grossWeightCalculatorKg()
                                                    .toFixed(2)
                                                    .replace(".", ",")}
                                                // onChange={onDimensionsChangeHandler("shippingDimensions")}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            flexDirection: "column",
                                            p: 3,
                                            maxWidth: "600px",
                                        }}
                                        ref={
                                            elementReferences.imperialshippingDimensionsRef
                                        }
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={1}
                                        >
                                            Shipping dimensions - Imperial
                                        </Typography>

                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Length"
                                                name="lengthIn"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .lengthIn === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .lengthIn
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors.shippingLengthIn
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.shippingLengthIn
                                                        .error
                                                        ? formErrors
                                                              .shippingLengthIn
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Width"
                                                name="widthIn"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .widthIn === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .widthIn
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors.shippingWidthIn
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.shippingWidthIn
                                                        .error
                                                        ? formErrors
                                                              .shippingWidthIn
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Height"
                                                name="heightIn"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .heightIn === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .heightIn
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors.shippingHeightIn
                                                        .error
                                                }
                                                helperText={
                                                    formErrors.shippingHeightIn
                                                        .error
                                                        ? formErrors
                                                              .shippingHeightIn
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Shipping volume"
                                                name="shippingVolumeIn3"
                                                variant="outlined"
                                                disabled
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            in<sup>3</sup>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={shippingVolumeCalculatorIn().toFixed(
                                                    3
                                                )}
                                                // onChange={onDimensionsChangeHandler("shippingDimensions")}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Net weight"
                                                name="netWeightLb"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            lb
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .netWeightLb === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .netWeightLb
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors
                                                        .shippingNetWeightLb
                                                        .error
                                                }
                                                helperText={
                                                    formErrors
                                                        .shippingNetWeightLb
                                                        .error
                                                        ? formErrors
                                                              .shippingNetWeightLb
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    mb: 1,
                                                    minWidth: "600px",
                                                }}
                                                label="Tara weight"
                                                name="taraWeightLb"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            lb
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={
                                                    values.shippingDimensions
                                                        .taraWeightLb === 0
                                                        ? ""
                                                        : values
                                                              .shippingDimensions
                                                              .taraWeightLb
                                                }
                                                onChange={onDimensionsChangeHandler(
                                                    "shippingDimensions"
                                                )}
                                                error={
                                                    formErrors
                                                        .shippingTaraWeightLb
                                                        .error
                                                }
                                                helperText={
                                                    formErrors
                                                        .shippingTaraWeightLb
                                                        .error
                                                        ? formErrors
                                                              .shippingTaraWeightLb
                                                              .errorMessage
                                                        : ""
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                sx={{ minWidth: "600px" }}
                                                label="Gross weight"
                                                name="grossWeightLb"
                                                variant="outlined"
                                                disabled
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            lb
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                margin="dense"
                                                value={grossWeightCalculatorLb().toFixed(
                                                    2
                                                )}
                                                // onChange={onDimensionsChangeHandler("shippingDimensions")}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box
                                        display={"flex"}
                                        sx={{ flexDirection: "column", p: 3 }}
                                        ref={elementReferences.leadTimeRef}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            mb={2}
                                        >
                                            Lead Time
                                        </Typography>
                                        <Box display={"flex"} gap={2}>
                                            <Box sx={{ width: "100%" }}>
                                                <TextField
                                                    sx={{ mb: 1 }}
                                                    label="From (in days)"
                                                    name="leadTimeBegin"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="dense"
                                                    value={
                                                        values.leadTimeBegin ===
                                                        0
                                                            ? ""
                                                            : values.leadTimeBegin
                                                    }
                                                    onChange={(e) =>
                                                        onChangeHandler(e)
                                                    }
                                                    error={
                                                        formErrors.leadTimeBegin
                                                            .error
                                                    }
                                                    helperText={
                                                        formErrors.leadTimeBegin
                                                            .error
                                                            ? formErrors
                                                                  .leadTimeBegin
                                                                  .errorMessage
                                                            : ""
                                                    }
                                                />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                                <TextField
                                                    sx={{ mb: 1 }}
                                                    label="To (in days)"
                                                    name="leadTimeEnd"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="dense"
                                                    value={
                                                        values.leadTimeEnd === 0
                                                            ? ""
                                                            : values.leadTimeEnd
                                                    }
                                                    onChange={(e) =>
                                                        onChangeHandler(e)
                                                    }
                                                    error={
                                                        formErrors.leadTimeEnd
                                                            .error
                                                    }
                                                    helperText={
                                                        formErrors.leadTimeEnd
                                                            .error
                                                            ? formErrors
                                                                  .leadTimeEnd
                                                                  .errorMessage
                                                            : ""
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box sx={{ p: 3, maxWidth: "800px" }}>
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            // mb={1}
                                        >
                                            Pricing
                                        </Typography>

                                        <Box sx={{ fontSize: "12px", mb: 1 }}>
                                            *All prices must be numbers with 2
                                            decimal places
                                        </Box>

                                        <PriceGrid
                                            priceTypes={priceTypes}
                                            onPriceTypesChange={
                                                handlePriceTypesChange
                                            }
                                        />
                                        {formErrors.prices?.error && (
                                            <Box
                                                sx={{
                                                    color: "#D81A48",
                                                    fontSize: "12px",
                                                    mt: "10px",
                                                }}
                                            >
                                                {
                                                    formErrors.prices
                                                        ?.errorMessage
                                                }
                                            </Box>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    <CancelButton
                                        onCancelClickHandler={
                                            onCancelClickHandler
                                        }
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={onSubmit}
                                        // type="submit"
                                        sx={{
                                            bgcolor: "#3e97ff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
}
