import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    Box,
    Typography,
    TableSortLabel,
} from "@mui/material";
import { IEnhancedAttributeProps } from "../../../utilities/types";
import { useCallback } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ProductAttr } from "../../../services/openapi";

type IAttributeHeadCell = {
    id: keyof ProductAttr;
    label: string;
}
export const AttributesListHead = ({
    onSelectAllClick,
    numSelected,
    rowCount,
    order,
    onRequestSort,
    orderBy
}: IEnhancedAttributeProps) => {
    const headCells: readonly IAttributeHeadCell[] = [
        {
            id: "name",
            label: "Attributes",
        },
    ];

    const createSortHandler = useCallback(
        (property: keyof ProductAttr) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        },
        [onRequestSort]
    );
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        id="checkbox-select-all"
                        size="small"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align='left'
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : "asc"}
                        sx={{
                            p: 0.2,
                            textAlign: "left",
                        }}
                    >
                        <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : "asc"}
                              onClick={createSortHandler(headCell.id)}
                              IconComponent={KeyboardArrowDown}
                          >
                        <Box>
                            <Typography
                                variant="body2"
                                textTransform={"uppercase"}
                                sx={{
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 12,
                                }}
                            >
                                {headCell.label}
                            </Typography>
                        </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell
                    sx={{ p: 0.2, textAlign: "left",  }}
                >
                    <Box>
                        <Typography
                            variant="body2"
                            textTransform={"uppercase"}
                            sx={{
                                fontWeight: "bold",
                                color: "#99a1b7",
                                fontSize: 12,
                            }}
                        >
                            Actions
                        </Typography>
                    </Box>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
