import { Link } from "react-router-dom";
import { ISearchUserToolbarProps } from "../../utilities/types";
import { Toolbar, Button, TextField } from "@mui/material";

export function SearchUserToolbar({
    userOwnerId,
    collection,
    onSearchChangeHandler,
}: ISearchUserToolbarProps) {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
                gap: 2,
                // justifyContent: "space-between",
            }}
        >
            <TextField
                id="outlined-search"
                name="search"
                label="Search"
                type="search"
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#eeedf1',
                    },
                  }}
                onChange={onSearchChangeHandler}
            />
            <Link
                to={
                    userOwnerId
                        ? `/${collection}/${userOwnerId}/create-user`
                        : "/users/create"
                }
            >
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: "#3e97ff",
                        fontWeight: "bold",
                    }}
                >
                    Add user
                </Button>
            </Link>
        </Toolbar>
    );
};
