/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CategoryMapping } from './CategoryMapping';
import type { DataConsumer } from './DataConsumer';
import type { DataProvider } from './DataProvider';
import type { ObjectId } from './ObjectId';
import type { ShopSettings } from './ShopSettings';

export type DataAgreement = {
    _id: ObjectId;
    dataConsumerId: ObjectId;
    dataConsumer: DataConsumer;
    dataProviderId: ObjectId;
    dataProvider: DataProvider;
    agreementStartDate: string;
    agreementEndDate: string;
    priceType: string;
    priceFactor: number;
    language: DataAgreement.language;
    isMetric: boolean;
    webshopSolution: DataAgreement.webshopSolution;
    categoryMappings: Array<CategoryMapping>;
    consumerLastExport: string;
    consumerShopSettings: ShopSettings;
    lastExportProductsSent: Record<string, any>;
    lastExportVariantsSend: Record<string, any>;
    lastExportStatus: Record<string, any>;
    lastExportStatusMessage: Record<string, any>;
    created: string;
    modified: string;
};

export namespace DataAgreement {

    export enum language {
        ZH = 'ZH',
        ES = 'ES',
        EN = 'EN',
        HI = 'HI',
        UR = 'UR',
        AR = 'AR',
        BN = 'BN',
        PT = 'PT',
        KO = 'KO',
        JA = 'JA',
        LA = 'LA',
        DE = 'DE',
        FR = 'FR',
        IT = 'IT',
        TR = 'TR',
        NL = 'NL',
        PL = 'PL',
        SV = 'SV',
        CS = 'CS',
        FI = 'FI',
        RO = 'RO',
        EL = 'EL',
        DA = 'DA',
        BG = 'BG',
        SL = 'SL',
        LT = 'LT',
        SK = 'SK',
        LV = 'LV',
        ET = 'ET',
        HR = 'HR',
        HU = 'HU',
        GA = 'GA',
        CY = 'CY',
        SQ = 'SQ',
        SR = 'SR',
        IS = 'IS',
        MT = 'MT',
        MK = 'MK',
        EU = 'EU',
        GD = 'GD',
        NO = 'NO',
        UK = 'UK',
        FO = 'FO',
        KU = 'KU',
        BS = 'BS',
        UX = 'UX',
    }

    export enum webshopSolution {
        NONE = 'NONE',
        WOOCOMMERCE = 'WOOCOMMERCE',
    }


}
