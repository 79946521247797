import { createBrowserRouter } from "react-router-dom";
import RootPage from "../pages/RootPage";
import ErrorPage from "../pages/ErrorPage";
import { ProvidersList } from "../components/providers/ProvidersList";
import { ConsumersList } from "../components/consumers/ConsumersList";
import { UsersList } from "../components/users/UsersList";
import AboutPage from "../pages/AboutPage";
import ContactsPage from "../pages/ContactsPage";
import CreateProvider from "../components/providers/CreateProvider";
import EditProvider from "../components/providers/EditProvider";
import CreateConsumer from "../components/consumers/CreateConsumer";
import EditConsumer from "../components/consumers/EditConsumer";
import CreateUser from "../components/users/CreateUser";
import EditUser from "../components/users/EditUser";
import CreateProviderUser from "../components/providers/CreateProviderUser";
import { ProviderUsersList } from "../components/providers/ProviderUsersList";
import { ConsumerUsersList } from "../components/consumers/ConsumerUsersList";
import CreateConsumerUser from "../components/consumers/CreateConsumerUser";
import { ProviderProductsList } from "../components/providers/ProviderProductsList";
import CreateProduct from "../components/providers/CreateProduct";
import EditProduct from "../components/providers/EditProduct";
import { VariantsList } from "../components/products/variants/VariantsList";
import CreateVariant from "../components/products/variants/CreateVariant";
import EditVariant from "../components/products/variants/EditVariant";
import HomePage from "../pages/HomePage";
import { AttributesList } from "../components/products/attributes";
import CreateAttribute from "../components/products/attributes/CreateAttribute";
import EditAttribute from "../components/products/attributes/EditAttribute";
import { PriceTypesList } from "../components/products/pricetypes";
import CreatePriceType from "../components/products/pricetypes/CreatePriceType";
import EditPriceType from "../components/products/pricetypes/EditPriceType";
import HomeRootPage from "../pages/HomeRootPage";
import { CategoryList } from "../components/products/categories/CategoryList";
import ProductsImport from "../components/providers/ProductsImport";
import { AgreementsList } from "../components/agreements/AgreementsList";
import CreateAgreement from "../components/agreements/CreateAgreement";
import EditAgreement from "../components/agreements/EditAgreement";
import { ExportsList } from "../components/consumers/exports/ExportsList";
import ShopCredentials from "../components/agreements/ShopCredentials";
import { ExportMapping } from "../components/agreements/ExportMapping";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import * as reactRouterDom from "react-router-dom";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { ProviderAgreements } from "../components/providers/ProviderAgreements";
import { AuthProvider } from "../contexts/AuthProvider";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { RouteGuardAdmin } from "../guards/RouteGuardAdmin";
import { RouteGuardProviders } from "../guards/RouteGuardProviders";
import { RouteGuardConsumers } from "../guards/RouteGuardConsumers";

const routes = getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
    EmailPasswordPreBuiltUI,
]);

const router = createBrowserRouter([
    ...routes.map((r) => r.props),
    {
        path: "/",
        element: (
            <SessionAuth>
                <AuthProvider>
                    <HomeRootPage />
                </AuthProvider>
            </SessionAuth>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: "providers/create",
                element: (
                    <RouteGuardAdmin>
                        <CreateProvider />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/edit",
                element: (
                    <RouteGuardAdmin>
                        <EditProvider />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/imports",
                element: (
                    <RouteGuardProviders>
                        <ProductsImport />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "consumers/create",
                element: (
                    <RouteGuardAdmin>
                        <CreateConsumer />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "consumers/:consumerId/edit",
                element: (
                    <RouteGuardAdmin>
                        <EditConsumer />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/create-product",
                element: (
                    <RouteGuardProviders>
                        <CreateProduct />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/edit",
                element: (
                    <RouteGuardProviders>
                        <EditProduct />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/create-variant",
                element: (
                    <RouteGuardProviders>
                        <CreateVariant />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/variants/:variantId/edit",
                element: (
                    <RouteGuardProviders>
                        <EditVariant />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "agreements/create",
                element: (
                    <RouteGuardAdmin>
                        <CreateAgreement />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "agreements/:agreementId/edit",
                element: (
                    <RouteGuardAdmin>
                        <EditAgreement />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "agreements/:agreementId/shop-credentials",
                element: (
                    <RouteGuardAdmin>
                        <ShopCredentials />
                    </RouteGuardAdmin>
                ),
            },

            {
                path: "users/create",
                element: (
                    <RouteGuardAdmin>
                        <CreateUser />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/create-user",
                element: (
                    <RouteGuardAdmin>
                        <CreateProviderUser />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "consumers/:consumerId/create-user",
                element: (
                    <RouteGuardAdmin>
                        <CreateConsumerUser />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "users/:userId/edit",
                element: (
                    <RouteGuardAdmin>
                        <EditUser />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/users/:userId/edit",
                element: (
                    <RouteGuardAdmin>
                        <EditUser />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "consumers/:consumerId/users/:userId/edit",
                element: (
                    <RouteGuardAdmin>
                        <EditUser />
                    </RouteGuardAdmin>
                ),
            },
        ],
    },
    {
        path: "/",
        element: (
            <SessionAuth>
                <AuthProvider>
                    <RootPage />
                </AuthProvider>
            </SessionAuth>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: "providers",
                element: (
                    <RouteGuardAdmin>
                        <ProvidersList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "consumers",
                element: (
                    <RouteGuardAdmin>
                        <ConsumersList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "consumers/:consumerId/exports",
                element: (
                    <RouteGuardConsumers>
                        <ExportsList />
                    </RouteGuardConsumers>
                ),
            },
            {
                path: "users",
                element: (
                    <RouteGuardAdmin>
                        <UsersList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "about",
                element: <AboutPage />,
            },
            {
                path: "contacts",
                element: <ContactsPage />,
            },
            {
                path: "agreements",
                element: (
                    <RouteGuardAdmin>
                        <AgreementsList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/users",
                element: (
                    <RouteGuardAdmin>
                        <ProviderUsersList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/agreements",
                element: (
                    <RouteGuardProviders>
                        <ProviderAgreements />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "consumers/:consumerId/users",
                element: (
                    <RouteGuardAdmin>
                        <ConsumerUsersList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "providers/:providerId/products",
                element: (
                    <RouteGuardProviders>
                        <ProviderProductsList />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/attributes",
                element: (
                    <RouteGuardProviders>
                        <AttributesList />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/attributes/create",
                element: (
                    <RouteGuardProviders>
                        <CreateAttribute />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/attributes/:attributeId/edit",
                element: (
                    <RouteGuardProviders>
                        <EditAttribute />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/price-types",
                element: (
                    <RouteGuardProviders>
                        <PriceTypesList />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/price-types/create",
                element: (
                    <RouteGuardProviders>
                        <CreatePriceType />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/price-types/:priceTypeId/edit",
                element: (
                    <RouteGuardProviders>
                        <EditPriceType />
                    </RouteGuardProviders>
                ),
            },
            {
                path: "providers/:providerId/products/:productId/variants",
                element: (
                    <RouteGuardProviders>
                        <VariantsList />
                    </RouteGuardProviders>
                ),
            },

            {
                path: "categories",
                element: (
                    <RouteGuardAdmin>
                        <CategoryList />
                    </RouteGuardAdmin>
                ),
            },
            {
                path: "agreements/:agreementId/export-mapping",
                element: (
                    <RouteGuardAdmin>
                        <ExportMapping />
                    </RouteGuardAdmin>
                ),
            },
        ],
    },
]);

export default router;
