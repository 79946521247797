import Button from "@mui/material/Button";
import { ICancelButtonProps } from "../../utilities/types";

export const CancelButton = ({onCancelClickHandler}: ICancelButtonProps) => {
    return (
        <Button onClick={onCancelClickHandler}
        variant="text"
        sx={{
            bgcolor: "#f9f9f9",
            color: "black",
            textTransform: "none",
            fontWeight: "bold",
        }}
    >
        Cancel
    </Button>
    );
};