import { ChangeEvent, useState, FormEvent, useCallback } from "react";
import { UpdateUserDto } from "../services/openapi";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
    firstName: ErrorFields;
    lastName: ErrorFields;
    email: ErrorFields;
    primaryPhone: ErrorFields;
}

export const useFormEditUser = (
    initialValues: UpdateUserDto,
    onSubmitHandler: (data: UpdateUserDto) => void
) => {
    const [values, setValues] = useState<UpdateUserDto>(initialValues);
    const [formErrors, setFormErrors] = useState<Errors>({
        firstName: {
            error: false,
            errorMessage: "",
        },
        lastName: {
            error: false,
            errorMessage: "",
        },
        email: {
            error: false,
            errorMessage: "",
        },
        primaryPhone: {
            error: false,
            errorMessage: "",
        },
    });
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    };

    const validateValues = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/gm;
        const newErrors: Errors = {
            firstName: {
                error: false,
                errorMessage: "",
            },
            lastName: {
                error: false,
                errorMessage: "",
            },
            email: {
                error: false,
                errorMessage: "",
            },
            primaryPhone: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;
        if (!values.firstName) {
            newErrors.firstName.error = true;
            newErrors.firstName.errorMessage = "This field is required";
            isValid = false;
        }
        if (!values.lastName) {
            newErrors.lastName.error = true;
            newErrors.lastName.errorMessage = "This field is required";
            isValid = false;
        }
        if (!values.primaryPhone) {
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage = "This field is required";
            isValid = false;
        }
        if (values.primaryPhone && !values.primaryPhone.startsWith("+")) {
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage =
                'Please enter a valid phone number. The phone number should start with "+" followed by country code';
            isValid = false;
        }
        if (!emailRegex.test(values.email)) {
            newErrors.email.error = true;
            newErrors.email.errorMessage = "Please enter a valid email address";
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateValues()) {
            return;
        }
        onSubmitHandler(values);
    };
    const changeValues = useCallback((newValues: UpdateUserDto) => {
        setValues(newValues);
    }, []);

    return {
        values,
        onSubmit,
        onChangeHandler,
        changeValues,
        formErrors,
    };
};
