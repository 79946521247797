/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDataAgreementDto } from '../models/CreateDataAgreementDto';
import type { DataAgreement } from '../models/DataAgreement';
import type { UpdateCategoryMappingsDto } from '../models/UpdateCategoryMappingsDto';
import type { UpdateDataAgreementDto } from '../models/UpdateDataAgreementDto';
import type { UpdateShopSettingsDto } from '../models/UpdateShopSettingsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataAgreementService {

    /**
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerGetDataAgreements(): CancelablePromise<Array<DataAgreement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataAgreement/getDataAgreements',
        });
    }

    /**
     * @param id 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerGetDataAgreement(
id: string,
): CancelablePromise<DataAgreement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataAgreement/getDataAgreement/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerGetDataAgreementsForConsumer(
id: string,
): CancelablePromise<Array<DataAgreement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataAgreement/getDataAgreementsForConsumer',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerGetDataAgreementForProvider(
id: string,
): CancelablePromise<Array<DataAgreement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataAgreement/getDataAgreementsForProvider',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerCreateDataAgreement(
requestBody: CreateDataAgreementDto,
): CancelablePromise<DataAgreement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataAgreement/createDataAgreement',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerUpdateDataAgreement(
id: string,
requestBody: UpdateDataAgreementDto,
): CancelablePromise<DataAgreement> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/dataAgreement/updateDataAgreement/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerDeleteDataAgreement(
id: string,
): CancelablePromise<DataAgreement> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/dataAgreement/deleteDataAgreement/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerUpdateShopSettings(
id: string,
requestBody: UpdateShopSettingsDto,
): CancelablePromise<DataAgreement> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/dataAgreement/updateShopSettings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataAgreement 
     * @throws ApiError
     */
    public static dataAgreementControllerUpdateDataAgreementCategoryMappings(
id: string,
requestBody: UpdateCategoryMappingsDto,
): CancelablePromise<DataAgreement> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/dataAgreement/updateDataAgreementCategoryMappings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
