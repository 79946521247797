/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateImportDto } from '../models/CreateImportDto';
import type { FinishImportDto } from '../models/FinishImportDto';
import type { Import } from '../models/Import';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImportService {

    /**
     * @param requestBody 
     * @returns Import 
     * @throws ApiError
     */
    public static importProductsControllerCreate(
requestBody: CreateImportDto,
): CancelablePromise<Import> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/import-products/createImport',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param deletePreviousImportedProducts 
     * @param requestBody 
     * @returns Import 
     * @throws ApiError
     */
    public static importProductsControllerFinishImport(
deletePreviousImportedProducts: boolean,
requestBody: FinishImportDto,
): CancelablePromise<Import> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/import-products/finishImport',
            query: {
                'deletePreviousImportedProducts': deletePreviousImportedProducts,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param dataProviderId 
     * @returns Import 
     * @throws ApiError
     */
    public static importProductsControllerGetimports(
dataProviderId: string,
): CancelablePromise<Array<Import>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/import-products/getImports',
            query: {
                'dataProviderId': dataProviderId,
            },
        });
    }

    /**
     * @param id 
     * @returns Import 
     * @throws ApiError
     */
    public static importProductsControllerStartImport(
id: string,
): CancelablePromise<Import> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/import-products/startImport/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any 
     * @throws ApiError
     */
    public static importProductsControllerRemoveImportProducts(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/import-products/removeImportProducts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param dataProviderId 
     * @returns any 
     * @throws ApiError
     */
    public static importProductsControllerCleanOldImports(
dataProviderId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/import-products/cleanOldImports',
            query: {
                'dataProviderId': dataProviderId,
            },
        });
    }

}
