import {
    ChangeEvent,
    useState,
    FormEvent,
    SyntheticEvent,
    useRef,
    createRef,
} from "react";
import { FieldPairs, productElementRefs } from "../utilities/types";
import {
    CreateInLanguageDto,
    CreateProductDto,
    InLanguage,
} from "../services/openapi";
import { SelectChangeEvent } from "@mui/material";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface LangValError {
    val: ErrorFields;
    lang: ErrorFields;
}
interface Errors {
    name: LangValError[];
    shortDescription: LangValError[];
    longDescription: LangValError[];
    categories: ErrorFields;
    image: ErrorFields;
}
export const useFormCreateProduct = (
    initialValues: CreateProductDto,
    onSubmitHandler: (data: CreateProductDto) => void,
    files: (File | null)[]
) => {
    const [values, setValues] = useState<CreateProductDto>(initialValues);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [formErrors, setFormErrors] = useState<Errors>({
        name: [
            {
                val: { error: false, errorMessage: "" },
                lang: { error: false, errorMessage: "" },
            },
        ],
        shortDescription: [
            {
                val: { error: false, errorMessage: "" },
                lang: { error: false, errorMessage: "" },
            },
        ],
        longDescription: [
            {
                val: { error: false, errorMessage: "" },
                lang: { error: false, errorMessage: "" },
            },
        ],
        categories: {
            error: false,
            errorMessage: "",
        },
        image: {
            error: false,
            errorMessage: "",
        },
    });

    const handleAddFieldPair = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        fieldName: keyof FieldPairs
    ) => {
        setValues((state) => ({
            ...state,
            name: [...state.name, { lang: InLanguage.lang.UX, val: "" }],
            shortDescription: [
                ...state.shortDescription,
                { lang: InLanguage.lang.UX, val: "" },
            ],
            longDescription: [
                ...state.longDescription,
                { lang: InLanguage.lang.UX, val: "" },
            ],
            // [fieldName]: [...state[fieldName], { lang: InLanguage.lang.UX, val: "" }]
        }));
    };

    const handleRemoveFieldPair = (
        fieldName: keyof FieldPairs,
        index: number
    ) => {
        setValues((state) => ({
            ...state,
            name: state.name.filter((pair, i: number) => i !== index),
            shortDescription: state.shortDescription.filter(
                (pair, i: number) => i !== index
            ),
            longDescription: state.longDescription.filter(
                (pair, i: number) => i !== index
            ),
            // [fieldName]: state[fieldName].filter((_: CreateProductAttrDto | CreateInLanguageDto, i: number) => i !== index)
        }));
    };
    const onlanguageSelectHandler = (
        e: SyntheticEvent,
        selectedValue: CreateInLanguageDto.lang | null,
        fieldName: keyof FieldPairs,
        index: number
    ) => {
        if (selectedValue) {
            setValues((state) => ({
                ...state,
                name: state.name.map((pair, i) =>
                    i === index ? { ...pair, lang: selectedValue } : pair
                ),
                shortDescription: state.shortDescription.map((pair, i) =>
                    i === index ? { ...pair, lang: selectedValue } : pair
                ),
                longDescription: state.longDescription.map((pair, i) =>
                    i === index ? { ...pair, lang: selectedValue } : pair
                ),
                // [fieldName]: state[fieldName].map((pair, i) => (i === index ? { ...pair, "lang": selectedValue } : pair))
            }));
        }
    };
    const onChangeHandler = (
        e:
            | SelectChangeEvent
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName?: keyof FieldPairs,
        index?: number
    ) => {
        if (!fieldName) {
            setValues((state) => ({
                ...state,
                [e.target.name]: e.target.value,
            }));
        } else if (fieldName) {
            setValues((state) => ({
                ...state,
                [fieldName]: state[fieldName].map((pair, i) =>
                    i === index
                        ? { ...pair, [e.target.name]: e.target.value }
                        : pair
                ),
            }));
        }
    };
    const handleNodeSelect = (event: React.MouseEvent, nodeId: string) => {
        event.stopPropagation();

        const selectedIndex = selectedCategories.indexOf(nodeId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCategories, nodeId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCategories.slice(1));
        } else if (selectedIndex === selectedCategories.length - 1) {
            newSelected = newSelected.concat(selectedCategories.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCategories.slice(0, selectedIndex),
                selectedCategories.slice(selectedIndex + 1)
            );
        }
        setSelectedCategories(newSelected);
    };

    const elementReferences: productElementRefs = {
        imageRef: useRef<HTMLInputElement>(null),
        nameRef: values["name"].map(() => createRef<HTMLDivElement>()),
        shortDescRef: values["shortDescription"].map(() =>
            createRef<HTMLDivElement>()
        ),
        longDescRef: values["longDescription"].map(() =>
            createRef<HTMLDivElement>()
        ),
    };

    const validateValues = () => {
        const newErrors: Errors = {
            name: [],
            shortDescription: [],
            longDescription: [],
            categories: {
                error: false,
                errorMessage: "",
            },
            image: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;
        let firstErrorField: React.RefObject<HTMLElement> | null = null;

        if (files[0] === null || files[0] === undefined) {
            newErrors.image.error = true;
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.imageRef;
        }
        values.name.forEach((name, index) => {
            const nameError = {
                val: {
                    error: false,
                    errorMessage: "",
                },
                lang: {
                    error: false,
                    errorMessage: "",
                },
            };

            if (name.val === "") {
                nameError.val.error = true;
                nameError.val.errorMessage =
                    "Name field is required. Maximum name length 40 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.nameRef[index];
            }

            if (name.lang === "UX") {
                nameError.lang.error = true;
                nameError.lang.errorMessage = "Language field is required";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.nameRef[index];
            }

            newErrors.name.push(nameError);
            // });
            // values.shortDescription.forEach((pair, index) => {
            const shortDescError = {
                val: {
                    error: false,
                    errorMessage: "",
                },
                lang: {
                    error: false,
                    errorMessage: "",
                },
            };

            if (values.shortDescription[index].val === "") {
                shortDescError.val.error = true;
                shortDescError.val.errorMessage =
                    "Short description field is required. Maximum text length 2000 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.shortDescRef[index];
            }

            newErrors.shortDescription.push(shortDescError);
            // })
            // values.longDescription.forEach((pair, index) => {
            const longDescError = {
                val: {
                    error: false,
                    errorMessage: "",
                },
                lang: {
                    error: false,
                    errorMessage: "",
                },
            };

            if (values.longDescription[index].val === "") {
                longDescError.val.error = true;
                longDescError.val.errorMessage =
                    "Long description field is required. Maximum text length 5000 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.longDescRef[index];
            }

            newErrors.longDescription.push(longDescError);
        });
        if (selectedCategories.length === 0) {
            newErrors.categories.error = true;
            isValid = false;
        }

        setFormErrors(newErrors);
        if (firstErrorField) {
            firstErrorField.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
        return isValid;
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateValues()) {
            return;
        }

        onSubmitHandler(values);
    };

    return {
        values,
        handleNodeSelect,
        selectedCategories,
        onSubmit,
        onChangeHandler,
        onlanguageSelectHandler,
        formErrors,
        elementReferences,
        handleAddFieldPair,
        handleRemoveFieldPair,
    };
};
