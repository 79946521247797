import { Link } from "react-router-dom";
import { Toolbar, Button, TextField } from "@mui/material";
import { ISearchVariantToolbarProps } from "../../../utilities/types";

export function AddPriceTypeToolbar({
    onSearchChangeHandler,
    id,
    productId
}: ISearchVariantToolbarProps) {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
                gap: 2
                // justifyContent: "space-between",
            }}
        >
            <TextField
                id="outlined-search"
                label="Search"
                type="search"
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#eeedf1',
                    },
                  }}
                onChange={onSearchChangeHandler}
            />
            <Link to={`/providers/${id}/products/${productId}/price-types/create`}>
            <Button
                variant="contained"
                sx={{
                    bgcolor: "#3e97ff",
                    fontWeight: "bold",
                }}
            >
                Add Price Type
            </Button>
            </Link>
        </Toolbar>
    );
};
