import { Typography } from "@mui/material";

export default function AboutPage() {
    return (
        <>
            <Typography variant="h6"><b>Application Details:</b></Typography>
            <Typography variant="h6">Arkforms Connect Version: 1.0</Typography>
                        
        </>
    );
}
