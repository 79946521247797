/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ObjectId } from './ObjectId';
import type { User } from './User';

export type DataConsumerUser = {
    userId: ObjectId;
    user: User;
    userRole: DataConsumerUser.userRole;
};

export namespace DataConsumerUser {

    export enum userRole {
        USER = 'USER',
        ADMIN = 'ADMIN',
    }


}
