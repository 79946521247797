import {
    Autocomplete,
    CircularProgress,
    TextField,
    Typography,
} from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import {
    Category,
    CategoryHierarchyService,
    CategoryService,
    DataAgreementService,
    ShopExportService,
    UpdateCategoryMappingDto,
    WooCommerceProductCategoryClass,
} from "../../services/openapi";

import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_TableOptions,
    useMaterialReactTable,
    MRT_ToggleGlobalFilterButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleDensePaddingButton,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import "../../utilities/sweet-alert.css";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import { ParamTypes } from "../../utilities/types";
import Spinner from "../common/spinner";

type CategoryWithChildren = Category & {
    subRows: CategoryWithChildren[]; // Recursive subarray of CategoryWithChildren
};
type DataRow = CategoryWithChildren & {
    mappedCategories: UpdateCategoryMappingDto[];
    fetchedWebshopCategories: WooCommerceProductCategoryClass[];
};
type MappedArray = { [key: string]: CategoryWithChildren };

export const ExportMapping = () => {

    const [validationErrors, setValidationErrors] = useState<
        Record<string, string | undefined>
    >({});

    const { agreementId } = useParams<keyof ParamTypes>() as ParamTypes;
    const [mappedCategories, setMappedCategories] = useState<
        UpdateCategoryMappingDto[]
    >([]);
    const [fetchedWebshopCategories, setFetchedWebshopCategories] = useState<
        WooCommerceProductCategoryClass[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingWebshopCategories, setIsLoadingWebshopCategories] =
        useState(false);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();

    //READ hook (get users from api)
    function useGetCategories() {
        return useQuery<CategoryWithChildren[]>({
            queryKey: ["categories"],
            queryFn: async () => {
                try {
                    setIsLoading(true);
                    const categoryHierarchies =
                        await CategoryHierarchyService.categoryHierarchyControllerFindall();
                    const categoryHierarchyId =
                        categoryHierarchies[0]._id.toString();
                    const categories =
                        await CategoryService.categoryControllerFindall(
                            categoryHierarchyId
                        );
                    const agreement =
                        await DataAgreementService.dataAgreementControllerGetDataAgreement(
                            agreementId
                        );
                    setMappedCategories(
                        categories.map((category) => {
                            return {
                                datahubCategoryId: category._id.toString(),
                                shopCategoryIds:
                                    agreement.categoryMappings.find(
                                        (item) =>
                                            item.datahubCategoryId ===
                                            category._id.toString()
                                    )?.shopCategoryIds || [],
                            };
                        })
                    );
                    function unflatten(items: Category[]) {
                        const tree = [],
                            mappedArr: MappedArray = {};
    
                        // Build a hash table and map items to objects
                        items.forEach((item) => {
                            const id = item._id.toString();
                            if (!mappedArr.hasOwnProperty(id)) {
                                // in case of duplicates
                                mappedArr[id] = item as CategoryWithChildren; // the extracted id as key, and the item as value
                                mappedArr[id].subRows = []; // under each item, add a key "children" with an empty array as value
                            }
                        });
    
                        // Loop over hash table
                        for (let id in mappedArr) {
                            if (mappedArr.hasOwnProperty(id)) {
                                let mappedElem = mappedArr[id];
    
                                // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
                                if (mappedElem.parentId) {
                                    const parentId = mappedElem.parentId.toString();
                                    //   check if the parent exists until the backend issue with deleting parent is fixed
                                    if (mappedArr[parentId]) {
                                        mappedArr[parentId].subRows.push(
                                            mappedElem
                                        );
                                    }
                                }
    
                                // If the element is at the root level, directly push to the tree
                                else {
                                    tree.push(mappedElem);
                                }
                            }
                        }
    
                        return tree;
                    }
                    const result = unflatten(categories);
                    setIsLoading(false);
                    return result;
                } catch (error: any) {
                    console.error(error);
                    setError(error.message);
                    setIsLoading(false);
                    return [];
                }
                
            },
            refetchOnWindowFocus: false,
        });
    }

    const uploadCategories = async () => {
        setIsLoadingWebshopCategories(true);
        setStatusMessage("Checking API keys");
        try {
            try {
                const { wooCommerceCredStatus, wordPressCredStatus } =
                    await ShopExportService.shopExportControllerTestConsumerConnection(
                        agreementId
                    );
                if (!wooCommerceCredStatus || !wordPressCredStatus) {
                    setIsLoadingWebshopCategories(false);
                    Swal.fire({
                        text: "Please enter valid API keys first",
                        showCancelButton: true,
                        cancelButtonText: "Cancel",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Enter API keys",
                        icon: "error",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate(
                                `/agreements/${agreementId}/shop-credentials`
                            );
                        }
                    });
                    return;
                }
            } catch (error: any) {
                console.error(error);
                setError(error.message);
                setIsLoadingWebshopCategories(false);
            }
            setStatusMessage("Downloading Categories from Consumer System");
            const fetchedWebshopCategories =
                await ShopExportService.shopExportControllerGetShopCategories(
                    agreementId
                );
            // Create a new array with only unique category names
            const webshopCategories = fetchedWebshopCategories.filter(
                (category, index, self) =>
                    index === self.findIndex((c) => c.name === category.name)
            );
            webshopCategories.sort((a, b) => a.name.localeCompare(b.name));
            setFetchedWebshopCategories(webshopCategories);
            setIsLoadingWebshopCategories(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoadingWebshopCategories(false);
        }
    };

    //    call READ hook
    const {
        data: fetchedCategories = [],
        isError: isLoadingCategoriesError,
        isFetching: isFetchingCategories,
        isLoading: isLoadingCategories,
    } = useGetCategories();

    const addPropertiesToRows = (rows: CategoryWithChildren[]): DataRow[] => {
        return rows.map((row) => ({
            ...row,
            mappedCategories: mappedCategories,
            fetchedWebshopCategories: fetchedWebshopCategories,
            subRows: addPropertiesToRows(row.subRows || []),
        }));
    };

    const data: DataRow[] = addPropertiesToRows(fetchedCategories);

    const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
        () => [
            {
                accessorKey: "_id",
                header: "Id",
                enableEditing: false,
                // size: 80,
            },
            {
                accessorKey: "name",
                header: "Category Name",
                // size: 80,
                muiTableHeadCellProps: {
                    style: {
                        fontWeight: "bold", // Set the font weight as needed
                        // Add more styles as needed
                        color: "#99a1b7",
                        fontSize: 12,
                        textTransform: "uppercase",
                    },
                },
                enableEditing: false,

                muiEditTextFieldProps: {
                    autoFocus: true,
                    required: true,
                    inputProps: {
                        style: {
                            fontSize: "14px",
                        },
                    },
                    error: !!validationErrors?.categoryName,
                    helperText: validationErrors?.categoryName,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            categoryName: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: "webshopCategories",
                header: "Webshop Categories",
                Cell: ({ row }) => {
                    const mappedCategory = row.original.mappedCategories?.find(
                        (category) => category.datahubCategoryId === row.id
                    );
                    // Check if the mappedCategory exists and render its shopCategoryIds
                    if (mappedCategory) {
                        return mappedCategory.shopCategoryIds.join(", ");
                    }
                },
                Edit: ({ row }) => {
                    const [selectedOptions, setSelectedOptions] = useState<
                        WooCommerceProductCategoryClass[]
                    >([]);

                    useEffect(() => {
                        const mappedCategory =
                            row.original.mappedCategories?.find(
                                (category) =>
                                    category.datahubCategoryId === row.id
                            );
                        if (mappedCategory) {
                            setSelectedOptions(
                                row.original.fetchedWebshopCategories.filter(
                                    (category) =>
                                        mappedCategory.shopCategoryIds.includes(
                                            category.name
                                        )
                                )
                            );
                        }
                    }, [row]);
                    return (
                        <Autocomplete
                            options={row.original.fetchedWebshopCategories}
                            autoHighlight
                            autoSelect
                            disableClearable={true}
                            getOptionLabel={(option) => option.name}
                            value={selectedOptions}
                            multiple
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => {
                                const newSelectedOptions = value || [];
                                setSelectedOptions(newSelectedOptions);
                                row._valuesCache["webshopCategories"] =
                                    value || [];
                            }}
                        />
                    );
                },
            },
        ],
        [validationErrors]
    );
    // const updateCategories = async () => {
    //           try {
    //         setIsLoading(true);
    //         const updatedAgreement =
    //             await DataAgreementService.dataAgreementControllerUpdateDataAgreementCategoryMappings(
    //                 agreementId,
    //                 { updateCategoryMappings: mappedCategories }
    //             );
    //         navigate("/agreements");
    //         setIsLoading(false);
    //     } catch (error) {
    //         console.error(error);
    //         setIsLoading(false);
    //     }
    // };
    function useUpdateCategory() {
        return useMutation({
            mutationFn: async ({ values, row }: { values: any; row: any }) => {
                const updatedCategories: UpdateCategoryMappingDto[] =
                    mappedCategories.map((item) => {
                        return item.datahubCategoryId === row.id
                            ? {
                                  ...item,
                                  shopCategoryIds: values.webshopCategories.map(
                                      (option: any) => option.name
                                  ),
                              }
                            : item;
                    });

                //   //send api update request here
                try {
                    await DataAgreementService.dataAgreementControllerUpdateDataAgreementCategoryMappings(
                        agreementId,
                        { updateCategoryMappings: updatedCategories }
                    );
                } catch (error: any) {
                    console.error(error);
                    setError(error.message);
                }
                setMappedCategories(updatedCategories);
            },
        });
    }
    //   //call UPDATE hook
    const { mutateAsync: updateCategory, isPending: isUpdatingCategory } =
        useUpdateCategory();

    //   //UPDATE action
    const handleSaveCategory: MRT_TableOptions<DataRow>["onEditingRowSave"] =
        async ({ values, row, table }) => {
            if (fetchedWebshopCategories.length === 0) {
                Swal.fire({
                    // title: "Error",
                    text: "Please load webshop categories first",
                    icon: "error",
                });
                return;
            }

            await updateCategory({ values, row });
            table.setEditingRow(null); //exit editing mode
        };

    // function findCategoryInTree(
    //     parentId: string | null,
    //     categories: CategoryWithChildren[]
    // ): CategoryWithChildren | null {
    //     for (let i = 0; i < categories.length; i++) {
    //         if (categories[i]._id === parentId) {
    //             return categories[i];
    //         }
    //         if (categories[i].subRows) {
    //             const found = findCategoryInTree(
    //                 parentId,
    //                 categories[i].subRows!
    //             );
    //             if (found) return found;
    //         }
    //     }
    //     return null;
    // }

    const table = useMaterialReactTable({
        columns,
        data: data,
        createDisplayMode: "row", // ('modal', and 'custom' are also available)
        editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
        enableColumnPinning: true,
        enableSortingRemoval: false,
        enablePagination: false,
        enableEditing: true,
        enableExpanding: true,
        enableColumnActions: false,
        // positionCreatingRow: creatingRowIndex, //index where new row is inserted before
        getRowId: (row) => row?._id?.toString(),
        muiToolbarAlertBannerProps: isLoadingCategoriesError
            ? {
                  color: "error",
                  children: "Error loading data",
              }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: "500px",
            },
        },
        icons: {
            //change sort icon, connect internal props so that it gets styled correctly

            ArrowDownwardIcon: KeyboardArrowDown, //best practice
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ToggleGlobalFilterButton table={table} />
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ToggleFiltersButton table={table} />
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ToggleDensePaddingButton table={table} />
            </>
        ),
        muiTableBodyRowProps: ({ row }) => ({
            //conditional styling based on row depth
            sx: (theme) => ({
                td: {
                    backgroundColor: (() => {
                        if (row.depth === 0) {
                            return "#b2b2b2";
                        } else if (row.depth === 1) {
                            return "#cccccc";
                        } else if (row.depth === 2) {
                            return "#e5e5e5";
                        } else {
                            return "#ffffff";
                        }
                    })(),
                },
            }),
        }),
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveCategory,
        renderRowActions: ({ row, staticRowIndex, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Box display={"flex"} gap={1}>
                <Button
                    sx={{
                        bgcolor: "#3e97ff",
                        fontWeight: "bold",
                        ":hover": { backgroundColor: "#1565c0" },
                    }}
                    variant="contained"
                    onClick={() => {
                        uploadCategories();
                    }}
                >
                    Load categories
                </Button>
            </Box>
        ),

        initialState: {
            columnPinning: { left: ["mrt-row-actions"], right: [] },
            columnVisibility: { _id: false },
            sorting: [{ id: "name", desc: false }],
        },
        state: {
            isLoading: isLoadingCategories,
            isSaving: isUpdatingCategory,
            showAlertBanner: isLoadingCategoriesError,
            showProgressBars: isFetchingCategories,
        },
    });

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {(isLoading || isLoadingCategories || isFetchingCategories) && (
                <Spinner />
            )}

            {isLoadingWebshopCategories && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        pt: 20,
                    }}
                >
                    <CircularProgress size={100} />

                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mt={2}
                    >
                        {statusMessage}
                    </Typography>
                </Box>
            )}
            {!isLoading &&
                !isLoadingCategories &&
                !error &&
                !isFetchingCategories &&
                !isLoadingWebshopCategories && (
                    <Box>
                        <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={"bold"}
                            mx={2}
                            my={2}
                        >
                            Export Mapping
                        </Typography>
                        <MaterialReactTable table={table} />
                    </Box>
                )}
        </>
    );
};
