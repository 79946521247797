/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserDto = {
    firstName: string;
    lastName: string;
    email: string;
    primaryPhone: string;
    userRole: UpdateUserDto.userRole;
};

export namespace UpdateUserDto {

    export enum userRole {
        NONE = 'none',
        PLATFORM_ADMINISTRATOR = 'PlatformAdministrator',
    }


}
