import { Box } from "@mui/material";
import Logo from "../assets/Logo_ARKforms.svg";

export default function HomePage() {

    return (
    //     <Box
    //     sx={{
    //         display: "flex",
    //         minHeight: "100vh",
    //         bgcolor: "#f9f9f9",
    //         color: "black",
    //     }}
    // >
    //     <Sidebar />
       
       <Box
                component="main"
                sx={{
                    my: 12,
                    mx: 5,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 120px)",
                    px: 2,
                    py: 3,
                }}
            >
                        <img src={Logo} alt="ARKForms logo" width="600px" />
            </Box>
    /* </Box> */
    );
}