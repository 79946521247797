import { Box, Typography } from "@mui/material";
import Sidebar from "../components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Logo from "../assets/Logo_ARKforms.svg";
import { useEffect, useState } from "react";

export default function HomeRootPage() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        }
    }, []);

    return (
        <>
            {isMobile && (
                <Box
                    sx={{
                        display: "flex",
                        minHeight: "100vh",
                        bgcolor: "#fcfcfc",
                        color: "black",
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: 12,
                            mx: 5,
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            overflowY: "auto",
                            maxHeight: "calc(100vh - 120px)",
                            px: 2,
                            py: 3,
                        }}
                    >
                        <img
                            src={Logo}
                            alt="ARKForms logo"
                            style={{
                                width: window.innerWidth < 600 ? 200 : 300,
                            }}
                        />
                        <Typography
                            align={"center"}
                            variant="h5"
                            sx={{
                                mt: 3,
                                fontSize: {
                                    xs: 16,
                                    sm: 18,
                                },
                            }}
                        >
                            This application is currently not optimized for
                            mobile use
                        </Typography>
                    </Box>
                </Box>
            )}

            {!isMobile && (
                <Box
                    sx={{
                        display: "flex",
                        minHeight: "100vh",
                        bgcolor: "#fcfcfc",
                        color: "black",
                    }}
                >
                    <Sidebar />
                    <Box
                        component="main"
                        sx={{
                            my: 12,
                            mx: 5,
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // overflowY: "auto",
                            // maxHeight: "calc(100vh - 120px)",
                            // px: 2,
                            // py: 3,
                        }}
                    >
                        <Outlet />
                    </Box>
                </Box>
            )}
        </>
    );
}
