import { Toolbar, Button, TextField } from "@mui/material";
import { IExportTableToolbarProps } from "../../../utilities/types";
import Swal from "sweetalert2";

export function SearchExportsToolbar({
    onSearchChangeHandler,
    selected,
    exportProducts,
    exportDeleteProducts,
    clearSelected,
}: IExportTableToolbarProps) {
    const handleClickOpen = () => {
        if (selected === "") {
            Swal.fire({
                icon: "error",
                text: "Please select an agreement first",
            });
            return;
        }
        Swal.fire({
            title: "Are you sure you want to export the selected agreement?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, cancel",
            confirmButtonText: "Yes, export!",
        }).then((result) => {
            if (result.isConfirmed) {
                exportProducts();
            } else if (result.isDismissed) {
                clearSelected();
            }
        });
        // setOpen(true);
    };
    const handleDeleteClickOpen = () => {
        if (selected === "") {
            Swal.fire({
                icon: "error",
                text: "Please select an agreement first",
            });
            return;
        }
        exportDeleteProducts();
    };
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
                gap: 2,
                alignItems: "center",
                // justifyContent: "space-between",
            }}
        >
            {/* {selected.length > 0 ? (
                              <Typography variant="subtitle1" component="div" fontWeight={"bold"}>
                              {multiSelectCheck(selected)
                                  ? `${numSelected} agreements selected`
                                  : "1 agreement selected"}
                          </Typography>
                    ) : ( */}
            <TextField
                id="outlined-search"
                label="Search"
                type="search"
                size="small"
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: "#eeedf1",
                    },
                }}
                onChange={onSearchChangeHandler}
            />
            {/* )} */}
            {/* { selected.length > 0 && */}
            <Button
                variant="contained"
                sx={{
                    bgcolor: "#3e97ff",
                    fontWeight: "bold",
                    // py: "6px",
                    // lineHeight: "38px",
                    // height: "40px",
                    // fontSize: "30px",
                    // display: "inline-block"
                }}
                onClick={handleClickOpen}
            >
                Export
            </Button>
            <Button
                variant="contained"
                sx={{
                    bgcolor: "#3e97ff",
                    fontWeight: "bold",
                    // py: "6px",
                    // lineHeight: "38px",
                    // height: "40px",
                    // fontSize: "30px",
                    // display: "inline-block"
                }}
                onClick={handleDeleteClickOpen}
            >
                Delete and Export
            </Button>
            {/* } */}
        </Toolbar>
    );
}
