import {
    TextField,
    Button,
    Box,
    Typography,
    Autocomplete,
    createFilterOptions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    CreateDataAgreementDto,
    DataAgreementService,
    DataConsumer,
    DataConsumersService,
    DataProvider,
    DataProvidersService,
} from "../../services/openapi";
import { CancelButton } from "../common/CancelButton";
import { agreementLanguages, webshopSolutions } from "../../utilities/utils";
import { useEffect, useState } from "react";
import Spinner from "../common/spinner";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormCreateAgreement } from "../../hooks/useFormCreateAgreement";

export default function CreateAgreement() {
    const [providers, setProviders] = useState<DataProvider[]>([]);
    const [consumers, setConsumers] = useState<DataConsumer[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        Promise.all([
            DataProvidersService.dataProviderControllerFindAllv1(),
            DataConsumersService.dataConsumerControllerFindAllv1(),
        ])
            .then(([providersData, consumersData]) => {
                setProviders(providersData.data);
                setConsumers(consumersData.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    const dimensions: string[] = ["Metric (cm)", "Imperial (inches)"];
    const initialValues: CreateDataAgreementDto = {
        dataProviderId: "",
        dataConsumerId: "",
        agreementStartDate: dayjs().startOf("day").format("YYYY-MM-DD"),
        agreementEndDate: dayjs()
            .startOf("day")
            .add(1, "year")
            .format("YYYY-MM-DD"),
        priceType: "",
        webshopSolution: CreateDataAgreementDto.webshopSolution.WOOCOMMERCE,
        language: CreateDataAgreementDto.language.EN,
        priceFactor: 1.0,
        isMetric: true,
    };
    const onCreateSubmitHandler = async (
        data: CreateDataAgreementDto
    ): Promise<void> => {
        setIsLoading(true);
        const updatedData = {
            ...data,
            priceFactor: Number(data.priceFactor.toString().replace(",", ".")),
        };
        try {
            await DataAgreementService.dataAgreementControllerCreateDataAgreement(
                updatedData
            );
            navigate("/agreements");
            setIsLoading(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const {
        values,
        onChangeHandler,
        onSubmit,
        onProviderSelectHandler,
        onConsumerSelectHandler,
        onTargetSelectHandler,
        onStartDateChangeHandler,
        onPriceTypeChangeHandler,
        priceTypes,
        formErrors,
        elementReferences,
        onDimensionsChangeHandler,
        onLanguageChangeHandler,
        onEndDateChangeHandler,
    } = useFormCreateAgreement(initialValues, onCreateSubmitHandler);

    const navigate = useNavigate();

    const onCancelClickHandler = () => {
        navigate("/agreements");
    };

    return (
        <>
            {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <Box
                    component="form"
                    noValidate
                    // sx={{
                    //     maxWidth: "60%",
                    // }}
                    width="100%"
                    action="POST"
                    onSubmit={onSubmit}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                    >
                        Add Data Access Agreement
                    </Typography>
                    <Box display={"flex"} gap={1}>
                        <Box
                            sx={{
                                border: "1px solid #0000001f",
                                width: "100%",
                                p: 3,
                                borderRadius: "12px",
                                mb: 3,
                            }}
                        >
                            <Typography
                                component="h6"
                                variant="h6"
                                fontSize={"16px"}
                                fontWeight={"bold"}
                                // mx={2}
                                mb={1}
                            >
                                Data Provider
                            </Typography>

                            <Autocomplete
                                id="providers-select"
                                ref={elementReferences.companiesRef}
                                sx={{ mt: 2 }}
                                options={providers}
                                autoHighlight
                                autoSelect
                                aria-required
                                disableClearable={true}
                                getOptionLabel={(option) => option.companyName}
                                filterOptions={createFilterOptions({
                                    matchFrom: "start",
                                    stringify: (option) => option.companyName,
                                })}
                                onChange={onProviderSelectHandler}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.companyName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Data Provider"
                                        sx={{ width: "100%" }}
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        error={formErrors.dataProviderId.error}
                                        helperText={
                                            formErrors.dataProviderId.error
                                                ? formErrors.dataProviderId
                                                      .errorMessage
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Box>
                        <Box
                            sx={{
                                border: "1px solid #0000001f",
                                width: "100%",
                                p: 3,
                                borderRadius: "12px",
                                mb: 3,
                            }}
                        >
                            <Typography
                                component="h6"
                                variant="h6"
                                fontSize={"16px"}
                                fontWeight={"bold"}
                                // mx={2}
                                mb={1}
                            >
                                Data Consumer
                            </Typography>

                            <Autocomplete
                                id="consumers-select"
                                sx={{ mt: 2 }}
                                options={consumers}
                                ref={elementReferences.companiesRef}
                                autoHighlight
                                autoSelect
                                aria-required
                                disableClearable={true}
                                getOptionLabel={(option) => option.companyName}
                                filterOptions={createFilterOptions({
                                    matchFrom: "start",
                                    stringify: (option) => option.companyName,
                                })}
                                onChange={onConsumerSelectHandler}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.companyName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Data Consumer"
                                        sx={{ width: "100%" }}
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        error={formErrors.dataConsumerId.error}
                                        helperText={
                                            formErrors.dataConsumerId.error
                                                ? formErrors.dataConsumerId
                                                      .errorMessage
                                                : ""
                                        }
                                    />
                                )}
                            />
                            <Autocomplete
                                id="targetSystem-select"
                                sx={{ mt: 2 }}
                                options={webshopSolutions.sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                )}
                                ref={elementReferences.companiesRef}
                                autoHighlight
                                autoSelect
                                aria-required
                                value={webshopSolutions.find(
                                    (shop) =>
                                        shop.name === values.webshopSolution
                                )}
                                disableClearable={true}
                                getOptionLabel={(option) => option.label}
                                filterOptions={createFilterOptions({
                                    matchFrom: "start",
                                    stringify: (option) => option.label,
                                })}
                                onChange={onTargetSelectHandler}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.label}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Target System"
                                        sx={{ width: "100%" }}
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        error={formErrors.webshopSolution.error}
                                        helperText={
                                            formErrors.webshopSolution.error
                                                ? formErrors.webshopSolution
                                                      .errorMessage
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            border: "1px solid #0000001f",
                            width: "100%",
                            p: 3,
                            borderRadius: "12px",
                            mb: 3,
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Agreement Period
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ width: "60%", mt: 2 }}
                                label="Start Date"
                                value={dayjs(values.agreementStartDate)}
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            disabled: true,
                                        },
                                    },
                                }}
                                onChange={onStartDateChangeHandler}
                                format="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ width: "60%", mt: 2 }}
                                label="End Date"
                                ref={elementReferences.datesRef}
                                value={dayjs(values.agreementEndDate)}
                                slotProps={{
                                    textField: {
                                        error: formErrors.agreementEndDate
                                            .error,
                                        helperText: formErrors.agreementEndDate
                                            .error
                                            ? formErrors.agreementEndDate
                                                  .errorMessage
                                            : "",
                                        inputProps: {
                                            disabled: true,
                                        },
                                    },
                                }}
                                onChange={onEndDateChangeHandler}
                                format="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box
                        sx={{
                            border: "1px solid #0000001f",
                            width: "100%",
                            p: 3,
                            borderRadius: "12px",
                            mb: 3,
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Export Options
                        </Typography>

                        <Autocomplete
                            id="priceType-select"
                            sx={{ mt: 2 }}
                            options={priceTypes.sort((a, b) =>
                                a.localeCompare(b)
                            )}
                            autoHighlight
                            autoSelect
                            aria-required
                            disableClearable={true}
                            value={values.priceType}
                            getOptionLabel={(option) => option}
                            filterOptions={createFilterOptions({
                                matchFrom: "start",
                                stringify: (option) => option,
                            })}
                            onChange={onPriceTypeChangeHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Price Type to be exported"
                                    sx={{ width: "60%" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    error={formErrors.priceType.error}
                                    helperText={
                                        formErrors.priceType.error
                                            ? formErrors.priceType.errorMessage
                                            : ""
                                    }
                                />
                            )}
                        />
                        <TextField
                            sx={{ width: "60%", mt: 2 }}
                            label="Price Factor"
                            name="priceFactor"
                            value={
                                values.priceFactor === 1.0
                                    ? "1,00"
                                    : values.priceFactor
                            }
                            onChange={onChangeHandler}
                            required
                            variant="outlined"
                            margin="dense"
                            error={formErrors.priceFactor.error}
                            helperText={
                                formErrors.priceFactor.error
                                    ? formErrors.priceFactor.errorMessage
                                    : ""
                            }
                        />
                        <Autocomplete
                            id="productLanguages-select"
                            sx={{ mt: 2 }}
                            options={agreementLanguages.sort((a, b) =>
                                a.label.localeCompare(b.label)
                            )}
                            autoHighlight
                            autoSelect
                            aria-required
                            value={agreementLanguages.find(
                                (lang) => lang.code === values.language
                            )}
                            disableClearable={true}
                            getOptionLabel={(option) => option.label}
                            filterOptions={createFilterOptions({
                                matchFrom: "start",
                                stringify: (option) => option.label,
                            })}
                            onChange={onLanguageChangeHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Product Language"
                                    sx={{ width: "60%" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    error={formErrors.language.error}
                                    helperText={
                                        formErrors.language.error
                                            ? formErrors.language.errorMessage
                                            : ""
                                    }
                                />
                            )}
                        />
                        <Autocomplete
                            id="dimensions-select"
                            sx={{ mt: 2 }}
                            options={dimensions}
                            autoHighlight
                            autoSelect
                            aria-required
                            value={
                                values.isMetric
                                    ? "Metric (cm)"
                                    : "Imperial (inches)"
                            }
                            disableClearable={true}
                            getOptionLabel={(option) => option}
                            filterOptions={createFilterOptions({
                                matchFrom: "start",
                                stringify: (option) => option,
                            })}
                            onChange={onDimensionsChangeHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Dimensions to be exported"
                                    sx={{ width: "60%" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <CancelButton
                            onCancelClickHandler={onCancelClickHandler}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                bgcolor: "#3e97ff",
                                fontWeight: "bold",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
}
