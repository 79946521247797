import {
    useState,
    useCallback,
    useEffect,
    MouseEvent,
    ChangeEvent,
} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
    Checkbox,
    TablePagination,
    Avatar,
    TableFooter,
    Button,
} from "@mui/material";
import { IHeadCell, Order, ParamTypes, ValueOf } from "../../utilities/types";
import { Product, ProductsService } from "../../services/openapi";
import Spinner from "../common/spinner";
import { ProductsListHead } from "./ProductsListHead";
import { SearchProductToolbar } from "./SearchProductToolbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import photoPlaceholder from "../../assets/image_placeholder.6e3610d.jpg";
import { DeleteProductsToolbar } from "./DeleteProductsToolbar";
import ActionButtonProducts from "../common/ActionButtonProducts";
import { InfoIcon } from "../../utilities/InfoIcon/InfoIcon";

export const ProviderProductsList = () => {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<ValueOf<IHeadCell>>("name");
    const [selected, setSelected] = useState<string[]>([]);
    const [data, setData] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [skipped, setSkipped] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [error, setError] = useState<string>("");
    const { providerId } = useParams<keyof ParamTypes>() as ParamTypes;

    const navigate = useNavigate();
    useEffect(() => {
        ProductsService.productControllerFindAll(
            searchQuery,
            rowsPerPage,
            skipped,
            order,
            providerId,
            orderBy
        )
            .then((result) => {
                setData(result.data);
                setTotalItems(result.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [
        rowsPerPage,
        skipped,
        order,
        orderBy,
        searchQuery,
        totalItems,
        providerId,
    ]);

    const onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setSkipped(0);
        setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setSkipped(newPage * rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setSkipped(0);
    };

    const handleRequestSort = useCallback(
        (event: MouseEvent<unknown>, property: ValueOf<IHeadCell>) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );
    const handleCloseClick = () => {
        setSelected([]);
    };
    const handleDeleteClick = async () => {
        setIsLoading(true);
        const promises = selected.map((id) => ProductsService.productControllerDelete(id))
        try {
            await Promise.all(promises)
            // setData((state) => state.filter((x) => x._id !== id));
            // setSelected((state) => state.filter((x) => x !== id));
            setTotalItems((state) => state - selected.length);
            setSelected([]);
            setPage(0);
            setSkipped(0);
            setSearchQuery("");
        }
                catch(error: any) {
                    console.error(error);
                    setError(error.message);
                    setIsLoading(false);
                }
        }
    

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n._id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onAddProductButtonHandler = () => {
        navigate(`/providers/${providerId}/create-product`);
    }
    const onHandleImportsButtonHandler = () => {
        navigate(`/providers/${providerId}/imports`);
    };

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mx={2}
                        my={2}
                    >
                        Products
                    </Typography>
                    {data.length === 0 && (
                        <Box
                            sx={{
                                mx: 2,
                                mt: 8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <InfoIcon />
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 24,
                                }}
                            >
                                Empty List
                            </Typography>
                            <Box display={"flex"} gap={2}>
                            <Button onClick={onAddProductButtonHandler}
                                variant="contained"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Add product
                            </Button>
                            <Button onClick={onHandleImportsButtonHandler}
                                variant="contained"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Import product(s)
                            </Button>
                            </Box>
                        </Box>
                    )}
                    {data.length !== 0 && (
                        <>
                            {selected.length > 0 ? (
                                <DeleteProductsToolbar
                                    numSelected={selected.length}
                                    selected={selected}
                                    handleDeleteClick={handleDeleteClick}
                                    handleCloseClick={handleCloseClick}
                                />
                            ) : (
                                <SearchProductToolbar
                                    onSearchChangeHandler={
                                        onSearchChangeHandler
                                    }
                                    id={providerId}
                                />
                            )}
                            <Table
                                aria-label="simple table"
                                // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
                            >
                                <ProductsListHead
                                    numSelected={selected.length}
                                    rowCount={data.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />

                                <TableBody>
                                    {data.map((row, index) => {
                                        const mainImage = row.images.find(img => img.order === 0)
                                        const rowId = row._id.toString();
                                        const isItemSelected =
                                            isSelected(rowId);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                key={rowId}
                                                hover
                                                // role="checkbox"
                                                // aria-checked={isItemSelected}
                                                // tabIndex={-1}
                                                // selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        key={rowId}
                                                        id={labelId}
                                                        color="primary"
                                                        size="small"
                                                        checked={isItemSelected}
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                rowId
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    key={rowId}
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                    sx={{
                                                        p: 0.3,
                                                        height: 60,
                                                        width: "30%",
                                                    }}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        gap={2}
                                                        alignItems={"center"}
                                                    >
                                                        <Link
                                                            to={`/providers/${providerId}/products/${row._id}/variants`}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            <Avatar
                                                                variant="square"
                                                                alt={
                                                                    row.name[0]
                                                                        ?.val
                                                                }
                                                                src={
                                                                    mainImage
                                                                        ? `https://ik.imagekit.io/arkforms/datahubdev/tr:w-40,h-40/${mainImage.storageFilePath}`
                                                                        : 
                                                                        photoPlaceholder
                                                                }
                                                                sx={{
                                                                    bgcolor:
                                                                        "#dbdade",
                                                                    boxShadow:
                                                                        "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                                    width: 40,
                                                                    height: 40,
                                                                }}
                                                            />
                                                        </Link>
                                                        <Box>
                                                            <Link
                                                                to={`/providers/${providerId}/products/${row._id}/variants`}
                                                                style={{
                                                                    textDecoration:
                                                                        "none",
                                                                }}
                                                            >
                                                                <Typography
                                                                    textAlign={
                                                                        "left"
                                                                    }
                                                                    color={
                                                                        "#4b5679"
                                                                    }
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        cursor: "pointer",
                                                                        ":hover":
                                                                            {
                                                                                color: "#3e97ff",
                                                                            },
                                                                        fontWeight:
                                                                            "bold",
                                                                        pr: 1,
                                                                    }}
                                                                >
                                                                    {
                                                                        row
                                                                            .name[0]
                                                                            ?.val
                                                                    }
                                                                </Typography>
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                    {/* <Box
                                                sx={{ textDecoration: "none" }}
                                            >
                                                <Link
                                                    to={`/providers/${rowId}/edit`}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign={"left"}
                                                        color={"#4b5679"}
                                                        sx={{
                                                            fontSize: 14,
                                                            cursor: "pointer",
                                                            ":hover": {
                                                                color: "#3e97ff",
                                                            },
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {row.name[0].val}
                                                    </Typography>
                                                </Link>
                                            </Box> */}
                                                </TableCell>
                                                <TableCell sx={{ p: 0.3, width: "25%" }}>
                                                    <Box>
                                                        <Typography
                                                            align="left"
                                                            sx={{
                                                                fontSize: 14,
                                                                color: "#78829d",
                                                            }}
                                                        >
                                                            <>
                                                                {
                                                                    row.variantsCount
                                                                }
                                                            </>
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ p: 0.3, width: "25%"  }}>
                                                    <Box
                                                        display={"flex"}
                                                        alignItems={"center"}
                                                    >
                                                       {row.status === "DRAFT" ? (
                                                    <>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#FFA500", // Orange color for DRAFT status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for other dots in DRAFT status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for other dots in DRAFT status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for other dots in DRAFT status
                                                                borderRadius: "50%",
                                                                display: "inline-block"
                                                            }}
                                                        ></Box>
                                                    </>
                                                ) : row.status === "READY" ? (
                                                    <>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for first dot in READY status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#0048BA", // Blue color for second dot in READY status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for other dots in READY status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for other dots in READY status
                                                                borderRadius: "50%",
                                                                display: "inline-block"
                                                            }}
                                                        ></Box>
                                                    </>
                                                ) : row.status === "PUBLISHED" ? (
                                                    <>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for first two dots in PUBLISHED status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for third dot in PUBLISHED status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#529873", // Green color for last dot in PUBLISHED status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for last dot in PUBLISHED status
                                                                borderRadius: "50%",
                                                                display: "inline-block"
                                                            }}
                                                        ></Box>
                                                    </>
                                                ) : row.status === "DISCONTINUED" ? (
                                                    <>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for first three dots in DISCONTINUED status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for last dot in DISCONTINUED status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#CCCCCC", // Gray color for last dot in DISCONTINUED status
                                                                borderRadius: "50%",
                                                                display: "inline-block",
                                                                marginRight: 0.5
                                                            }}
                                                        ></Box>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                height: "10px",
                                                                width: "10px",
                                                                bgcolor: "#D10000", // Red color for last dot in DISCONTINUED status
                                                                borderRadius: "50%",
                                                                display: "inline-block"
                                                            }}
                                                        ></Box>
                                                    </>
                                                ) : (
                                                    <>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            height: "10px",
                                                            width: "10px",
                                                            bgcolor: "#CCCCCC", // Default gray color
                                                            borderRadius: "50%",
                                                            display: "inline-block",
                                                            marginRight: 0.5
                                                        }}
                                                    ></Box>
                                                         <Box
                                                        component="span"
                                                        sx={{
                                                            height: "10px",
                                                            width: "10px",
                                                            bgcolor: "#CCCCCC", // Default gray color
                                                            borderRadius: "50%",
                                                            display: "inline-block",
                                                            marginRight: 0.5
                                                        }}
                                                    ></Box>
                                                         <Box
                                                        component="span"
                                                        sx={{
                                                            height: "10px",
                                                            width: "10px",
                                                            bgcolor: "#CCCCCC", // Default gray color
                                                            borderRadius: "50%",
                                                            display: "inline-block",
                                                            marginRight: 0.5
                                                        }}
                                                    ></Box>
                                                         <Box
                                                        component="span"
                                                        sx={{
                                                            height: "10px",
                                                            width: "10px",
                                                            bgcolor: "#CCCCCC", // Default gray color
                                                            borderRadius: "50%",
                                                            display: "inline-block"
                                                        }}
                                                    ></Box>
                                                    </>
                                                )}
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ p: 0.3 }}>
                                                    <ActionButtonProducts
                                                        id={rowId}
                                                        companyId={providerId}
                                                        handleCheckClick={
                                                            handleClick
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15]}
                                            sx={{
                                                borderBottom: 0,
                                                "& .MuiTablePagination-spacer":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                            count={totalItems}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={
                                                handleChangeRowsPerPage
                                            }
                                            showFirstButton
                                            showLastButton
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </>
                    )}
                </>
            )}
        </>
    );
};
