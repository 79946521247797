/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category } from '../models/Category';
import type { CreateCategoryDto } from '../models/CreateCategoryDto';
import type { UpdateCategoryDto } from '../models/UpdateCategoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoryService {

    /**
     * @param categoryHierarchyId 
     * @returns Category 
     * @throws ApiError
     */
    public static categoryControllerFindall(
categoryHierarchyId: string,
): CancelablePromise<Array<Category>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/category',
            query: {
                'categoryHierarchyId': categoryHierarchyId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Category 
     * @throws ApiError
     */
    public static categoryControllerCreate(
requestBody: CreateCategoryDto,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/category',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Category 
     * @throws ApiError
     */
    public static categoryControllerUpdate(
id: string,
requestBody: UpdateCategoryDto,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/category/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Category 
     * @throws ApiError
     */
    public static categoryControllerDelete(
id: string,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/category/{id}',
            path: {
                'id': id,
            },
        });
    }

}
