import { useEffect, useState } from "react";
import { User, UsersService } from "../services/openapi";
import { AuthContext } from "./AuthContext";

type AuthProviderProps = {
    children: React.ReactNode;
  };
  
export const AuthProvider = ({ children }: AuthProviderProps) => {
 const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        UsersService.usersControllerGetMe()
        .then((result) => {
            setUser(result);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);
    
    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    );
};