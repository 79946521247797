import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useContext } from "react";
// import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import GroupIcon from "@mui/icons-material/Group";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CategoryIcon from "@mui/icons-material/Category";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import InventoryIcon from "@mui/icons-material/Inventory";
import "./sidebar.css";
import Logo from "../../assets/Logo_ARKforms.svg";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { AuthContext } from "../../contexts/AuthContext";

const drawerWidth = 260;

export default function Sidebar() {
    // const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const currentUser = useContext(AuthContext);

    // const handleClick = () => {
    //     setOpen(!open);
    // };

    const onLogout = async () => {
        await signOut();
        navigate("/auth");
    };

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                    bgcolor: "#fcfcfc",
                    boxShadow: "none",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    paddingRight: 3,
                }}
            >
                <Toolbar sx={{ justifyContent: "flex-end", paddingRight: 30 }}>
                    <Link to={"/"}>
                        <Button
                            variant="text"
                            size="small"
                            // LinkComponent={Link}
                            sx={{
                                textTransform: "capitalize",
                                fontSize: 16,
                                color: "#000000de",
                                fontWeight: 400,
                                lineHeight: 1.5,
                                letterSpacing: "0.15008px",
                                "&:hover": {
                                    color: "#3e97ff",
                                    boxShadow: "0px 3px 4px rgba(0,0,0,.06)",
                                },
                            }}
                        >
                            Home
                        </Button>
                    </Link>
                    <Link to={"/about"}>
                        <Button
                            variant="text"
                            size="small"
                            // LinkComponent={Link}
                            sx={{
                                textTransform: "capitalize",
                                fontSize: 16,
                                fontWeight: 400,
                                lineHeight: 1.5,
                                letterSpacing: "0.15008px",
                                color: "#000000de",
                                "&:hover": {
                                    color: "#3e97ff",
                                    boxShadow: "0px 3px 4px rgba(0,0,0,.06)",
                                },
                            }}
                        >
                            About
                        </Button>
                    </Link>
                    <Link to={"/contacts"}>
                        <Button
                            variant="text"
                            size="small"
                            sx={{
                                textTransform: "capitalize",
                                fontSize: 16,
                                color: "#000000de",
                                fontWeight: 400,
                                lineHeight: 1.5,
                                letterSpacing: "0.15008px",
                                "&:hover": {
                                    color: "#3e97ff",
                                    boxShadow: "0px 3px 4px rgba(0,0,0,.06)",
                                },
                            }}
                        >
                            Contact
                        </Button>
                    </Link>
                    {/* <Button onClick={onLogout}
                            variant="contained"
                            size="small"
                            // LinkComponent={Link}
                            sx={{ml: 2,
                                bgcolor: "#3e97ff",
                                fontWeight: "bold",
                            }}
                        >
                            Log out
                        </Button> */}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        bgcolor: "#e5e5e5",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar
                    sx={{
                        bgcolor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Link to={"/"}>
                        <img src={Logo} alt="ARKForms logo" width="150px" />
                    </Link>
                </Toolbar>

                <Divider />
                {currentUser !== null && (
                    <List
                        sx={{
                            mt: 14,
                            width: "100%",
                            maxWidth: 360,
                        }}
                        component="nav"
                    >
                        {/* <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Administration" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit> */}
                        <List
                            component="div"
                            disablePadding
                            className="sidebar-item-list"
                        >
                            {currentUser?.dataConsumer && (
                                <Link
                                    to={`/consumers/${currentUser?.dataConsumer._id}/exports`}
                                >
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <SyncAltIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Data Access Agreements" />
                                    </ListItemButton>
                                </Link>
                            )}
                            {currentUser?.dataProvider && (
                                <>
                                    <Link
                                        to={`/providers/${currentUser?.dataProvider._id}/imports`}
                                    >
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <ImportExportIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Import Products" />
                                        </ListItemButton>
                                    </Link>
                                    <Link
                                        to={`/providers/${currentUser?.dataProvider._id}/products`}
                                    >
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <InventoryIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Products" />
                                        </ListItemButton>
                                    </Link>
                                    <Link
                                        to={`/providers/${currentUser?.dataProvider._id}/agreements`}
                                    >
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <SyncAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Data Access Agreements" />
                                        </ListItemButton>
                                    </Link>
                                </>
                            )}
                            {currentUser?.userRole ===
                                "PlatformAdministrator" && (
                                <>
                                    <Link to="/providers">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                {/* <GroupIcon />
                                        <FactoryIcon />
                                        <BusinessCenterIcon /> */}
                                                <WarehouseIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Providers" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to="/consumers">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                {/* <GroupIcon /> */}
                                                <StorefrontIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Consumers" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to="/users">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <GroupIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Users" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to="/categories">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <CategoryIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Categories" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to="/agreements">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <SyncAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Data Access Agreements" />
                                        </ListItemButton>
                                    </Link>
                                </>
                            )}
                            <ListItemButton sx={{ pl: 4 }} onClick={onLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Log out" />
                            </ListItemButton>
                        </List>
                        {/* </Collapse> */}
                    </List>
                )}
                {/* <Divider /> */}
                {/* <List className="sidebar-item-list"> */}
                {/* <ListItemButton>
                        <ListItemIcon>
                            <ShoppingBagIcon />
                        </ListItemIcon>
                        <ListItemText primary="Products" />
                    </ListItemButton> */}

                {/* </List> */}
            </Drawer>
        </Box>
    );
}
