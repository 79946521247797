import { useState, useCallback, MouseEvent, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
    Checkbox,
    Avatar,
    Button,
} from "@mui/material";
import { IHeadCell, Order, ParamTypes, ValueOf } from "../../utilities/types";
import {
    DataConsumersService,
    DataConsumer,
    DataConsumerUser,
    UsersService,
} from "../../services/openapi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ConsumerUsersListHead } from "./ConsumerUsersListHead";
import ActionButtonUsers from "../common/ActionButtonUsers";
import { DeleteUsersToolbar } from "../users/DeleteUserToolbar";
import { SearchUserToolbar } from "../users/SearchUserToolbar";
import Spinner from "../common/spinner";
import { InfoIcon } from "../../utilities/InfoIcon/InfoIcon";

const collection = "consumers";

export const ConsumerUsersList = () => {
    const { consumerId } = useParams<keyof ParamTypes>() as ParamTypes;
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<ValueOf<IHeadCell>>("firstName");
    const [selected, setSelected] = useState<string[]>([]);
    const [users, setUsers] = useState<DataConsumerUser[]>([]);
    const [consumer, setConsumer] = useState<DataConsumer | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        DataConsumersService.dataConsumerControllerFindOne(consumerId)
            .then((result) => {
                setConsumer(result);
                setUsers(result.users);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [consumerId]);
    const handleCloseClick = () => {
        setSelected([]);
    }
    const handleDeleteClick = () => {
        selected.forEach((id) => {
            Promise.all([
                DataConsumersService.dataConsumerControllerRemoveDataConsumerUser(consumerId, id),
                UsersService.usersControllerRemove(id)
            ])            
                .then(() => {
                    setUsers((state) => state.filter((x) => x.user._id !== id));
                    setSelected((state) => state.filter((x) => x !== id));
                })
                .catch((error) => {
                    console.error(error);
                    setError(error.message);
                });
        });
    };

    const handleRequestSort = useCallback(
        (event: MouseEvent<unknown>, property: ValueOf<IHeadCell>) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = users.map((n) => n.user._id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onAddUserButtonHandler = () => {
        navigate(`/consumers/${consumerId}/create-user`);
    }

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
        {isLoading && <Spinner />}
        {!isLoading && !error &&
        <>
            <Typography
                component="h1"
                variant="h6"
                fontWeight={"bold"}
                mx={2}
                my={2}
            >
                {`${consumer?.companyName} - Maintain users`}
            </Typography>
            {users.length === 0 && (
                        <Box
                            sx={{
                                mx: 2,
                                mt: 8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <InfoIcon />
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 24,
                                }}
                            >
                                Empty List
                            </Typography>
                            <Button onClick={onAddUserButtonHandler}
                                variant="contained"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Add User
                            </Button>
                        </Box>
                     )}
                      {users.length !== 0 && (
                        <>
            {selected.length > 0 ? (
                <DeleteUsersToolbar
                    numSelected={selected.length}
                    selected={selected}
                    handleDeleteClick={handleDeleteClick}
                    handleCloseClick={handleCloseClick}
                />
            ) : (
                <SearchUserToolbar
                userOwnerId={consumerId}
                collection={collection}
                />
            )}
            <Table
                aria-label="simple table"
                // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
            >
                <ConsumerUsersListHead
                    numSelected={selected.length}
                    rowCount={users.length}
                    onSelectAllClick={handleSelectAllClick}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />

                <TableBody>
                    {users.map((row, index) => {
                        const userId = row.user?._id.toString();
                        const isItemSelected = isSelected(userId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow
                                key={userId}
                                hover
                                // role="checkbox"
                                // aria-checked={isItemSelected}
                                // tabIndex={-1}
                                // selected={isItemSelected}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        key={userId}
                                        id={labelId}
                                        color="primary"
                                        size="small"
                                        checked={isItemSelected}
                                        onClick={(event) =>
                                            handleClick(event, userId)
                                        }
                                        sx={{ cursor: "pointer" }}
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    key={userId}
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    sx={{
                                        p: 0.3,
                                        height: 60,
                                        width: "25%",
                                    }}
                                >
                                    <Box display={"flex"} gap={2} alignItems={"center"}>
                                    <Link
                                                    to={`/consumers/${consumerId}/users/${userId}/edit`}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <Avatar
                                                        alt={row.user?.firstName}
                                                        src={row.user?.profileImage?.imageId
                                                            ? `https://ik.imagekit.io/arkforms/datahubdev/tr:w-40,h-40/${row.user.profileImage.storageFilePath}`
                                                            :""
                                                        }
                                                        sx={{
                                                            bgcolor: "#dbdade",
                                                            boxShadow:
                                                                "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                            width: 40,
                                                        height: 40,                                                    }}
                                                    >
                                                        {row.user?.firstName.charAt(
                                                            0
                                                        ).toUpperCase() +
                                                            row.user?.lastName.charAt(
                                                                0
                                                            ).toUpperCase()}
                                                    </Avatar>
                                                </Link>
                                                <Box>
                                        <Link
                                            to={`/consumers/${consumerId}/users/${userId}/edit`}
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Typography
                                                textAlign={"left"}
                                                color={"#4b5679"}
                                                sx={{
                                                    fontSize: 14,
                                                    cursor: "pointer",
                                                    ":hover": {color: "#3e97ff"},
                                                    fontWeight: "bold"
                                               
                                                }}
                                            >
                                                {row.user?.firstName}{" "}{row?.user?.lastName}
                                            </Typography>
                                        </Link>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ p: 0.3, width: "25%" }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14, color: "#78829d" }}
                                        >
                                            {row.user?.email}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ p: 0.3, width: "15%" }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14, color: "#78829d" }}
                                        >
                                            {row.user?.primaryPhone}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                {/* <TableCell sx={{ p: 0.3 }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14 }}
                                        >
                                            {row.user.created} 
                                        </Typography>
                                    </Box>
                                </TableCell> */}
                                <TableCell sx={{ p: 0.3, width: "10%" }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14, color: "#78829d" }}
                                        >
                                            {row.userRole}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ p: 0.3, width: "10%" }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14 }}
                                        >
                                            {/* {row.status} */}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ p: 0.3 }}>
                                    <ActionButtonUsers
                                        userId={userId}
                                        userOwnerId={consumerId}
                                        collection={collection}
                                        handleCheckClick={handleClick}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            </>
         )}
            {/* {data.length === 0 && <Typography variant="h3" sx={{textAlign: "center", mt: 4}}>No users</Typography>} */}
            </>}
        </>
    );
};
