import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    Box,
    Typography,
} from "@mui/material";
import { IEnhancedTableProps, IHeadCell } from "../../../utilities/types";

export const EnhancedTableHead = ({
    onRequestSort,
}: IEnhancedTableProps) => {
    const headCells: readonly IHeadCell[] = [
        {
            id: "companyName",
            label: "Provider",
        },
        {
            id: "webshopSolution",
            label: "Target System",
        },
        {
            id: "agreementStatus",
            label: "Agreement Status",
        },
    ];
    // const createSortHandler = useCallback(
    //     (property: ValueOf<IHeadCell>) =>
    //         (event: React.MouseEvent<unknown>) => {
    //             onRequestSort(event, property);
    //         },
    //     [onRequestSort]
    // );
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        size="small"
                        id="checkbox-select-all"
                        disabled
                        // indeterminate={
                        //     numSelected > 0 && numSelected < rowCount
                        // }
                        // checked={rowCount > 0 && numSelected === rowCount}
                        // onChange={onSelectAllClick}
                        // inputProps={{
                        //     "aria-label": "select all",
                        // }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        padding="none"
                        key={headCell.id}
                        // align='left'
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        // sortDirection={orderBy === id ? order : "asc"}
                        sx={{
                            // p: 0.2,
                            textAlign: "left",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                variant="body2"
                                textTransform={"uppercase"}
                                sx={{
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 12,
                                    lineHeight: 0,
                                }}
                            >
                                {headCell.label}
                            </Typography>
                            {/* {headCell.id === "companyName" && (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={
                                        orderBy === headCell.id ? order : "asc"
                                    }
                                    onClick={createSortHandler(headCell.id)}
                                    IconComponent={KeyboardArrowDown}
                                />
                            )} */}
                        </Box>
                    </TableCell>
                ))}
                {/* <TableCell padding="none" sx={{ textAlign: "left" }}>
                    <Box>
                        <Typography
                            variant="body2"
                            textTransform={"uppercase"}
                            sx={{
                                fontWeight: "bold",
                                color: "#99a1b7",
                                fontSize: 12,
                            }}
                        >
                            Actions
                        </Typography>
                    </Box>
                </TableCell> */}
            </TableRow>
        </TableHead>
    );
};
