import {
    useState,
    useCallback,
    MouseEvent,
    useEffect,
    ChangeEvent,
} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
    Checkbox,
    TablePagination,
    Avatar,
    TableFooter,
} from "@mui/material";
import { IHeadCell, Order, ValueOf } from "../../utilities/types";
import { EnhancedTableHead } from "./UsersListHead";
import { DeleteUsersToolbar } from "./DeleteUserToolbar";
import { SearchUserToolbar } from "./SearchUserToolbar";
import { User, UsersService } from "../../services/openapi";
import { Link } from "react-router-dom";
import ActionButtonUsers from "../common/ActionButtonUsers";
import Spinner from "../common/spinner";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import the utc plugin
dayjs.extend(utc); // Use the utc plugin

export const UsersList = () => {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<ValueOf<IHeadCell>>("firstName");
    const [selected, setSelected] = useState<string[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [skipped, setSkipped] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        UsersService.usersControllerFindAllv1(
            searchQuery,
            rowsPerPage,
            skipped,
            order,
            orderBy
        )
            .then((result) => {
                setUsers(result.data);
                setTotalItems(result.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [rowsPerPage, skipped, order, orderBy, totalItems, searchQuery]);

    const onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setSkipped(0);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setSkipped(newPage * rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setSkipped(0);
    };

    const handleCloseClick = () => {
        setSelected([]);
    };
    const handleDeleteClick = async () => {
        setIsLoading(true);
        const promises = selected.map((id) => UsersService.usersControllerRemove(id));
        try {
            await Promise.all(promises);
            setTotalItems((state) => state - selected.length);
            setSelected([]);
            setPage(0);
            setSkipped(0);
            setSearchQuery("");
            } catch (error: any) {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            }
    };

    const handleRequestSort = useCallback(
        (event: MouseEvent<unknown>, property: ValueOf<IHeadCell>) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
            setPage(0);
            setSkipped(0);
        },
        [order, orderBy]
    );

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = users.map((n) => n._id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    return (
        <>
        {error && (
            <div
                id="error-page"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error}</i>
                </p>
            </div>
        )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mx={2}
                        my={2}
                    >
                        Users
                    </Typography>
                    {selected.length > 0 ? (
                        <DeleteUsersToolbar
                            numSelected={selected.length}
                            selected={selected}
                            handleDeleteClick={handleDeleteClick}
                            handleCloseClick={handleCloseClick}
                        />
                    ) : (
                        <SearchUserToolbar
                            onSearchChangeHandler={onSearchChangeHandler}
                        />
                    )}
                    <Table
                        aria-label="simple table"
                        // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={users.length}
                            onSelectAllClick={handleSelectAllClick}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />

                        <TableBody>
                            {users.map((row, index) => {
                                const rowId = row._id.toString();
                                const isItemSelected = isSelected(rowId);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const companyName = row.dataProvider?.companyName || row.dataConsumer?.companyName || "Arkforms";
                                return (
                                    <TableRow
                                        key={rowId}
                                        hover
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        // tabIndex={-1}
                                        // selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                key={rowId}
                                                id={labelId}
                                                size="small"
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(event) =>
                                                    handleClick(event, rowId)
                                                }
                                                sx={{ cursor: "pointer" }}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            key={rowId}
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                p: 0.3,
                                                height: 60,
                                                width: "25%",
                                            }}
                                        >
                                            <Box display={"flex"} gap={2} alignItems={"center"}>
                                                <Link
                                                    to={`/users/${row._id}/edit`}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <Avatar
                                                        alt={row.firstName}
                                                        src={row.profileImage?.imageId
                                                            ? `https://ik.imagekit.io/arkforms/datahubdev/tr:w-40,h-40/${row.profileImage.storageFilePath}`
                                                            :""
                                                        }
                                                        sx={{
                                                            bgcolor: "#dbdade",
                                                            boxShadow:
                                                                "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                            width: 40,
                                                        height: 40,                                                    }}
                                                    >
                                                        {row.firstName.charAt(
                                                            0
                                                        ).toUpperCase() +
                                                            row.lastName.charAt(
                                                                0
                                                            ).toUpperCase()}
                                                    </Avatar>
                                                </Link>
                                                <Box>
                                                    <Link
                                                        to={`/users/${rowId}/edit`}
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        <Typography
                                                              textAlign={"left"}
                                                              color={"#4b5679"}
                                                              sx={{
                                                                  fontSize: 14,
                                                                  cursor: "pointer",
                                                                  ":hover": {color: "#3e97ff"},
                                                                  fontWeight: "bold"
                                                             
                                                              }}
                                                        >
                                                            {row.firstName}{" "}
                                                            {row.lastName}
                                                        </Typography>
                                                    </Link>
                                                    <Typography
                                                        textAlign={"left"}
                                                        color={"#4b5679"}
                                                        sx={{
                                                            fontSize: 14,
                                                            color: "#78829d",
                                                        }}
                                                    >
                                                        {row.email}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0.3, width: "20%", }}>
                                            <Box>
                                                <Typography
                                                    align="left"
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                    }}
                                                >
                                                    {companyName}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0.3, width: "15%", }}>
                                            <Box>
                                                <Typography
                                                    align="left"
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                    }}
                                                >
                                                    {row?.userRole === "none" && "Company User" }
                                                    {row?.userRole === "PlatformAdministrator" && "Arkforms Administrator" }
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        {/* <TableCell sx={{ p: 0.3 }}>
                                            <Box>
                                                <Typography
                                                    align="left"
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                    }}
                                                >
                                                    {row.email}
                                                </Typography>
                                            </Box>
                                        </TableCell> */}
                                        <TableCell sx={{ p: 0.3 }}>
                                            <Box>
                                                <Typography
                                                    align="left"
                                                    sx={{
                                                        fontSize: 14,
                                                        color: "#78829d",
                                                    }}
                                                >
                                                    {/* {row.primaryPhone} */}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0.3 }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14 }}
                                        >
                                            {/* {row.status} */}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ p: 0.3 }}>
                                    <Box>
                                        <Typography
                                            align="left"
                                            sx={{ fontSize: 14, color: "#78829d", }}
                                        >
                                            {dayjs(row?.created)?.startOf("day").format('DD-MM-YYYY')}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                        <TableCell sx={{ p: 0.3 }}>
                                            <ActionButtonUsers
                                                userId={rowId}
                                                handleCheckClick={handleClick}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow >
                            <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        sx={{borderBottom: 0,
                            "& .MuiTablePagination-spacer": {
                                display: "none",
                              }}}                        
                            count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                         </TableRow>
                        </TableFooter>
                    </Table>
  
                    {/* {data.length === 0 && <Typography variant="h3" sx={{textAlign: "center", mt: 4}}>No users</Typography>} */}
                </>
            )}
        </>
    );
};
