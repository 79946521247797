import {
    ChangeEvent,
    useState,
    SyntheticEvent,
    useRef,
    createRef,
    useCallback,
} from "react";
import {
    FieldPairs,
    IDimensionTypes,
    variantElementRefs,
} from "../utilities/types";
import {
    CreateInLanguageDto,
    CreatePriceDto,
    CreateProductVariantDto,
    InLanguage,
    ProductAttr,
} from "../services/openapi";
import { SelectChangeEvent } from "@mui/material";
import { GridValidRowModel } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface LangValError {
    val: ErrorFields;
    lang: ErrorFields;
}
interface Errors {
    startDate: ErrorFields;
    endDate: ErrorFields;
    discontinuedDate: ErrorFields;
    name: LangValError[];
    shortDescription: LangValError[];
    longDescription: LangValError[];
    SKU: ErrorFields;
    image: ErrorFields;
    attributes: ErrorFields;
    prices: ErrorFields;
    productWidthCm: ErrorFields;
    productHeightCm: ErrorFields;
    productLengthCm: ErrorFields;
    productWidthIn: ErrorFields;
    productHeightIn: ErrorFields;
    productLengthIn: ErrorFields;
    shippingWidthCm: ErrorFields;
    shippingHeightCm: ErrorFields;
    shippingLengthCm: ErrorFields;
    shippingWidthIn: ErrorFields;
    shippingHeightIn: ErrorFields;
    shippingLengthIn: ErrorFields;
    shippingNetWeightKg: ErrorFields;
    shippingNetWeightLb: ErrorFields;
    shippingTaraWeightKg: ErrorFields;
    shippingTaraWeightLb: ErrorFields;
    leadTimeBegin: ErrorFields;
    leadTimeEnd: ErrorFields;
}

export const useFormCreateVariant = (
    initialValues: CreateProductVariantDto,
    onSubmitHandler: (data: CreateProductVariantDto) => void,
    files: (File | null)[]
    // elementReferences: variantElementRefs
) => {
    const [values, setValues] =
        useState<CreateProductVariantDto>(initialValues);
    const [formErrors, setFormErrors] = useState<Errors>({
        name: [
            {
                val: { error: false, errorMessage: "" },
                lang: { error: false, errorMessage: "" },
            },
        ],
        shortDescription: [
            {
                val: { error: false, errorMessage: "" },
                lang: { error: false, errorMessage: "" },
            },
        ],
        longDescription: [
            {
                val: { error: false, errorMessage: "" },
                lang: { error: false, errorMessage: "" },
            },
        ],
        startDate: {
            error: false,
            errorMessage: "",
        },
        endDate: {
            error: false,
            errorMessage: "",
        },
        discontinuedDate: {
            error: false,
            errorMessage: "",
        },
        SKU: {
            error: false,
            errorMessage: "",
        },
        image: {
            error: false,
            errorMessage: "",
        },
        attributes: {
            error: false,
            errorMessage: "",
        },
        prices: {
            error: false,
            errorMessage: "",
        },
        productWidthCm: {
            error: false,
            errorMessage: "",
        },
        productHeightCm: {
            error: false,
            errorMessage: "",
        },
        productLengthCm: {
            error: false,
            errorMessage: "",
        },
        productWidthIn: {
            error: false,
            errorMessage: "",
        },
        productHeightIn: {
            error: false,
            errorMessage: "",
        },
        productLengthIn: {
            error: false,
            errorMessage: "",
        },
        shippingWidthCm: {
            error: false,
            errorMessage: "",
        },
        shippingHeightCm: {
            error: false,
            errorMessage: "",
        },
        shippingLengthCm: {
            error: false,
            errorMessage: "",
        },
        shippingWidthIn: {
            error: false,
            errorMessage: "",
        },
        shippingHeightIn: {
            error: false,
            errorMessage: "",
        },
        shippingLengthIn: {
            error: false,
            errorMessage: "",
        },
        shippingNetWeightKg: {
            error: false,
            errorMessage: "",
        },
        shippingNetWeightLb: {
            error: false,
            errorMessage: "",
        },
        shippingTaraWeightKg: {
            error: false,
            errorMessage: "",
        },
        shippingTaraWeightLb: {
            error: false,
            errorMessage: "",
        },
        leadTimeBegin: {
            error: false,
            errorMessage: "",
        },
        leadTimeEnd: {
            error: false,
            errorMessage: "",
        },
    });

    const handleAddAttributePair = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setValues((state) => ({
            ...state,
            productAttrs: [...state["productAttrs"], { name: "", value: "" }],
        }));
    };

    const handleRemoveAttributePair = (index: number) => {
        setValues((state) => ({
            ...state,
            productAttrs: state["productAttrs"].filter(
                (pair, i: number) => i !== index
            ),
        }));
    };

    const handleAddFieldPair = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        fieldName: keyof FieldPairs
    ) => {
        setValues((state) => ({
            ...state,
            name: [...state.name, { lang: InLanguage.lang.UX, val: "" }],
            shortDescription: [
                ...state.shortDescription,
                { lang: InLanguage.lang.UX, val: "" },
            ],
            longDescription: [
                ...state.longDescription,
                { lang: InLanguage.lang.UX, val: "" },
            ],
            // [fieldName]: [...state[fieldName], { lang: InLanguage.lang.UX, val: "" }]
        }));
    };

    const handleRemoveFieldPair = (
        fieldName: keyof FieldPairs,
        index: number
    ) => {
        setValues((state) => ({
            ...state,
            name: state.name.filter((pair, i: number) => i !== index),
            shortDescription: state.shortDescription.filter(
                (pair, i: number) => i !== index
            ),
            longDescription: state.longDescription.filter(
                (pair, i: number) => i !== index
            ),
            // [fieldName]: state[fieldName].filter((pair, i: number) => i !== index)
        }));
    };

    const onlanguageSelectHandler = (
        e: SyntheticEvent,
        selectedValue: CreateInLanguageDto.lang | null,
        fieldName: keyof FieldPairs,
        index: number
    ) => {
        if (selectedValue) {
            setValues((state) => ({
                ...state,
                name: state.name.map((pair, i) =>
                    i === index ? { ...pair, lang: selectedValue } : pair
                ),
                shortDescription: state.shortDescription.map((pair, i) =>
                    i === index ? { ...pair, lang: selectedValue } : pair
                ),
                longDescription: state.longDescription.map((pair, i) =>
                    i === index ? { ...pair, lang: selectedValue } : pair
                ),
                // [fieldName]: state[fieldName].map((pair, i) => (i === index ? { ...pair, "lang": selectedValue } : pair))
            }));
        }
    };

    const handleAttributesChange = useCallback((newRows: GridValidRowModel[]) => {
        const updatedAttributes: ProductAttr[] = newRows.map((row) => {
            return {
                name: row.name,
                value: row.value,
            };
        });
        setValues((state) => ({
            ...state,
            productAttrs: updatedAttributes,
        }));
        // setAttributes(updatedAttributes);
    }, [setValues]);

    const onChangeHandler = (
        e:
            | SelectChangeEvent
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName?: keyof FieldPairs,
        index?: number
    ) => {
        if (!fieldName) {
            setValues((state) => ({
                ...state,
                [e.target.name]: e.target.value,
            }));
        } else if (fieldName) {
            setValues((state) => ({
                ...state,
                [fieldName]: state[fieldName].map((pair, i) =>
                    i === index
                        ? { ...pair, [e.target.name]: e.target.value }
                        : pair
                ),
            }));
        }
    };

    const handlePriceTypesChange = useCallback((newRows: GridValidRowModel[]) => {
        const updatedPrices: CreatePriceDto[] = newRows.map((row) => {
            return {
                priceType: row.priceType,
                currency: row.currency,
                price: row.price,
            };
        });
        setValues((state) => ({
            ...state,
            prices: updatedPrices,
        }));
    }, [setValues]);

    const onDimensionsChangeHandler =
        (dimensionType: keyof IDimensionTypes) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setValues((state) => ({
                ...state,
                [dimensionType]: {
                    ...state[dimensionType],
                    [e.target.name]: e.target.value,
                },
            }));
        };
    const onCountryChangeHandler = (
        e: SyntheticEvent,
        value: CreateProductVariantDto.countryOfOrigin | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                countryOfOrigin: value,
            }));
        }
    };
    const onStatusChangeHandler = (
        e: SyntheticEvent,
        value: CreateProductVariantDto.status | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                status: value,
            }));
        }
        if (value === "PUBLISHED") {
            setValues((state) => ({
                ...state,
                publishedStartDate: dayjs().startOf("day").format("YYYY-MM-DD"),
                publishedEndDate: dayjs()
                    .add(10, "year")
                    .endOf("year")
                    .format("YYYY-MM-DD"),
                discontinuedStartDate: "",
            }));
        }
        if (value === "READY" || value === "DRAFT") {
            setValues((state) => ({
                ...state,
                publishedStartDate: "",
                publishedEndDate: "",
                discontinuedStartDate: "",
            }));
        }
        if (value === "DISCONTINUED") {
            setValues((state) => ({
                ...state,
                discontinuedStartDate: dayjs()
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };

    const onStartDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                publishedStartDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };
    const onEndDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                publishedEndDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };
    const onDiscontinuedDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                discontinuedStartDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        } else {
            setValues((state) => ({
                ...state,
                discontinuedStartDate: "",
            }));
        }
    };
    const elementReferences: variantElementRefs = {
        imageRef: useRef<HTMLDivElement>(null),
        datesRef: useRef<HTMLDivElement>(null),
        nameRef: values["name"].map(() => createRef<HTMLDivElement>()),
        shortDescRef: values["shortDescription"].map(() =>
            createRef<HTMLDivElement>()
        ),
        longDescRef: values["longDescription"].map(() =>
            createRef<HTMLDivElement>()
        ),
        attributesRef: useRef<HTMLDivElement>(null),
        skuRef: useRef<HTMLDivElement>(null),
        metricDimensionsRef: useRef<HTMLDivElement>(null),
        imperialDimensionsRef: useRef<HTMLDivElement>(null),
        metricshippingDimensionsRef: useRef<HTMLDivElement>(null),
        imperialshippingDimensionsRef: useRef<HTMLDivElement>(null),
        leadTimeRef: useRef<HTMLDivElement>(null),
    };

    const validateValues = () => {
        // const priceRegex = /^\d{1,3}(?:[.]\d{3})*(?:[,]\d{2})$/;
        const dimensionsRegex = /^\d+([.,]\d{1,2})?$/;
        const priceRegex = /^\d+([.,]\d{2})?$/;
        const isStartDateBeforeEndDate = dayjs(
            values.publishedStartDate
        ).isBefore(dayjs(values.publishedEndDate));
        const isEndDateBeforeDiscontinuedDate = dayjs(
            values.publishedEndDate
        ).isBefore(dayjs(values.discontinuedStartDate));

        const newErrors: Errors = {
            name: [],
            shortDescription: [],
            longDescription: [],
            startDate: {
                error: false,
                errorMessage: "",
            },
            endDate: {
                error: false,
                errorMessage: "",
            },
            discontinuedDate: {
                error: false,
                errorMessage: "",
            },
            SKU: {
                error: false,
                errorMessage: "",
            },
            image: {
                error: false,
                errorMessage: "",
            },
            attributes: {
                error: false,
                errorMessage: "",
            },
            prices: {
                error: false,
                errorMessage: "",
            },
            productWidthCm: {
                error: false,
                errorMessage: "",
            },
            productHeightCm: {
                error: false,
                errorMessage: "",
            },
            productLengthCm: {
                error: false,
                errorMessage: "",
            },
            productWidthIn: {
                error: false,
                errorMessage: "",
            },
            productHeightIn: {
                error: false,
                errorMessage: "",
            },
            productLengthIn: {
                error: false,
                errorMessage: "",
            },
            shippingWidthCm: {
                error: false,
                errorMessage: "",
            },
            shippingHeightCm: {
                error: false,
                errorMessage: "",
            },
            shippingLengthCm: {
                error: false,
                errorMessage: "",
            },
            shippingWidthIn: {
                error: false,
                errorMessage: "",
            },
            shippingHeightIn: {
                error: false,
                errorMessage: "",
            },
            shippingLengthIn: {
                error: false,
                errorMessage: "",
            },
            shippingNetWeightKg: {
                error: false,
                errorMessage: "",
            },
            shippingNetWeightLb: {
                error: false,
                errorMessage: "",
            },
            shippingTaraWeightKg: {
                error: false,
                errorMessage: "",
            },
            shippingTaraWeightLb: {
                error: false,
                errorMessage: "",
            },
            leadTimeBegin: {
                error: false,
                errorMessage: "",
            },
            leadTimeEnd: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;
        let firstErrorField: React.RefObject<HTMLElement> | null = null;

        if (files[0] === null || files[0] === undefined) {
            newErrors.image.error = true;
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.imageRef;
        }
        if (values.status === "PUBLISHED" && !isStartDateBeforeEndDate) {
            newErrors.endDate.error = true;
            newErrors.endDate.errorMessage =
                "Published end date must be greater than published start date";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.datesRef;
        }
        if (
            values.status === "PUBLISHED" &&
            !isEndDateBeforeDiscontinuedDate &&
            values.discontinuedStartDate
        ) {
            newErrors.discontinuedDate.error = true;
            newErrors.discontinuedDate.errorMessage =
                "Discontinued start date must be greater than published end date";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.datesRef;
        }
        values.name.forEach((name, index) => {
            const nameError = {
                val: {
                    error: false,
                    errorMessage: "",
                },
                lang: {
                    error: false,
                    errorMessage: "",
                },
            };

            if (name.val === "") {
                nameError.val.error = true;
                nameError.val.errorMessage =
                    "Name field is required. Maximum name length 40 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.nameRef[index];
            }

            if (name.lang === "UX") {
                nameError.lang.error = true;
                nameError.lang.errorMessage = "Language field is required";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.nameRef[index];
            }

            newErrors.name.push(nameError);
            // });
            // values.shortDescription.forEach((pair) => {
            const shortDescError = {
                val: {
                    error: false,
                    errorMessage: "",
                },
                lang: {
                    error: false,
                    errorMessage: "",
                },
            };

            if (values.shortDescription[index].val === "") {
                shortDescError.val.error = true;
                shortDescError.val.errorMessage =
                    "Short description field is required. Maximum text length 2000 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.shortDescRef[index];
            }
            if (values.shortDescription[index].val.length > 2000) {
                shortDescError.val.error = true;
                shortDescError.val.errorMessage =
                    "Maximum text length 2000 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.shortDescRef[index];
            }

            newErrors.shortDescription.push(shortDescError);
            // })
            // values.longDescription.forEach((pair) => {
            const longDescError = {
                val: {
                    error: false,
                    errorMessage: "",
                },
                lang: {
                    error: false,
                    errorMessage: "",
                },
            };

            if (values.longDescription[index].val === "") {
                longDescError.val.error = true;
                longDescError.val.errorMessage =
                    "Long description field is required. Maximum text length 5000 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.longDescRef[index];
            }
            if (values.longDescription[index].val.length > 5000) {
                longDescError.val.error = true;
                longDescError.val.errorMessage =
                    "Maximum text length 5000 characters";
                isValid = false;
                if (!firstErrorField)
                    firstErrorField = elementReferences.longDescRef[index];
            }

            newErrors.longDescription.push(longDescError);
        });

        if (values.productAttrs.some((attr) => attr.value === "")) {
            newErrors.attributes.error = true;
            newErrors.attributes.errorMessage =
                "Please fill in all attribute value fields";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.attributesRef;
        }
        if (!values.SKU) {
            newErrors.SKU.error = true;
            newErrors.SKU.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.skuRef;
        }

        if (
            values.itemDimensions.heightCm &&
            !dimensionsRegex.test(values.itemDimensions.heightCm.toString())
        ) {
            newErrors.productHeightCm.error = true;
            newErrors.productHeightCm.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricDimensionsRef;
        }
        if (
            values.itemDimensions.lengthCm &&
            !dimensionsRegex.test(values.itemDimensions.lengthCm.toString())
        ) {
            newErrors.productLengthCm.error = true;
            newErrors.productLengthCm.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricDimensionsRef;
        }
        if (
            values.itemDimensions.widthCm &&
            !dimensionsRegex.test(values.itemDimensions.widthCm.toString())
        ) {
            newErrors.productWidthCm.error = true;
            newErrors.productWidthCm.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricDimensionsRef;
        }
        if (
            values.itemDimensions.lengthIn &&
            !dimensionsRegex.test(values.itemDimensions.lengthIn.toString())
        ) {
            newErrors.productLengthIn.error = true;
            newErrors.productLengthIn.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.imperialDimensionsRef;
        }
        if (
            values.itemDimensions.heightIn &&
            !dimensionsRegex.test(values.itemDimensions.heightIn.toString())
        ) {
            newErrors.productHeightIn.error = true;
            newErrors.productHeightIn.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.imperialDimensionsRef;
        }
        if (
            values.itemDimensions.widthIn &&
            !dimensionsRegex.test(values.itemDimensions.widthIn.toString())
        ) {
            newErrors.productWidthIn.error = true;
            newErrors.productWidthIn.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.imperialDimensionsRef;
        }
        if (
            values.shippingDimensions.lengthCm &&
            !dimensionsRegex.test(values.shippingDimensions.lengthCm.toString())
        ) {
            newErrors.shippingLengthCm.error = true;
            newErrors.shippingLengthCm.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.heightCm &&
            !dimensionsRegex.test(values.shippingDimensions.heightCm.toString())
        ) {
            newErrors.shippingHeightCm.error = true;
            newErrors.shippingHeightCm.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.widthCm &&
            !dimensionsRegex.test(values.shippingDimensions.widthCm.toString())
        ) {
            newErrors.shippingWidthCm.error = true;
            newErrors.shippingWidthCm.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.netWeightKg &&
            !dimensionsRegex.test(
                values.shippingDimensions.netWeightKg.toString()
            )
        ) {
            newErrors.shippingNetWeightKg.error = true;
            newErrors.shippingNetWeightKg.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.taraWeightKg &&
            !dimensionsRegex.test(
                values.shippingDimensions.taraWeightKg.toString()
            )
        ) {
            newErrors.shippingTaraWeightKg.error = true;
            newErrors.shippingTaraWeightKg.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.metricshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.lengthIn &&
            !dimensionsRegex.test(values.shippingDimensions.lengthIn.toString())
        ) {
            newErrors.shippingLengthIn.error = true;
            newErrors.shippingLengthIn.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField =
                    elementReferences.imperialshippingDimensionsRef;
        }

        if (
            values.shippingDimensions.heightIn &&
            !dimensionsRegex.test(values.shippingDimensions.heightIn.toString())
        ) {
            newErrors.shippingHeightIn.error = true;
            newErrors.shippingHeightIn.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField =
                    elementReferences.imperialshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.widthIn &&
            !dimensionsRegex.test(values.shippingDimensions.widthIn.toString())
        ) {
            newErrors.shippingWidthIn.error = true;
            newErrors.shippingWidthIn.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField =
                    elementReferences.imperialshippingDimensionsRef;
        }
        if (
            values.shippingDimensions.netWeightLb &&
            !dimensionsRegex.test(
                values.shippingDimensions.netWeightLb.toString()
            )
        ) {
            newErrors.shippingNetWeightLb.error = true;
            newErrors.shippingNetWeightLb.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField =
                    elementReferences.imperialshippingDimensionsRef;
        }

        if (
            values.shippingDimensions.taraWeightLb &&
            !dimensionsRegex.test(
                values.shippingDimensions.taraWeightLb.toString()
            )
        ) {
            newErrors.shippingTaraWeightLb.error = true;
            newErrors.shippingTaraWeightLb.errorMessage =
                "Please enter a valid dimension format. Only numbers with max 2 decimal places";
            isValid = false;
            if (!firstErrorField)
                firstErrorField =
                    elementReferences.imperialshippingDimensionsRef;
        }
        if (!Number.isInteger(Number(values.leadTimeBegin))) {
            newErrors.leadTimeBegin.error = true;
            newErrors.leadTimeBegin.errorMessage =
                "Please enter a valid number of days";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.leadTimeRef;
        }
        if (!Number.isInteger(Number(values.leadTimeEnd))) {
            newErrors.leadTimeEnd.error = true;
            newErrors.leadTimeEnd.errorMessage =
                "Please enter a valid number of days";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.leadTimeRef;
        }
        if (
            Number.isInteger(Number(values.leadTimeBegin)) &&
            Number.isInteger(Number(values.leadTimeEnd)) &&
            Number(values.leadTimeBegin) !== 0 &&
            Number(values.leadTimeBegin) >= Number(values.leadTimeEnd)
        ) {
            newErrors.leadTimeBegin.error = true;
            newErrors.leadTimeBegin.errorMessage =
                '"To" must be greater than "From"';
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.leadTimeRef;
        }
        if (
            values.prices.some(
                (price) => !priceRegex.test(price.price.toString())
            )
        ) {
            newErrors.prices.error = true;
            newErrors.prices.errorMessage =
                "Please enter a valid price format. Only numbers with 2 decimal places";
            isValid = false;
        }
        setFormErrors(newErrors);
        if (firstErrorField && firstErrorField.current) {
            // firstErrorField?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
            const elementRect = firstErrorField.current.getBoundingClientRect();
            const absoluteElementTop = window.scrollY + elementRect.top;
            const middlePosition = absoluteElementTop - window.innerHeight / 2;
            window.scrollTo({ top: middlePosition + 64, behavior: "smooth" });
        }
        return isValid;
    };

    const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (!validateValues()) {
            return;
        }

        onSubmitHandler(values);
    };

    return {
        values,
        onSubmit,
        onChangeHandler,
        onCountryChangeHandler,
        onDimensionsChangeHandler,
        onlanguageSelectHandler,
        formErrors,
        handleAttributesChange,
        handlePriceTypesChange,
        elementReferences,
        handleAddFieldPair,
        handleRemoveFieldPair,
        handleAddAttributePair,
        handleRemoveAttributePair,
        onStatusChangeHandler,
        onStartDateChangeHandler,
        onEndDateChangeHandler,
        onDiscontinuedDateChangeHandler,
    };
};
