import { createTheme } from "@mui/material";



export let theme = createTheme({
    palette: {
        // primary: {
        // //   main: '#FF5733',
       
        //   // light: will be calculated from palette.primary.main,
        //   // dark: will be calculated from palette.primary.main,
        //   // contrastText: will be calculated to contrast with palette.primary.main
        // },
        // secondary: {
        // //   main: '#E0C2FF',
        // //   light: '#F5EBFF',
        //   // dark: will be calculated from palette.secondary.main,
        // //   contrastText: '#47008F',
        // },
           // background: {
        // //   main: '#E0C2FF',
        // //   light: '#F5EBFF',
        //   // dark: will be calculated from palette.secondary.main,
        // //   contrastText: '#47008F',
        // },
        error: {
          main: "#D81A48",
        },  
      },
});

theme = createTheme(theme, {
    components: {
        MuiFormLabel: {
          styleOverrides: {
            asterisk: {
              color: "#D81A48"
            },
            
          },
        },
        MuiPaper: {
            styleOverrides: {
              rounded: { borderRadius: "12px" },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: '#fff'
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                backgroundColor: '#fff'
              },
            },
          }
      },
});