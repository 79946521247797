/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryHierarchy } from '../models/CategoryHierarchy';
import type { CreateCategoryHierarchyDto } from '../models/CreateCategoryHierarchyDto';
import type { UpdateCategoryHierarchyDto } from '../models/UpdateCategoryHierarchyDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoryHierarchyService {

    /**
     * @returns CategoryHierarchy 
     * @throws ApiError
     */
    public static categoryHierarchyControllerFindall(): CancelablePromise<Array<CategoryHierarchy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/category-hierarchy',
        });
    }

    /**
     * @param requestBody 
     * @returns CategoryHierarchy 
     * @throws ApiError
     */
    public static categoryHierarchyControllerCreate(
requestBody: CreateCategoryHierarchyDto,
): CancelablePromise<CategoryHierarchy> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/category-hierarchy',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns CategoryHierarchy 
     * @throws ApiError
     */
    public static categoryHierarchyControllerUpdate(
id: string,
requestBody: UpdateCategoryHierarchyDto,
): CancelablePromise<CategoryHierarchy> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/category-hierarchy/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CategoryHierarchy 
     * @throws ApiError
     */
    public static categoryHierarchyControllerDelete(
id: string,
): CancelablePromise<CategoryHierarchy> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/category-hierarchy/{id}',
            path: {
                'id': id,
            },
        });
    }

}
