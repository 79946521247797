import {
    useState,
    useEffect,
} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
} from "@mui/material";
import {
    ParamTypes,
} from "../../utilities/types";
import { AgreementsListHead } from "./AgreementsListHead";
import { useParams } from "react-router-dom";
import {
    DataAgreement,
    DataAgreementService,
} from "../../services/openapi";
import Spinner from "../common/spinner";
import { InfoIcon } from "../../utilities/InfoIcon/InfoIcon";

const currentDate = new Date();
currentDate.setUTCHours(0, 0, 0, 0);
export const ProviderAgreements = () => {
    // const [order, setOrder] = useState<Order>("asc");
    // const [orderBy, setOrderBy] = useState<ValueOf<IHeadCell>>("companyName");
    // const [selected, setSelected] = useState("");
    const [agreements, setAgreements] = useState<DataAgreement[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [isExporting, setIsExporting] = useState(false);
    // const [statusMessage, setStatusMessage] = useState<string>("");
    // const [page, setPage] = useState(0);
    // const [skipped, setSkipped] = useState(0);
    // const [searchQuery, setSearchQuery] = useState("");
    const [error, setError] = useState<string>("");
    const { providerId } = useParams<keyof ParamTypes>() as ParamTypes;

    useEffect(() => {
        DataAgreementService.dataAgreementControllerGetDataAgreementForProvider(
            providerId
        )
            .then((result) => {
                setAgreements(result);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [providerId]);

    // const onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    //     // setSearchQuery(e.target.value);
    //     // setSkipped(0);
    //     // setPage(0);
    // };

    // const handleRequestSort = useCallback(
    //     (event: MouseEvent<unknown>, property: ValueOf<IHeadCell>) => {
    //         const isAsc = orderBy === property && order === "asc";
    //         setOrder(isAsc ? "desc" : "asc");
    //         setOrderBy(property);
    //     },
    //     [order, orderBy]
    // );
    // const handleCloseClick = () => {
    //     setSelected("");
    // };

    // const clearSelected = () => {
    //     setSelected("");
    // };

    // const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    //     if (selected === id) {
    //         setSelected("");
    //     } else {
    //         setSelected(id);
    //     }
    // };

    // const isSelected = (id: string) => selected === id;
    return (
        <>
         {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mx={2}
                        my={2}
                    >
                        Data Access Agreements
                    </Typography>
                    {agreements.length === 0 && (
                        <Box
                            sx={{
                                mx: 2,
                                mt: 8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <InfoIcon />
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "#99a1b7",
                                    fontSize: 24,
                                }}
                            >
                                Empty List
                            </Typography>
                        </Box>
                    )}
                    {agreements.length !== 0 && (
                        <>
                            {/* <SearchAgreementsToolbar
                                onSearchChangeHandler={onSearchChangeHandler}
                                numSelected={selected.length}
                                selected={selected}
                                clearSelected={clearSelected}
                                handleCloseClick={handleCloseClick}
                                exportProducts={exportProducts}
                                exportDeleteProducts={exportDeleteProducts}
                                consumerId={consumerId}
                            /> */}
                            <Table
                                aria-label="simple table"
                                // sx={{ tableLayout: "fixed", bgcolor: "#fff" }}
                            >
                                <AgreementsListHead
                                    // numSelected={selected.length}
                                    // rowCount={agreements.length}
                                    // // onSelectAllClick={handleSelectAllClick}
                                    // order={order}
                                    // orderBy={orderBy}
                                    // onRequestSort={handleRequestSort}
                                />

                                <TableBody>
                                    {agreements.map((row, index) => {
                                        const rowId = row?._id.toString();
                                        // const isItemSelected =
                                        //     isSelected(rowId);
                                        // const labelId = `enhanced-table-checkbox-${index}`;
                                        const agreementStartDateObj = new Date(
                                            row.agreementStartDate
                                        );
                                        const agreementEndDateObj = new Date(
                                            row.agreementEndDate
                                        );
                                        agreementStartDateObj.setUTCHours(
                                            0,
                                            0,
                                            0,
                                            0
                                        );
                                        agreementEndDateObj.setUTCHours(
                                            0,
                                            0,
                                            0,
                                            0
                                        );
                                        const isValid =
                                            agreementStartDateObj <=
                                                currentDate &&
                                            currentDate <=
                                                agreementEndDateObj &&
                                            row?.dataProvider !== null;
                                        return (
                                            <TableRow
                                                key={rowId}
                                                hover={isValid}

                                                // role="checkbox"
                                                // aria-checked={isItemSelected}
                                                // tabIndex={-1}
                                                // selected={isItemSelected}
                                            >
                                                {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        key={rowId}
                                                        id={labelId}
                                                        color="primary"
                                                        size="small"
                                                        disabled={!isValid}
                                                        checked={
                                                            isItemSelected &&
                                                            isValid
                                                        }
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                rowId
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell> */}
                                                <TableCell
                                                    key={rowId}
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                    sx={{
                                                        pl: "16px",
                                                        height: 60,
                                                        position: "relative",
                                                        width: "30%",
                                                    }}
                                                >
                                                    {/* <Box
                > */}
                                                    {/* <Link
                        to={`/providers/${rowId}/products`}
                        style={{
                            textDecoration: "none",
                        }}
                    > */}
                                                    <Typography
                                                        textAlign={"left"}
                                                        color={
                                                            isValid
                                                                ? "#4b5679"
                                                                : "#99a1b7"
                                                        }
                                                        sx={{
                                                            fontSize: 14,
                                                            // cursor: "pointer",
                                                            // ":hover": {
                                                            //     color: "#3e97ff",
                                                            // },
                                                            fontWeight: "bold",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            transform:
                                                                "translatey(-50%)",
                                                        }}
                                                    >
                                                        {
                                                            row?.dataConsumer
                                                                ?.companyName
                                                        }
                                                    </Typography>
                                                    {/* </Link> */}
                                                    {/* </Box> */}
                                                </TableCell>

                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        position: "relative",
                                                        width: "30%",
                                                    }}
                                                >
                                                    {/* <Box sx={{ position: "relative" }}>  */}
                                                    <Typography
                                                        textAlign={"left"}
                                                        color={
                                                            isValid
                                                                ? "#4b5679"
                                                                : "#99a1b7"
                                                        }
                                                        sx={{
                                                            fontSize: 14,
                                                            pl: "16px",
                                                            color: "#78829d",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            transform:
                                                                "translatey(-50%)",
                                                        }}
                                                    >
                                                        {row?.webshopSolution}
                                                    </Typography>
                                                    {/* </Box> */}
                                                </TableCell>
                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        position: "relative",
                                                        width: "20%",
                                                        pl: "16px",
                                                    }}
                                                >
                                                    <Box display={"flex"}>
                                                        {isValid ? (
                                                            <>
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        height: "10px",
                                                                        width: "10px",
                                                                        bgcolor:
                                                                            "#bbb",
                                                                        borderRadius:
                                                                            "50%",
                                                                        display:
                                                                            "inline-block",
                                                                        mr: 0.5,
                                                                    }}
                                                                ></Box>
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        height: "10px",
                                                                        width: "10px",
                                                                        bgcolor:
                                                                            "#529873",
                                                                        borderRadius:
                                                                            "50%",
                                                                        display:
                                                                            "inline-block",
                                                                        mr: 0.5,
                                                                    }}
                                                                ></Box>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        height: "10px",
                                                                        width: "10px",
                                                                        bgcolor:
                                                                            "#D10000",
                                                                        borderRadius:
                                                                            "50%",
                                                                        display:
                                                                            "inline-block",
                                                                        mr: 0.5,
                                                                    }}
                                                                ></Box>
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        height: "10px",
                                                                        width: "10px",
                                                                        bgcolor:
                                                                            "#bbb",
                                                                        borderRadius:
                                                                            "50%",
                                                                        display:
                                                                            "inline-block",
                                                                        mr: 0.5,
                                                                    }}
                                                                ></Box>
                                                            </>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                                {/* <TableCell padding="none">
                                                    <ActionButtonExports
                                                        // exportId={rowId}
                                                        id={rowId}
                                                        handleCheckClick={
                                                            handleClick
                                                        }
                                                        isValid={isValid}
                                                    />
                                                </TableCell> */}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </>
            )}
        </>
    );
};
