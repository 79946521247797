import { ChangeEvent, useState, FormEvent, useRef } from "react";
import { UpdateShopSettingsDto } from "../services/openapi";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
    url1: ErrorFields;
    key1: ErrorFields;
    secret1: ErrorFields;
    url2: ErrorFields;
    key2: ErrorFields;
    secret2: ErrorFields;
}

export const useFormUpdateCredentials = (
    initialValues: UpdateShopSettingsDto,
    onSubmitHandler: (data: UpdateShopSettingsDto) => void
) => {
    const [values, setValues] = useState<UpdateShopSettingsDto>(initialValues);
    const [formErrors, setFormErrors] = useState<Errors>({
        url1: {
            error: false,
            errorMessage: "",
        },
        key1: {
            error: false,
            errorMessage: "",
        },
        secret1: {
            error: false,
            errorMessage: "",
        },
        url2: {
            error: false,
            errorMessage: "",
        },
        key2: {
            error: false,
            errorMessage: "",
        },
        secret2: {
            error: false,
            errorMessage: "",
        },
    });
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    };

    const wooCommerceRef = useRef<HTMLInputElement>(null);

    const validateValues = () => {
        const newErrors: Errors = {
            url1: {
                error: false,
                errorMessage: "",
            },
            key1: {
                error: false,
                errorMessage: "",
            },
            secret1: {
                error: false,
                errorMessage: "",
            },
            url2: {
                error: false,
                errorMessage: "",
            },
            key2: {
                error: false,
                errorMessage: "",
            },
            secret2: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;
        let firstErrorField = null;

        if (!values.key1.startsWith("ck_")) {
            newErrors.key1.error = true;
            newErrors.key1.errorMessage =
                'Consumer Key should start with "ck_"';
            isValid = false;
            if (!firstErrorField) firstErrorField = wooCommerceRef;
        }
        if (!values.secret1.startsWith("cs_")) {
            newErrors.secret1.error = true;
            newErrors.secret1.errorMessage =
                'Consumer Secret should start with "cs_"';
            isValid = false;
            if (!firstErrorField) firstErrorField = wooCommerceRef;
        }

        if (!values.key2) {
            newErrors.key2.error = true;
            newErrors.key2.errorMessage = "This field is required";
            isValid = false;
        }
        if (!values.secret2) {
            newErrors.secret2.error = true;
            newErrors.secret2.errorMessage = "This field is required";
            isValid = false;
        }

        setFormErrors(newErrors);
        if (firstErrorField) {
            firstErrorField.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
        return isValid;
    };
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateValues()) {
            return;
        }
        onSubmitHandler(values);
    };

    return {
        values,
        onSubmit,
        onChangeHandler,
        formErrors,
        wooCommerceRef,
    };
};
