import { Box, Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import { CancelButton } from "../common/CancelButton";
import { useNavigate, useParams } from "react-router-dom";
import { ParamTypes } from "../../utilities/types";
import { MuiFileInput } from "mui-file-input";
import { useContext, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
    CreateImageMetadataDto,
    Import,
    ImportService,
    UploadFileInfo,
    UploadImageInfo,
    UploadService,
} from "../../services/openapi";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Swal from "sweetalert2";
import "../../utilities/import-sweet-alert.css";
import { AuthContext } from "../../contexts/AuthContext";

const imageRegex = /^[a-zA-Z0-9_#-]+$/;

export default function ProductsImport() {
    const navigate = useNavigate();
    const { providerId } = useParams<keyof ParamTypes>() as ParamTypes;

    const [file, setFile] = useState<File | null>(null);
    const [images, setImages] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [createdImport, setCreatedImport] = useState<Import | null>(null);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [deleteImport, setDeleteImport] = useState<boolean>(false);

    const currentUser = useContext(AuthContext);

    const handleFileChange = async (file: File | null): Promise<void> => {
        if (file) {
            setFile(file);
            try {
                setIsFileLoading(true);
                setImages([]);
                const uploadFileInfo: UploadFileInfo =
                    await UploadService.uploadControllerUploadFile(file.size);
                await fetch(uploadFileInfo.uploadUrl, {
                    method: "PUT",
                    body: file,
                    headers: { "Content-Type": file.type },
                });
                const createdImport: Import =
                    await ImportService.importProductsControllerCreate({
                        dataProviderId: providerId,
                        fileId: uploadFileInfo.fileId,
                    });
                setCreatedImport(createdImport);
                setIsFileLoading(false);
            } catch (error) {
                console.error(error);
                setFile(null);
                setIsFileLoading(false);
            }
        }
    };
    const handleImageChange = (value: File[]): void => {
        if (!createdImport) {
            Swal.fire({
                icon: "error",
                text: "Please upload an excel file first",
            });
            return;
        }
        setImages((state) => [
            ...state,
            ...value.filter((file) =>
                createdImport.docImageNames.includes(
                    file.name.slice(0, file.name.lastIndexOf(".") + 1) +
                        file.name
                            .slice(file.name.lastIndexOf(".") + 1)
                            .toLowerCase()
                )
            ),
        ]);
    };

    const onCancelClickHandler = () => {
        navigate(`/providers`);
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!file) {
            Swal.fire({
                icon: "error",
                text: "Please upload an excel file",
            });
            return;
        }

        if (createdImport) {
            try {
                setIsLoading(true);
                setStatusMessage("Validating images...");
                const imageNames: string[] = images.map((image) => image.name);
                const inavlidNameImages = imageNames.filter(
                    (imageName) =>
                        !imageRegex.test(
                            imageName.slice(0, imageName.lastIndexOf("."))
                        )
                );
                const invalidSizeImages = images
                    .filter((image) => image.size > 3500000)
                    .map((image) => image.name);
                const missingImages: string[] = [];
                createdImport.docImageNames.forEach((imageName) => {
                    if (
                        !imageNames
                            .map(
                                (imgName) =>
                                    imgName.slice(
                                        0,
                                        imgName.lastIndexOf(".") + 1
                                    ) +
                                    imgName
                                        .slice(imgName.lastIndexOf(".") + 1)
                                        .toLowerCase()
                            )
                            .includes(imageName)
                    ) {
                        missingImages.push(imageName);
                    }
                });

                if (
                    missingImages.length > 0 ||
                    inavlidNameImages.length > 0 ||
                    invalidSizeImages.length > 0
                ) {
                    const fileText = `Number of images defined in the selected Excel data file: ${
                        createdImport.docImageNames.length
                    }\n\nNumber of images not found in the selected directory/directories: ${
                        missingImages.length
                    }${
                        missingImages.length > 0
                            ? ", with the following file names:\n" +
                              missingImages.join("\n")
                            : ""
                    }
                    \n\nNumber of found images with invalid name (only latin letters, numbers or the symbols "_", "#" and "-" are allowed): ${
                        inavlidNameImages.length
                    }${
                        inavlidNameImages.length > 0
                            ? ", with the following file names:\n" +
                              inavlidNameImages.join("\n")
                            : ""
                    }
                    \n\nNumber of found images with invalid size (the image size cannot exceed 3500 KB): ${
                        invalidSizeImages.length
                    }${
                        invalidSizeImages.length > 0
                            ? ", with the following file names:\n" +
                              invalidSizeImages.join("\n")
                            : ""
                    }`;
                    const blob = new Blob([fileText], { type: "text/plain" });
                    const url = URL.createObjectURL(blob);
                    Swal.fire({
                        icon: "error",
                        html: `<p>Missing or invalid images. Check   
                <a href=${url} download="ProductImport.txt">logfile</a> for more details
            </p>`,
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            // Change the state here
                            setFile(null);
                            setImages([]);
                            setCreatedImport(null);
                            setDeleteImport(false);
                        }
                    });
                    setIsLoading(false);
                    // setFile(null);
                    // setImages([]);
                    // setCreatedImport(null);
                    // setIsLoading(false);
                    return;
                }
                let counter = 0;
                const processImage = async (image: File, index: number) => {
                    if (createdImport.docImageNames.includes(image.name)) {
                        const imageExtension = image.name.substring(image.name.lastIndexOf(".") + 1);
                        const { uploadUrl, imageId }: UploadImageInfo =
                            await UploadService.uploadControllerUploadBundleImageUpload(
                                image.size,
                                createdImport._id.toString(),
                                providerId,
                                imageExtension
                            );
                        setStatusMessage(
                            `Uploading image ${counter++} of ${images.length}`
                        );
                        await fetch(uploadUrl, {
                            method: "PUT",
                            body: image,
                            headers: { "Content-Type": image.type },
                        });
                        return {
                            imageId,
                            name: image.name,
                            fileName: image.name,
                            order: 0,
                            width: 0,
                            height: 0,
                            extension: "",
                        };
                    }
                    return null;
                };

                const imagesMetaDataPromises: Promise<CreateImageMetadataDto | null>[] =
                    images.map(processImage);
                const imagesMetaData: (CreateImageMetadataDto | null)[] =
                    await Promise.all(imagesMetaDataPromises);
                // Filter out any null values from the array
                const filteredImagesMetaData: CreateImageMetadataDto[] =
                    imagesMetaData.filter(
                        (
                            imageMetaData
                        ): imageMetaData is CreateImageMetadataDto =>
                            imageMetaData !== null
                    );
                const imagesSizeKiloBytes =
                    images.reduce((acc, image) => acc + image.size, 0) / 1000;
                const imagesUploadTime = Math.ceil(
                    (imagesSizeKiloBytes / 13200) * 2
                );

                setStatusMessage(
                    `Importing products\nEstimated runtime: ${imagesUploadTime} minutes\nPlease don't navigate away from this screen`
                );
                try {
                    await ImportService.importProductsControllerFinishImport(deleteImport, {
                        importId: createdImport._id,
                        createImageList: filteredImagesMetaData,
                    });
                // setStatusMessage("Finishing import...");
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: "Import failed!",
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            // Change the state here
                            setFile(null);
                            setImages([]);
                            setCreatedImport(null);
                            setDeleteImport(false);
                        }
                    });
                   
                    return;
                }

                try {
                    await ImportService.importProductsControllerStartImport(
                        createdImport._id.toString()
                    );
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: "Import failed!",
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            // Change the state here
                            setFile(null);
                            setImages([]);
                            setCreatedImport(null);
                            setDeleteImport(false);
                        }
                    });
                   
                    return;
                }
               
                // }
                Swal.fire({
                    position: "center",
                    icon: "success",
                    text: "Import finished successfully!",
                    allowOutsideClick: false,
                });
                setFile(null);
                setImages([]);
                setIsLoading(false);
                setDeleteImport(false);
            } catch (error) {
                console.error(error);
                setFile(null);
                setImages([]);
                setDeleteImport(false);
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        pt: 20,
                    }}
                >
                    <CircularProgress size={100} />
                    {statusMessage.split("\n").map((line, index) => (
                        <Typography
                            key={index}
                            component="h1"
                            variant="h6"
                            fontWeight={"bold"}
                            mt={2}
                        >
                            {line}
                        </Typography>
                    ))}
                    {/* <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mt={2}
                    >
                        {statusMessage}
                    </Typography> */}
                </Box>
            )}

            {isFileLoading && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        pt: 20,
                    }}
                >
                    <CircularProgress size={100} />
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={"bold"}
                        mt={2}
                    >
                        Uploading excel file...
                    </Typography>
                </Box>
            )}
            {!isLoading && !isFileLoading && (
                <>
                    <Box
                        component="form"
                        sx={{
                            width: "100%",
                            height: "100%",
                        }}
                        action="POST"
                        onSubmit={onSubmit}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={"bold"}
                            // mx={2}
                            mb={4}
                        >
                            Import Products
                        </Typography>

                        <Box sx={{ mb: 3, maxWidth: "350px" }}>
                            <MuiFileInput
                                value={file}
                                onChange={handleFileChange}
                                placeholder="Select Excel data file"
                                InputProps={{
                                    inputProps: {
                                        accept: ".xls, .xlsx, .xlsm",
                                    },
                                    startAdornment: <AttachFileIcon />,
                                }}
                            />
                        </Box>
                        <Box sx={{ maxWidth: "350px" }}>
                            <MuiFileInput
                                getInputText={(value) =>
                                    value
                                        ? `${value.length} Product Image(s)`
                                        : ""
                                }
                                value={images}
                                multiple
                                onChange={handleImageChange}
                                placeholder="Select Product Image(s)"
                                InputProps={{
                                    inputProps: {
                                        accept: ".jpg, .jpeg, .webp",
                                    },
                                    startAdornment: <AddPhotoAlternateIcon />,
                                }}
                            />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"left"}>
                            <Checkbox
                                id="delete-import-checkbox"
                                color="primary"
                                size="small"
                                checked={deleteImport}
                                onClick={() => setDeleteImport(!deleteImport)}
                            />
                            <Typography
                            component="h1"
                            variant="h6"
                            fontSize={14}
                            // mx={2}
                        >
                            Delete all products before import
                        </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                gap: 2,
                                mt: 4,
                            }}
                        >
                            {currentUser?.userRole === "PlatformAdministrator" && <CancelButton
                                onCancelClickHandler={onCancelClickHandler}
                            />}
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    bgcolor: "#3e97ff",
                                    fontWeight: "bold",
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}
