import {
    TextField,
    Button,
    Box,
    Typography,
    Avatar,
    Checkbox,
    Grid,
    Paper,
    Autocomplete,
    createFilterOptions,
} from "@mui/material";
import { ParamTypes } from "../../utilities/types";
import { useNavigate, useParams } from "react-router-dom";
import {
    Category,
    CategoryHierarchyService,
    CategoryService,
    CreateInLanguageDto,
    CreateProductDto,
    DataProvider,
    DataProvidersService,
    Product,
    ProductsService,
    UploadImageInfo,
    UploadService,
} from "../../services/openapi";
import { CancelButton } from "../common/CancelButton";
import { useFormCreateProduct } from "../../hooks/useFormCreateProduct";
import { useState, ChangeEvent, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import photoPlaceholder from "../../assets/image_placeholder.6e3610d.jpg";
import Spinner from "../common/spinner";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { languages } from "../../utilities/utils";
import Swal from "sweetalert2";
import "../../utilities/import-sweet-alert.css";
import { sortCategories } from "../../utilities/sorting";

const bigImageButtonStyles = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "30px",
    height: "30px",
    minWidth: "0",
    borderRadius: "50%",
    border: "grey",
    bgcolor: "white",
    boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
    "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
};
const smallImageButtonStyles = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "20px",
    height: "20px",
    minWidth: "0",
    borderRadius: "50%",
    border: "grey",
    bgcolor: "white",
    boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.075);",
    "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
};

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
type CategoryWithChildren = Category & {
    subRows: CategoryWithChildren[]; // Recursive subarray of CategoryWithChildren
};
type MappedArray = { [key: string]: CategoryWithChildren };
const imageRegex = /^[a-zA-Z0-9_#-]+$/;

export default function CreateProduct() {
    const { providerId } = useParams<keyof ParamTypes>() as ParamTypes;
    const [provider, setProvider] = useState<DataProvider | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState<CategoryWithChildren[]>([]);
    const [files, setFiles] = useState<(File | null)[]>([]);
    const [previewImage, setPreviewImage] = useState<string[]>([]);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        Promise.all([
            DataProvidersService.dataProviderControllerFindOne(providerId),
            CategoryHierarchyService.categoryHierarchyControllerFindall(),
        ])
            .then(([provider, categoryHierarchies]) => {
                setProvider(provider);
                CategoryService.categoryControllerFindall(
                    categoryHierarchies[0]._id.toString()
                )
                    .then((categories) => {
                        const unflattenedCategories = unflatten(categories);
                        setCategories(unflattenedCategories);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setError(error.message);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [providerId]);

    function unflatten(items: Category[]) {
        const tree = [],
            mappedArr: MappedArray = {};

        // Build a hash table and map items to objects
        items.forEach((item) => {
            const id = item._id.toString();
            if (!mappedArr.hasOwnProperty(id)) {
                // in case of duplicates
                mappedArr[id] = item as CategoryWithChildren; // the extracted id as key, and the item as value
                mappedArr[id].subRows = []; // under each item, add a key "children" with an empty array as value
            }
        });

        // Loop over hash table
        for (let id in mappedArr) {
            if (mappedArr.hasOwnProperty(id)) {
                let mappedElem = mappedArr[id];

                // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
                if (mappedElem.parentId) {
                    const parentId = mappedElem.parentId.toString();
                    //   check if the parent exists until the backend issue with deleting parent is fixed
                    if (mappedArr[parentId]) {
                        mappedArr[parentId].subRows.push(mappedElem);
                    }
                }

                // If the element is at the root level, directly push to the tree
                else {
                    tree.push(mappedElem);
                }
            }
        }
        return sortCategories(tree);
    }

    const initialValues: CreateProductDto = {
        dataProviderId: providerId,
        name: [{ lang: CreateInLanguageDto.lang.EN, val: "" }],
        brand: "",
        shortDescription: [{ lang: CreateInLanguageDto.lang.EN, val: "" }],
        longDescription: [{ lang: CreateInLanguageDto.lang.EN, val: "" }],
        productAttrs: [],
        priceTypes: [],
        categories: [],
    };

    const areSomeOrAllChildrenSelected = (
        category: CategoryWithChildren
    ): "none" | "some" | "all" => {
        const childIds = category.subRows.map((child) => child._id.toString());
        const selectedChildIds = childIds.filter((id) =>
            selectedCategories.includes(id)
        );

        // Check nested children
        for (const child of category.subRows) {
            const childStatus = areSomeOrAllChildrenSelected(child);
            if (
                childStatus === "some" ||
                (childStatus === "all" &&
                    !selectedChildIds.includes(child._id.toString()))
            ) {
                return "some";
            }
        }

        if (selectedChildIds.length === 0) {
            return "none";
        } else if (selectedChildIds.length === childIds.length) {
            return "all";
        } else {
            return "some";
        }
    };

    const renderTree = (category: CategoryWithChildren) => {
        const categoryId = category._id.toString();
        const childSelectionStatus = areSomeOrAllChildrenSelected(category);

        return (
            <TreeItem
                key={categoryId}
                nodeId={categoryId}
                label={
                    <Box display={"flex"} alignItems={"center"}>
                        {/* {category.parentId */}
                        <Checkbox
                            sx={{ padding: 0.5 }}
                            disabled={
                                category.subRows.length === 0 ? false : true
                            }
                            //  indeterminate={selectedCategories.indexOf(categoryId) !== -1}
                            indeterminate={
                                childSelectionStatus === "some" &&
                                !selectedCategories.includes(categoryId)
                            }
                            checked={
                                selectedCategories.includes(categoryId) ||
                                childSelectionStatus === "all"
                            }
                            // tabIndex={-2}
                            disableRipple
                            onClick={(event) =>
                                handleNodeSelect(event, categoryId)
                            }
                        />
                        {/* //   : null */}
                        {/* //     } */}
                        {category.name}
                    </Box>
                }
            >
                {Array.isArray(category.subRows)
                    ? category.subRows.map((nestedCategory) =>
                          renderTree(nestedCategory)
                      )
                    : null}
            </TreeItem>
        );
    };

    const navigate = useNavigate();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        const imagePlaceNumber = Number(e.target.id);
        if (file) {
            if (
                !imageRegex.test(file.name.slice(0, file.name.lastIndexOf(".")))
            ) {
                Swal.fire({
                    icon: "error",
                    text: 'The image name should contain only latin letters, numbers or the symbols "_", "#" and "-". No other characters or empty spaces allowed',
                });
                e.target.value = "";
                return;
            }
            if (file.size > 3500000) {
                Swal.fire({
                    icon: "error",
                    text: "The image size cannot exceed 3500 KB",
                });
                e.target.value = "";
                return;
            }
            setPreviewImage((prevState) => {
                const newState = [...prevState];
                newState[imagePlaceNumber] = URL.createObjectURL(file);
                return newState;
            });
            setFiles((prevState) => {
                const newState = [...prevState];
                newState[imagePlaceNumber] = file;
                return newState;
            });
        }
    };
    const onDeleteImageClick = (e: React.MouseEvent<HTMLElement>) => {
        const deleteButtonIndex = Number(e.currentTarget.id);
        setPreviewImage((prevState) => {
            const newState = [...prevState];
            newState[deleteButtonIndex] = "";
            return newState;
        });
        setFiles((prevState) => {
            const newState = [...prevState];
            newState[deleteButtonIndex] = null;
            return newState;
        });
    };

    const onCreateSubmitHandler = async (data: CreateProductDto) => {
        const { brand, ...restOfData } = data;

        const modifiedObject = {
            ...restOfData,
            brand: provider!.companyName,
            categories: selectedCategories,
        };
        try {
            setIsLoading(true);
            const product: Product =
                await ProductsService.productControllerCreate(modifiedObject);
            const productId = product._id;
            const productIdAsString = productId.toString();
            const processImage = async (image: File, index: number) => {
                // if (image?.imageId)
                //  {
                const imageExtension = image.name.substring(image.name.lastIndexOf(".") + 1);
                const uploadImageInfo: UploadImageInfo =
                    await UploadService.uploadControllerUploadImage(image.size, imageExtension);
                await fetch(uploadImageInfo.uploadUrl, {
                    method: "PUT",
                    body: image,
                    headers: { "Content-Type": image.type },
                });
                const result =
                    await ProductsService.productControllerCreateProductImage(
                        productIdAsString,
                        product.dataProviderId,
                        {
                            imageId: uploadImageInfo.imageId,
                            name: image.name,
                            fileName: image.name,
                            order: index,
                            width: 0,
                            height: 0,
                            extension: "",
                        }
                    );
                return result;

                // } else {
                //     return null;
                // }
            };
            const filteredImages = files.filter((image): image is File =>
                Boolean(image)
            );
            const imagesPromises: Promise<Product>[] =
                filteredImages.map(processImage);
            await Promise.all(imagesPromises);

            navigate(`/providers/${providerId}/products`);
            setIsLoading(false);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const {
        values,
        handleNodeSelect,
        onChangeHandler,
        selectedCategories,
        formErrors,
        elementReferences,
        onSubmit,
        handleAddFieldPair,
        handleRemoveFieldPair,
        onlanguageSelectHandler,
    } = useFormCreateProduct(initialValues, onCreateSubmitHandler, files);

    const onCancelClickHandler = () => {
        navigate(`/providers/${providerId}/products`);
    };

    return (
        <>
        {error && (
                <div
                    id="error-page"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error}</i>
                    </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <>
                    <Box
                        component="form"
                        // sx={{
                        //     width: "60%",
                        // }}
                        noValidate
                        action="POST"
                        onSubmit={onSubmit}
                    >
                        <Box>
                            <Typography
                                component="h1"
                                variant="h6"
                                fontWeight={"bold"}
                                // mx={2}
                                mb={4}
                            >
                                {`${provider?.companyName} - Add Product`}
                            </Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                {/* <Paper elevation={2} > */}
                                <Box
                                    display={"flex"}
                                    sx={{ flexDirection: "column" }}
                                    ref={elementReferences.imageRef}
                                >
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: {
                                                xs: "115px",
                                                md: "230px",
                                                lg: "460px",
                                            },
                                            height: {
                                                xs: "115px",
                                                md: "230px",
                                                lg: "460px",
                                            },
                                            mb: 1,
                                            // mx: 2,
                                            outline: "1px solid lightgrey",
                                        }}
                                    >
                                        <Avatar
                                            variant="square"
                                            alt=""
                                            src={
                                                previewImage[0]
                                                    ? previewImage[0]
                                                    : photoPlaceholder
                                            }
                                            sx={{
                                                bgcolor: "#eeedf1",
                                                width: "100%",
                                                height: "100%",

                                                // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                            }}
                                        ></Avatar>
                                        {!previewImage[0] && (
                                            <Button
                                                component="label"
                                                aria-label="add-edit"
                                                sx={bigImageButtonStyles}
                                            >
                                                <AddIcon
                                                    fontSize="small"
                                                    sx={{ color: "grey" }}
                                                />
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    accept=".jpg, .jpeg, .webp"
                                                    id="0"
                                                    onChange={handleChange}
                                                />
                                            </Button>
                                        )}
                                        {previewImage[0] && (
                                            <Button
                                                // id="0"
                                                // onClick={onDeleteImageClick}
                                                component="label"
                                                aria-label="edit"
                                                sx={bigImageButtonStyles}
                                            >
                                                <EditIcon
                                                    fontSize="small"
                                                    sx={{ color: "grey" }}
                                                />
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    accept=".jpg, .jpeg, .webp"
                                                    id="0"
                                                    onChange={handleChange}
                                                />
                                            </Button>
                                        )}
                                    </Box>
                                    {formErrors.image?.error && (
                                        <Box
                                            sx={{
                                                color: "#D81A48",
                                                fontSize: "12px",
                                                mb: "10px",
                                            }}
                                        >
                                            Please upload a main image
                                        </Box>
                                    )}
                                    <Box
                                        display="flex"
                                        // gap={"5px"}
                                        justifyContent={"space-between"}
                                        // sx={{ mb: 2, mx: 2 }}
                                        sx={{
                                            width: {
                                                xs: "115px",
                                                md: "230px",
                                                lg: "460px",
                                            },
                                            height: {
                                                xs: "25px",
                                                md: "55px",
                                                lg: "110px",
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[1]
                                                        ? previewImage[1]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[1] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="1"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[1] && (
                                                <Button
                                                    id="1"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[2]
                                                        ? previewImage[2]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[2] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="2"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[2] && (
                                                <Button
                                                    id="2"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[3]
                                                        ? previewImage[3]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[3] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="3"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[3] && (
                                                <Button
                                                    id="3"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                height: {
                                                    xs: "25px",
                                                    md: "55px",
                                                    lg: "110px",
                                                },
                                                outline: "1px solid lightgrey",
                                            }}
                                        >
                                            <Avatar
                                                variant="square"
                                                alt=""
                                                src={
                                                    previewImage[4]
                                                        ? previewImage[4]
                                                        : photoPlaceholder
                                                }
                                                sx={{
                                                    bgcolor: "#eeedf1",
                                                    width: "100%",
                                                    height: "100%",
                                                    // boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.075);",
                                                }}
                                            ></Avatar>
                                            {!previewImage[4] && (
                                                <Button
                                                    component="label"
                                                    aria-label="add-edit"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <AddIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="4"
                                                        accept=".jpg, .jpeg, .webp"
                                                        onChange={handleChange}
                                                    />
                                                </Button>
                                            )}
                                            {previewImage[4] && (
                                                <Button
                                                    id="4"
                                                    onClick={onDeleteImageClick}
                                                    component="label"
                                                    aria-label="remove"
                                                    sx={smallImageButtonStyles}
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: "grey",
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* </Paper> */}
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={2}>
                                    <Box
                                        // display={"flex"}
                                        sx={{ p: 3 }}
                                    >
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={2}
                                        >
                                            Product Description
                                        </Typography>
                                        {values["name"].map((pair, index) => (
                                            <Box
                                                display={"flex"}
                                                gap={1}
                                                key={`container-${index}`}
                                                sx={{
                                                    // flexDirection: "column",
                                                    mb: 2,
                                                    mt: 1,
                                                }}
                                                // ref={elementReferences.nameRef}
                                            >
                                                <Box
                                                    display={"flex"}
                                                    key={`langContainer-${index}`}
                                                    gap={1}
                                                    sx={{ mb: 2 }}
                                                >
                                                    <Autocomplete
                                                        sx={{ minWidth: 200 }}
                                                        id={`nameLanguage-${index}`}
                                                        key={`nameLanguage-${index}`}
                                                        ref={
                                                            elementReferences
                                                                .nameRef[index]
                                                        }
                                                        options={languages.sort(
                                                            (a, b) =>
                                                                a.label.localeCompare(
                                                                    b.label
                                                                )
                                                        )}
                                                        disabled={index === 0}
                                                        getOptionDisabled={(
                                                            option
                                                        ) =>
                                                            values["name"]
                                                                .map(
                                                                    (pair) =>
                                                                        pair.lang
                                                                )
                                                                .includes(
                                                                    option.code
                                                                ) &&
                                                            option.code !==
                                                                pair.lang
                                                        }
                                                        forcePopupIcon={
                                                            index === 0
                                                                ? false
                                                                : true
                                                        }
                                                        autoHighlight
                                                        autoSelect
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.label}
                                                        value={
                                                            index === 0
                                                                ? languages.find(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.code ===
                                                                          "EN"
                                                                  )
                                                                : languages.find(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.code ===
                                                                          pair.lang
                                                                  )
                                                        }
                                                        disableClearable={true}
                                                        filterOptions={createFilterOptions(
                                                            {
                                                                matchFrom:
                                                                    "start",
                                                                stringify: (
                                                                    option
                                                                ) =>
                                                                    option.label,
                                                            }
                                                        )}
                                                        onChange={(
                                                            event,
                                                            value
                                                        ) =>
                                                            onlanguageSelectHandler(
                                                                event,
                                                                value.code,
                                                                "name",
                                                                index
                                                            )
                                                        }
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                            >
                                                                {option.label}
                                                            </Box>
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label={
                                                                    index === 0
                                                                        ? "Language"
                                                                        : "Choose language"
                                                                }
                                                                // sx={{width: "60%"}}
                                                                required={
                                                                    index === 0
                                                                        ? false
                                                                        : true
                                                                }
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                }}
                                                                error={
                                                                    formErrors
                                                                        .name[
                                                                        index
                                                                    ]?.lang
                                                                        .error
                                                                }
                                                                helperText={
                                                                    formErrors
                                                                        .name[
                                                                        index
                                                                    ]?.lang
                                                                        .error
                                                                        ? formErrors
                                                                              .name[
                                                                              index
                                                                          ]
                                                                              ?.lang
                                                                              .errorMessage
                                                                        : ""
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    {index !== 0 ? (
                                                        <Button
                                                            sx={{
                                                                height: "56px",
                                                            }}
                                                            onClick={() =>
                                                                handleRemoveFieldPair(
                                                                    "name",
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <ClearIcon fontSize="medium" />
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Box>
                                                {/* {values["shortDescription"].map(
                                             (pair, index) => ( */}
                                                <Box
                                                    display={"flex"}
                                                    gap={1}
                                                    key={`descriptionContainer-${index}`}
                                                    sx={{
                                                        flexDirection: "column",
                                                        mb: 2,
                                                        // mt: 1,
                                                    }}
                                                    // ref={elementReferences.nameRef}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        gap={1}
                                                        key={`nameDescContainer-${index}`}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                minWidth: 600,
                                                            }}
                                                            label="Product Name"
                                                            ref={
                                                                elementReferences
                                                                    .nameRef[
                                                                    index
                                                                ]
                                                            }
                                                            inputProps={{
                                                                maxLength: 40,
                                                            }}
                                                            name="val"
                                                            required
                                                            id={`name-input-${index}`}
                                                            key={`nameInput-${index}`}
                                                            value={pair.val}
                                                            onChange={(e) =>
                                                                onChangeHandler(
                                                                    e,
                                                                    "name",
                                                                    index
                                                                )
                                                            }
                                                            error={
                                                                formErrors.name[
                                                                    index
                                                                ]?.val.error
                                                            }
                                                            helperText={
                                                                formErrors.name[
                                                                    index
                                                                ]?.val.error
                                                                    ? formErrors
                                                                          .name[
                                                                          index
                                                                      ]?.val
                                                                          .errorMessage
                                                                    : "Maximum name length 40 characters"
                                                            }
                                                        />
                                                    </Box>
                                                    <Box
                                                        display={"flex"}
                                                        gap={1}
                                                        key={`shortDescContainer-${index}`}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                minWidth: 600,
                                                            }}
                                                            ref={
                                                                elementReferences
                                                                    .shortDescRef[
                                                                    index
                                                                ]
                                                            }
                                                            rows={6}
                                                            label="Short Description"
                                                            multiline
                                                            inputProps={{
                                                                maxLength: 2000,
                                                            }}
                                                            name="val"
                                                            value={
                                                                values[
                                                                    "shortDescription"
                                                                ][index].val
                                                            }
                                                            required
                                                            id={`shortDescInput-${index}`}
                                                            key={`shortDescInput-${index}`}
                                                            // value={pair.val}
                                                            onChange={(e) =>
                                                                onChangeHandler(
                                                                    e,
                                                                    "shortDescription",
                                                                    index
                                                                )
                                                            }
                                                            error={
                                                                formErrors
                                                                    .shortDescription[
                                                                    index
                                                                ]?.val.error
                                                            }
                                                            helperText={
                                                                formErrors
                                                                    .shortDescription[
                                                                    index
                                                                ]?.val.error
                                                                    ? formErrors
                                                                          .shortDescription[
                                                                          index
                                                                      ]?.val
                                                                          .errorMessage
                                                                    : "Maximum text length 2000 characters"
                                                            }
                                                        />
                                                    </Box>
                                                    <Box
                                                        display={"flex"}
                                                        gap={1}
                                                        key={`longDescContainer-${index}`}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                minWidth: 600,
                                                            }}
                                                            rows={10}
                                                            ref={
                                                                elementReferences
                                                                    .longDescRef[
                                                                    index
                                                                ]
                                                            }
                                                            label="Long Description"
                                                            inputProps={{
                                                                maxLength: 5000,
                                                            }}
                                                            multiline
                                                            name="val"
                                                            value={
                                                                values[
                                                                    "longDescription"
                                                                ][index].val
                                                            }
                                                            required
                                                            id={`longDescInput-${index}`}
                                                            key={`longDescInput-${index}`}
                                                            // value={pair.val}
                                                            onChange={(e) =>
                                                                onChangeHandler(
                                                                    e,
                                                                    "longDescription",
                                                                    index
                                                                )
                                                            }
                                                            error={
                                                                formErrors
                                                                    .longDescription[
                                                                    index
                                                                ]?.val.error
                                                            }
                                                            helperText={
                                                                formErrors
                                                                    .longDescription[
                                                                    index
                                                                ]?.val.error
                                                                    ? formErrors
                                                                          .longDescription[
                                                                          index
                                                                      ]?.val
                                                                          .errorMessage
                                                                    : "Maximum text length 5000 characters"
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                                {/* //     )
                                                      // )} */}
                                                {/* {values["name"].length > 0 && (
                                                    <Divider />
                                                )} */}
                                            </Box>
                                        ))}

                                        <Box>
                                            <Button
                                                onClick={(e) =>
                                                    handleAddFieldPair(
                                                        e,
                                                        "name"
                                                    )
                                                }
                                                // sx={{ mt: 1 }}
                                            >
                                                Add language
                                            </Button>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={2}>
                                    <Box sx={{ mb: 1, p: 3 }}>
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            fontSize={"16px"}
                                            fontWeight={"bold"}
                                            // mx={2}
                                            mb={2}
                                        >
                                            Product Categories
                                        </Typography>

                                        <SimpleTreeView>
                                            {categories.map((category) =>
                                                renderTree(category)
                                            )}
                                        </SimpleTreeView>
                                        {formErrors.categories.error && (
                                            <Box
                                                sx={{
                                                    color: "#D81A48",
                                                    fontSize: "12px",
                                                    mt: "3px",
                                                    ml: "14px",
                                                }}
                                            >
                                                Please select at least one
                                                category
                                            </Box>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 2,
                                        mt: 4,
                                    }}
                                >
                                    <CancelButton
                                        onCancelClickHandler={
                                            onCancelClickHandler
                                        }
                                    />
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            bgcolor: "#3e97ff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
}
