import { ChangeEvent, useState, FormEvent, SyntheticEvent } from "react";
import { CreateDataConsumerUserDto, CreateUserDto } from "../services/openapi";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
        firstName: ErrorFields;
        lastName: ErrorFields
        email: ErrorFields
        primaryPhone: ErrorFields
        password: ErrorFields
    };

export const useFormCreateConsumerUser = (
    initialUserValues: CreateUserDto,
    initialConsumerUserValues: CreateDataConsumerUserDto,
    onSubmitHandler: (userData: CreateUserDto, consumerUserData: CreateDataConsumerUserDto) => void
) => {
    const [userValues, setUserValues] =
        useState<CreateUserDto>(initialUserValues);
    const [consumerUserValues, setConsumerUserValues] =
        useState<CreateDataConsumerUserDto>(initialConsumerUserValues);
        const [password, setPassword] = useState("");
 const [formErrors, setFormErrors] = useState<Errors>({
            firstName: {
                error: false,
                errorMessage: ""
            },
            lastName: {
                error: false,
                errorMessage: ""
            },
            email: {
                error: false,
                errorMessage: ""
            },
            primaryPhone: {
                error: false,
                errorMessage: ""
            },
            password: {
                error: false,
                errorMessage: ""
            }
    })
    const onPasswordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onUserChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setUserValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    };
    const onUserRoleSelectHandler = (e: SyntheticEvent, value: CreateDataConsumerUserDto.userRole | null) => {
        if (value) {
            setConsumerUserValues((state) => ({
            ...state,
            "userRole": value
        })); 
        }
    }

    const validateValues = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/gm;
        const passwordRegex = /^(?=.*\d).{8,}$/;
        const newErrors: Errors = {
            firstName: {
                    error: false,
                    errorMessage: ""
                },
                lastName: {
                    error: false,
                    errorMessage: ""
                },
                email: {
                    error: false,
                    errorMessage: ""
                },
                primaryPhone: {
                    error: false,
                    errorMessage: ""
                },
                password: {
                    error: false,
                    errorMessage: ""
                }
        }
        let isValid = true;
        if (!userValues.firstName) {  
            newErrors.firstName.error = true;
            newErrors.firstName.errorMessage = "This field is required"
            isValid = false;
        }
        if (!userValues.lastName) {  
            newErrors.lastName.error = true;
            newErrors.lastName.errorMessage = "This field is required"
            isValid = false;
        }
        if (!userValues.primaryPhone) {  
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage = "This field is required"
            isValid = false;
        }
        if (userValues.primaryPhone && !userValues.primaryPhone.startsWith("+")) {  
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage = 'Please enter a valid phone number. The phone number should start with "+" followed by country code'
            isValid = false;
        }
        if (!emailRegex.test(userValues.email)) {  
            newErrors.email.error = true;
            newErrors.email.errorMessage = "Please enter a valid email address"
            isValid = false;
        }
        if (!passwordRegex.test(password)) {  
            newErrors.password.error = true;
            newErrors.password.errorMessage = "The password must contain at least 8 characters, including a number"
            isValid = false;
        }

        setFormErrors(newErrors)
        return isValid
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateValues()) {
            return
        }

        onSubmitHandler(userValues, consumerUserValues);
    };

    return {
        userValues,
        consumerUserValues,
        onSubmit,
        onUserChangeHandler,
        onUserRoleSelectHandler,
        formErrors,
        password,
        onPasswordChangeHandler
    };
};
