import {
    TextField,
    Button,
    Box,
    Typography,
    Autocomplete,
    createFilterOptions,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
    DataAgreement,
    DataAgreementService,
    DataConsumer,
    DataConsumersService,
    DataProvider,
    DataProvidersService,
    UpdateDataAgreementDto,
} from "../../services/openapi";
import { CancelButton } from "../common/CancelButton";
import { agreementLanguages } from "../../utilities/utils";
import { useEffect, useState } from "react";
import Spinner from "../common/spinner";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ParamTypes } from "../../utilities/types";
import { useFormEditAgreement } from "../../hooks/useFormEditAgreement";
import utc from "dayjs/plugin/utc"; // Import the utc plugin

dayjs.extend(utc); // Use the utc plugin

export default function EditAgreement() {
    const { agreementId } = useParams<keyof ParamTypes>() as ParamTypes;
    const [agreement, setAgreement] = useState<DataAgreement | null>(null);
    const [provider, setProvider] = useState<DataProvider | null>(null);
    const [consumer, setConsumer] = useState<DataConsumer | null>(null);
    const [priceTypes, setPriceTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>("");

    const dimensions: string[] = ["Metric (cm)", "Imperial (inches)"];

    const initialValues: UpdateDataAgreementDto = {
        agreementStartDate: "",
        agreementEndDate: "",
        priceType: "",
        language: UpdateDataAgreementDto.language.EN,
        priceFactor: 1.0,
        isMetric: true,
    };
    const onEditSubmitHandler = async (
        data: UpdateDataAgreementDto
    ): Promise<void> => {
        setIsLoading(true);
        const updatedData = {
            ...data,
            priceFactor: Number(data.priceFactor.toString().replace(",", ".")),
        };
        try {
            await DataAgreementService.dataAgreementControllerUpdateDataAgreement(
                agreementId,
                updatedData
            );
            navigate("/agreements");
            setIsLoading(false);
        } catch (error: Error | any) {
            console.error(error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const {
        values,
        onChangeHandler,
        onSubmit,
        changeValues,
        onStartDateChangeHandler,
        formErrors,
        onPriceTypeChangeHandler,
        onDimensionsChangeHandler,
        onLanguageChangeHandler,
        onEndDateChangeHandler,
    } = useFormEditAgreement(initialValues, onEditSubmitHandler);

    useEffect(() => {
        DataAgreementService.dataAgreementControllerGetDataAgreement(
            agreementId
        )
            .then((result) => {
                setAgreement(result);
                changeValues(result);
                Promise.all([
                    DataProvidersService.dataProviderControllerFindOne(
                        result.dataProviderId.toString()
                    ),
                    DataConsumersService.dataConsumerControllerFindOne(
                        result.dataConsumerId.toString()
                    ),
                ])
                    .then(([providersData, consumersData]) => {
                        setProvider(providersData);
                        setConsumer(consumersData);
                        DataProvidersService.dataProviderControllerGetDataProviderUniquePriceTypesV1(
                            providersData._id.toString()
                        )
                            .then((priceTypes) => {
                                setPriceTypes(priceTypes);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.error(error);
                                setError(error.message);
                                setIsLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                        setError(error.message);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsLoading(false);
            });
    }, [agreementId, changeValues]);

    const navigate = useNavigate();

    const onCancelClickHandler = () => {
        navigate("/agreements");
    };
    return (
        <>
           {error && (

                  <div id="error-page" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <h1>Oops!</h1>
                  <p>Sorry, an unexpected error has occurred.</p>
                  <p>
                    <i>{error}</i>
                  </p>
                </div>
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
                <Box
                    component="form"
                    noValidate
                    // sx={{
                    //     maxWidth: "60%",
                    // }}
                    width="100%"
                    action="POST"
                    onSubmit={onSubmit}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                    >
                        Edit Data Access Agreement
                    </Typography>
                    <Box display={"flex"} gap={1}>
                        <Box
                            sx={{
                                border: "1px solid #0000001f",
                                width: "100%",
                                p: 3,
                                borderRadius: "12px",
                                mb: 3,
                            }}
                        >
                            <Typography
                                component="h6"
                                variant="h6"
                                fontSize={"16px"}
                                fontWeight={"bold"}
                                // mx={2}
                                mb={1}
                            >
                                Data Provider
                            </Typography>

                            <TextField
                                sx={{ width: "100%", mt: 2 }}
                                label="Data Provider"
                                value={provider?.companyName}
                                disabled
                                variant="outlined"
                                margin="dense"
                            />
                        </Box>
                        <Box
                            sx={{
                                border: "1px solid #0000001f",
                                width: "100%",
                                p: 3,
                                borderRadius: "12px",
                                mb: 3,
                            }}
                        >
                            <Typography
                                component="h6"
                                variant="h6"
                                fontSize={"16px"}
                                fontWeight={"bold"}
                                // mx={2}
                                mb={1}
                            >
                                Data Consumer
                            </Typography>

                            <TextField
                                sx={{ width: "100%", mt: 2 }}
                                label="Data Consumer"
                                value={consumer?.companyName}
                                disabled
                                variant="outlined"
                                margin="dense"
                            />
                            <TextField
                                sx={{ width: "100%", mt: 2 }}
                                label="Target System"
                                value={
                                    agreement?.webshopSolution === "WOOCOMMERCE"
                                        ? "WooCommerce"
                                        : "None"
                                }
                                disabled
                                variant="outlined"
                                margin="dense"
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            border: "1px solid #0000001f",
                            width: "100%",
                            p: 3,
                            borderRadius: "12px",
                            mb: 3,
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Agreement Period
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ width: "60%", mt: 2 }}
                                label="Start Date"
                                value={dayjs(values.agreementStartDate)}
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            disabled: true,
                                        },
                                    },
                                }}
                                onChange={onStartDateChangeHandler}
                                format="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ width: "60%", mt: 2 }}
                                label="End Date"
                                value={dayjs(values.agreementEndDate)}
                                slotProps={{
                                    textField: {
                                        error: formErrors.agreementEndDate
                                            .error,
                                        helperText: formErrors.agreementEndDate
                                            .error
                                            ? formErrors.agreementEndDate
                                                  .errorMessage
                                            : "",
                                        inputProps: {
                                            disabled: true,
                                        },
                                    },
                                }}
                                onChange={onEndDateChangeHandler}
                                format="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box
                        sx={{
                            border: "1px solid #0000001f",
                            width: "100%",
                            p: 3,
                            borderRadius: "12px",
                            mb: 3,
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="h6"
                            fontSize={"16px"}
                            fontWeight={"bold"}
                            // mx={2}
                            mb={1}
                        >
                            Export Options
                        </Typography>

                        <Autocomplete
                            id="priceType-select"
                            sx={{ mt: 2 }}
                            options={priceTypes.sort((a, b) =>
                                a.localeCompare(b)
                            )}
                            autoHighlight
                            autoSelect
                            aria-required
                            disableClearable={true}
                            value={values.priceType}
                            getOptionLabel={(option) => option}
                            filterOptions={createFilterOptions({
                                matchFrom: "start",
                                stringify: (option) => option,
                            })}
                            onChange={onPriceTypeChangeHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Price Type to be exported"
                                    sx={{ width: "60%" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    error={formErrors.priceType.error}
                                    helperText={
                                        formErrors.priceType.error
                                            ? formErrors.priceType.errorMessage
                                            : ""
                                    }
                                />
                            )}
                        />
                        <TextField
                            sx={{ width: "60%", mt: 2 }}
                            label="Price Factor"
                            name="priceFactor"
                            value={values.priceFactor}
                            onChange={onChangeHandler}
                            required
                            variant="outlined"
                            margin="dense"
                            error={formErrors.priceFactor.error}
                            helperText={
                                formErrors.priceFactor.error
                                    ? formErrors.priceFactor.errorMessage
                                    : ""
                            }
                        />
                        <Autocomplete
                            id="productLanguages-select"
                            sx={{ mt: 2 }}
                            options={agreementLanguages.sort((a, b) =>
                                a.label.localeCompare(b.label)
                            )}
                            autoHighlight
                            autoSelect
                            aria-required
                            value={agreementLanguages.find(
                                (lang) => lang.code === values.language
                            )}
                            disableClearable={true}
                            getOptionLabel={(option) => option.label}
                            filterOptions={createFilterOptions({
                                matchFrom: "start",
                                stringify: (option) => option.label,
                            })}
                            onChange={onLanguageChangeHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Product Language"
                                    sx={{ width: "60%" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    error={formErrors.language.error}
                                    helperText={
                                        formErrors.language.error
                                            ? formErrors.language.errorMessage
                                            : ""
                                    }
                                />
                            )}
                        />
                        <Autocomplete
                            id="dimensions-select"
                            sx={{ mt: 2 }}
                            options={dimensions}
                            autoHighlight
                            autoSelect
                            aria-required
                            value={
                                values.isMetric
                                    ? "Metric (cm)"
                                    : "Imperial (inches)"
                            }
                            disableClearable={true}
                            getOptionLabel={(option) => option}
                            filterOptions={createFilterOptions({
                                matchFrom: "start",
                                stringify: (option) => option,
                            })}
                            onChange={onDimensionsChangeHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Dimensions to be exported"
                                    sx={{ width: "60%" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <CancelButton
                            onCancelClickHandler={onCancelClickHandler}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                bgcolor: "#3e97ff",
                                fontWeight: "bold",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
}
