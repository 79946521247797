import './supertokens-init';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./utilities/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SuperTokensWrapper } from "supertokens-auth-react";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <SuperTokensWrapper>
        <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
        </ThemeProvider>
        </QueryClientProvider>
        </SuperTokensWrapper>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
