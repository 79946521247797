import { CreateDataAgreementDto, CreateInLanguageDto, CreateProductVariantDto, CreateUserDto } from "../services/openapi";
import { Country, CountryType } from "./types";

export const multiSelectCheck = (selected: string[]) => {
    if (selected.length >= 2) {
        return true;
    } else {
        return false;
    }
}

// TODO set countryList to provider and consumer instead of countries
export const countryList: Country[] = [
  {code: CreateProductVariantDto.countryOfOrigin.AF, label: "Afghanistan"},
  {code: CreateProductVariantDto.countryOfOrigin.AX, label: "Aland Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.AL, label: "Albania"},
  {code: CreateProductVariantDto.countryOfOrigin.DZ, label: "Algeria"},
  {code: CreateProductVariantDto.countryOfOrigin.AS, label: "American Samoa"},
  {code: CreateProductVariantDto.countryOfOrigin.AD, label: "Andorra"},
  {code: CreateProductVariantDto.countryOfOrigin.AO, label: "Angola"},
  {code: CreateProductVariantDto.countryOfOrigin.AI, label: "Anguilla"},
  {code: CreateProductVariantDto.countryOfOrigin.AQ, label: "Antarctica"},
  {code: CreateProductVariantDto.countryOfOrigin.AG, label: "Antigua and Barbuda"},
  {code: CreateProductVariantDto.countryOfOrigin.AR, label: "Argentina"},
  {code: CreateProductVariantDto.countryOfOrigin.AM, label: "Armenia"},
  {code: CreateProductVariantDto.countryOfOrigin.AW, label: "Aruba"},
  {code: CreateProductVariantDto.countryOfOrigin.AU, label: "Australia"},
  {code: CreateProductVariantDto.countryOfOrigin.AT, label: "Austria"},
  {code: CreateProductVariantDto.countryOfOrigin.AZ, label: "Azerbaijan"},
  {code: CreateProductVariantDto.countryOfOrigin.BS, label: "Bahamas"},
  {code: CreateProductVariantDto.countryOfOrigin.BH, label: "Bahrain"},
  {code: CreateProductVariantDto.countryOfOrigin.BD, label: "Bangladesh"},
  {code: CreateProductVariantDto.countryOfOrigin.BB, label: "Barbados"},
  {code: CreateProductVariantDto.countryOfOrigin.BY, label: "Belarus"},
  {code: CreateProductVariantDto.countryOfOrigin.BE, label: "Belgium"},
  {code: CreateProductVariantDto.countryOfOrigin.BZ, label: "Belize"},
  {code: CreateProductVariantDto.countryOfOrigin.BJ, label: "Benin"},
  {code: CreateProductVariantDto.countryOfOrigin.BM, label: "Bermuda"},
  {code: CreateProductVariantDto.countryOfOrigin.BT, label: "Bhutan"},
  {code: CreateProductVariantDto.countryOfOrigin.BO, label: "Bolivia"},
  {code: CreateProductVariantDto.countryOfOrigin.BQ, label: "Bonaire, Sint Eustatius and Saba"},
  {code: CreateProductVariantDto.countryOfOrigin.BA, label: "Bosnia and Herzegovina"},
  {code: CreateProductVariantDto.countryOfOrigin.BW, label: "Botswana"},
  {code: CreateProductVariantDto.countryOfOrigin.BV, label: "Bouvet Island"},
  {code: CreateProductVariantDto.countryOfOrigin.BR, label: "Brazil"},
  {code: CreateProductVariantDto.countryOfOrigin.IO, label: "British Indian Ocean Territory"},
  {code: CreateProductVariantDto.countryOfOrigin.BN, label: "Brunei Darussalam"},
  {code: CreateProductVariantDto.countryOfOrigin.BG, label: "Bulgaria"},
  {code: CreateProductVariantDto.countryOfOrigin.BF, label: "Burkina Faso"},
  {code: CreateProductVariantDto.countryOfOrigin.BI, label: "Burundi"},
  {code: CreateProductVariantDto.countryOfOrigin.KH, label: "Cambodia"},
  {code: CreateProductVariantDto.countryOfOrigin.CM, label: "Cameroon"},
  {code: CreateProductVariantDto.countryOfOrigin.CA, label: "Canada"},
  {code: CreateProductVariantDto.countryOfOrigin.CV, label: "Cape Verde"},
  {code: CreateProductVariantDto.countryOfOrigin.KY, label: "Cayman Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.CF, label: "Central African Republic"},
  {code: CreateProductVariantDto.countryOfOrigin.TD, label: "Chad"},
  {code: CreateProductVariantDto.countryOfOrigin.CL, label: "Chile"},
  {code: CreateProductVariantDto.countryOfOrigin.CN, label: "China"},
  {code: CreateProductVariantDto.countryOfOrigin.CX, label: "Christmas Island"},
  {code: CreateProductVariantDto.countryOfOrigin.CC, label: "Cocos (Keeling) Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.CO, label: "Colombia"},
  {code: CreateProductVariantDto.countryOfOrigin.KM, label: "Comoros"},
  {code: CreateProductVariantDto.countryOfOrigin.CG, label: "Congo"},
  {code: CreateProductVariantDto.countryOfOrigin.CD, label: "Congo, the Democratic Republic of the"},
  {code: CreateProductVariantDto.countryOfOrigin.CK, label: "Cook Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.CR, label: "Costa Rica"},
  {code: CreateProductVariantDto.countryOfOrigin.CI, label: "Cote d'Ivoire"},
  {code: CreateProductVariantDto.countryOfOrigin.HR, label: "Croatia"},
  {code: CreateProductVariantDto.countryOfOrigin.CU, label: "Cuba"},
  {code: CreateProductVariantDto.countryOfOrigin.CW, label: "Curacao"},
  {code: CreateProductVariantDto.countryOfOrigin.CY, label: "Cyprus"},
  {code: CreateProductVariantDto.countryOfOrigin.CZ, label: "Czech Republic"},
  {code: CreateProductVariantDto.countryOfOrigin.DK, label: "Denmark"},
  {code: CreateProductVariantDto.countryOfOrigin.DJ, label: "Djibouti"},
  {code: CreateProductVariantDto.countryOfOrigin.DM, label: "Dominica"},
  {code: CreateProductVariantDto.countryOfOrigin.DO, label: "Dominican Republic"},
  {code: CreateProductVariantDto.countryOfOrigin.EC, label: "Ecuador"},
  {code: CreateProductVariantDto.countryOfOrigin.EG, label: "Egypt"},
  {code: CreateProductVariantDto.countryOfOrigin.SV, label: "El Salvador"},
  {code: CreateProductVariantDto.countryOfOrigin.GQ, label: "Equatorial Guinea"},
  {code: CreateProductVariantDto.countryOfOrigin.ER, label: "Eritrea"},
  {code: CreateProductVariantDto.countryOfOrigin.EE, label: "Estonia"},
  {code: CreateProductVariantDto.countryOfOrigin.ET, label: "Ethiopia"},
  {code: CreateProductVariantDto.countryOfOrigin.FK, label: "Falkland Islands (Malvinas)"},
  {code: CreateProductVariantDto.countryOfOrigin.FO, label: "Faroe Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.FJ, label: "Fiji"},
  {code: CreateProductVariantDto.countryOfOrigin.FI, label: "Finland"},
  {code: CreateProductVariantDto.countryOfOrigin.FR, label: "France"},
  {code: CreateProductVariantDto.countryOfOrigin.GF, label: "French Guiana"},
  {code: CreateProductVariantDto.countryOfOrigin.PF, label: "French Polynesia"},
  {code: CreateProductVariantDto.countryOfOrigin.TF, label: "French Southern Territories"},
  {code: CreateProductVariantDto.countryOfOrigin.GA, label: "Gabon"},
  {code: CreateProductVariantDto.countryOfOrigin.GM, label: "Gambia"},
  {code: CreateProductVariantDto.countryOfOrigin.GE, label: "Georgia"},
  {code: CreateProductVariantDto.countryOfOrigin.DE, label: "Germany"},
  {code: CreateProductVariantDto.countryOfOrigin.GH, label: "Ghana"},
  {code: CreateProductVariantDto.countryOfOrigin.GI, label: "Gibraltar"},
  {code: CreateProductVariantDto.countryOfOrigin.GR, label: "Greece"},
  {code: CreateProductVariantDto.countryOfOrigin.GL, label: "Greenland"},
  {code: CreateProductVariantDto.countryOfOrigin.GD, label: "Grenada"},
  {code: CreateProductVariantDto.countryOfOrigin.GP, label: "Guadeloupe"},
  {code: CreateProductVariantDto.countryOfOrigin.GU, label: "Guam"},
  {code: CreateProductVariantDto.countryOfOrigin.GT, label: "Guatemala"},
  {code: CreateProductVariantDto.countryOfOrigin.GG, label: "Guernsey"},
  {code: CreateProductVariantDto.countryOfOrigin.GN, label: "Guinea"},
  {code: CreateProductVariantDto.countryOfOrigin.GW, label: "Guinea-Bissau"},
  {code: CreateProductVariantDto.countryOfOrigin.GY, label: "Guyana"},
  {code: CreateProductVariantDto.countryOfOrigin.HT, label: "Haiti"},
  {code: CreateProductVariantDto.countryOfOrigin.HM, label: "Heard Island and McDonald Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.VA, label: "Holy See (Vatican City State)"},
  {code: CreateProductVariantDto.countryOfOrigin.HN, label: "Honduras"},
  {code: CreateProductVariantDto.countryOfOrigin.HK, label: "Hong Kong"},
  {code: CreateProductVariantDto.countryOfOrigin.HU, label: "Hungary"},
  {code: CreateProductVariantDto.countryOfOrigin.IS, label: "Iceland"},
  {code: CreateProductVariantDto.countryOfOrigin.IN, label: "India"},
  {code: CreateProductVariantDto.countryOfOrigin.ID, label: "Indonesia"},
  {code: CreateProductVariantDto.countryOfOrigin.IR, label: "Iran, Islamic Republic of"},
  {code: CreateProductVariantDto.countryOfOrigin.IQ, label: "Iraq"},
  {code: CreateProductVariantDto.countryOfOrigin.IE, label: "Ireland"},
  {code: CreateProductVariantDto.countryOfOrigin.IM, label: "IsleOfMan"},
  {code: CreateProductVariantDto.countryOfOrigin.IL, label: "Israel"},
  {code: CreateProductVariantDto.countryOfOrigin.IT, label: "Italy"},
  {code: CreateProductVariantDto.countryOfOrigin.JM, label: "Jamaica"},
  {code: CreateProductVariantDto.countryOfOrigin.JP, label: "Japan"},
  {code: CreateProductVariantDto.countryOfOrigin.JE, label: "Jersey"},
  {code: CreateProductVariantDto.countryOfOrigin.JO, label: "Jordan"},
  {code: CreateProductVariantDto.countryOfOrigin.KZ, label: "Kazakhstan"},
  {code: CreateProductVariantDto.countryOfOrigin.KE, label: "Kenya"},
  {code: CreateProductVariantDto.countryOfOrigin.KI, label: "Kiribati"},
  {code: CreateProductVariantDto.countryOfOrigin.KP, label: "Korea, Democratic People's Republic of"},
  {code: CreateProductVariantDto.countryOfOrigin.KR, label: "Korea, Republic of"},
  {code: CreateProductVariantDto.countryOfOrigin.KW, label: "Kuwait"},
  {code: CreateProductVariantDto.countryOfOrigin.KG, label: "Kyrgyzstan"},
  {code: CreateProductVariantDto.countryOfOrigin.LA, label: "Lao People's Democratic Republic"},
  {code: CreateProductVariantDto.countryOfOrigin.LV, label: "Latvia"},
  {code: CreateProductVariantDto.countryOfOrigin.LB, label: "Lebanon"},
  {code: CreateProductVariantDto.countryOfOrigin.LS, label: "Lesotho"},
  {code: CreateProductVariantDto.countryOfOrigin.LR, label: "Liberia"},
  {code: CreateProductVariantDto.countryOfOrigin.LY, label: "Libya"},
  {code: CreateProductVariantDto.countryOfOrigin.LI, label: "Liechtenstein"},
  {code: CreateProductVariantDto.countryOfOrigin.LT, label: "Lithuania"},
  {code: CreateProductVariantDto.countryOfOrigin.LU, label: "Luxembourg"},
  {code: CreateProductVariantDto.countryOfOrigin.MO, label: "Macao"},
  {code: CreateProductVariantDto.countryOfOrigin.MK, label: "Macedonia, the Former Yugoslav Republic of"},
  {code: CreateProductVariantDto.countryOfOrigin.MG, label: "Madagascar"},
  {code: CreateProductVariantDto.countryOfOrigin.MW, label: "Malawi"},
  {code: CreateProductVariantDto.countryOfOrigin.MY, label: "Malaysia"},
  {code: CreateProductVariantDto.countryOfOrigin.MV, label: "Maldives"},
  {code: CreateProductVariantDto.countryOfOrigin.ML, label: "Mali"},
  {code: CreateProductVariantDto.countryOfOrigin.MT, label: "Malta"},
  {code: CreateProductVariantDto.countryOfOrigin.MH, label: "Marshall Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.MQ, label: "Martinique"},
  {code: CreateProductVariantDto.countryOfOrigin.MR, label: "Mauritania"},
  {code: CreateProductVariantDto.countryOfOrigin.MU, label: "Mauritius"},
  {code: CreateProductVariantDto.countryOfOrigin.YT, label: "Mayotte"},
  {code: CreateProductVariantDto.countryOfOrigin.MX, label: "Mexico"},
  {code: CreateProductVariantDto.countryOfOrigin.FM, label: "Micronesia, Federated States of"},
  {code: CreateProductVariantDto.countryOfOrigin.MD, label: "Moldova, Republic of"},
  {code: CreateProductVariantDto.countryOfOrigin.MC, label: "Monaco"},
  {code: CreateProductVariantDto.countryOfOrigin.MN, label: "Mongolia"},
  {code: CreateProductVariantDto.countryOfOrigin.ME, label: "Montenegro"},
  {code: CreateProductVariantDto.countryOfOrigin.MS, label: "Montserrat"},
  {code: CreateProductVariantDto.countryOfOrigin.MA, label: "Morocco"},
  {code: CreateProductVariantDto.countryOfOrigin.MZ, label: "Mozambique"},
  {code: CreateProductVariantDto.countryOfOrigin.MM, label: "Myanmar"},
  {code: CreateProductVariantDto.countryOfOrigin.NA, label: "Namibia"},
  {code: CreateProductVariantDto.countryOfOrigin.NR, label: "Nauru"},
  {code: CreateProductVariantDto.countryOfOrigin.NP, label: "Nepal"},
  {code: CreateProductVariantDto.countryOfOrigin.NL, label: "Netherlands"},
  {code: CreateProductVariantDto.countryOfOrigin.NC, label: "New Caledonia"},
  {code: CreateProductVariantDto.countryOfOrigin.NZ, label: "New Zealand"},
  {code: CreateProductVariantDto.countryOfOrigin.NI, label: "Nicaragua"},
  {code: CreateProductVariantDto.countryOfOrigin.NE, label: "Niger"},
  {code: CreateProductVariantDto.countryOfOrigin.NG, label: "Nigeria"},
  {code: CreateProductVariantDto.countryOfOrigin.NU, label: "Niue"},
  {code: CreateProductVariantDto.countryOfOrigin.NF, label: "Norfolk Island"},
  {code: CreateProductVariantDto.countryOfOrigin.MP, label: "Northern Mariana Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.NO, label: "Norway"},
  {code: CreateProductVariantDto.countryOfOrigin.OM, label: "Oman"},
  {code: CreateProductVariantDto.countryOfOrigin.PK, label: "Pakistan"},
  {code: CreateProductVariantDto.countryOfOrigin.PW, label: "Palau"},
  {code: CreateProductVariantDto.countryOfOrigin.PS, label: "Palestinian Territory, Occupied"},
  {code: CreateProductVariantDto.countryOfOrigin.PA, label: "Panama"},
  {code: CreateProductVariantDto.countryOfOrigin.PG, label: "Papua New Guinea"},
  {code: CreateProductVariantDto.countryOfOrigin.PY, label: "Paraguay"},
  {code: CreateProductVariantDto.countryOfOrigin.PE, label: "Peru"},
  {code: CreateProductVariantDto.countryOfOrigin.PH, label: "Philippines"},
  {code: CreateProductVariantDto.countryOfOrigin.PN, label: "Pitcairn"},
  {code: CreateProductVariantDto.countryOfOrigin.PL, label: "Poland"},
  {code: CreateProductVariantDto.countryOfOrigin.PT, label: "Portugal"},
  {code: CreateProductVariantDto.countryOfOrigin.PR, label: "Puerto Rico"},
  {code: CreateProductVariantDto.countryOfOrigin.QA, label: "Qatar"},
  {code: CreateProductVariantDto.countryOfOrigin.RE, label: "Reunion"},
  {code: CreateProductVariantDto.countryOfOrigin.RO, label: "Romania"},
  {code: CreateProductVariantDto.countryOfOrigin.RU, label: "Russian Federation"},
  {code: CreateProductVariantDto.countryOfOrigin.RW, label: "Rwanda"},
  {code: CreateProductVariantDto.countryOfOrigin.BL, label: "Saint Barthelemy"},
  {code: CreateProductVariantDto.countryOfOrigin.SH, label: "Saint Helena"},
  {code: CreateProductVariantDto.countryOfOrigin.KN, label: "Saint Kitts and Nevis"},
  {code: CreateProductVariantDto.countryOfOrigin.LC, label: "Saint Lucia"},
  {code: CreateProductVariantDto.countryOfOrigin.MF, label: "Saint Martin (French part)"},
  {code: CreateProductVariantDto.countryOfOrigin.PM, label: "Saint Pierre and Miquelon"},
  {code: CreateProductVariantDto.countryOfOrigin.VC, label: "Saint Vincent and the Grenadines"},
  {code: CreateProductVariantDto.countryOfOrigin.WS, label: "Samoa"},
  {code: CreateProductVariantDto.countryOfOrigin.SM, label: "San Marino"},
  {code: CreateProductVariantDto.countryOfOrigin.ST, label: "Sao Tome and Principe"},
  {code: CreateProductVariantDto.countryOfOrigin.SA, label: "Saudi Arabia"},
  {code: CreateProductVariantDto.countryOfOrigin.SN, label: "Senegal"},
  {code: CreateProductVariantDto.countryOfOrigin.RS, label: "Serbia"},
  {code: CreateProductVariantDto.countryOfOrigin.SC, label: "Seychelles"},
  {code: CreateProductVariantDto.countryOfOrigin.SL, label: "Sierra Leone"},
  {code: CreateProductVariantDto.countryOfOrigin.SG, label: "Singapore"},
  {code: CreateProductVariantDto.countryOfOrigin.SX, label: "Sint Maarten (Dutch part)"},
  {code: CreateProductVariantDto.countryOfOrigin.SK, label: "Slovakia"},
  {code: CreateProductVariantDto.countryOfOrigin.SI, label: "Slovenia"},
  {code: CreateProductVariantDto.countryOfOrigin.SB, label: "Solomon Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.SO, label: "Somalia"},
  {code: CreateProductVariantDto.countryOfOrigin.ZA, label: "South Africa"},
  {code: CreateProductVariantDto.countryOfOrigin.GS, label: "South Georgia and the South Sandwich Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.SS, label: "South Sudan"},
  {code: CreateProductVariantDto.countryOfOrigin.ES, label: "Spain"},
  {code: CreateProductVariantDto.countryOfOrigin.LK, label: "Sri Lanka"},
  {code: CreateProductVariantDto.countryOfOrigin.SD, label: "Sudan"},
  {code: CreateProductVariantDto.countryOfOrigin.SR, label: "Suriname"},
  {code: CreateProductVariantDto.countryOfOrigin.SJ, label: "Svalbard and Jan Mayen"},
  {code: CreateProductVariantDto.countryOfOrigin.SZ, label: "Swaziland"},
  {code: CreateProductVariantDto.countryOfOrigin.SE, label: "Sweden"},
  {code: CreateProductVariantDto.countryOfOrigin.CH, label: "Switzerland"},
  {code: CreateProductVariantDto.countryOfOrigin.SY, label: "Syrian Arab Republic"},
  {code: CreateProductVariantDto.countryOfOrigin.TW, label: "Taiwan, Province of China"},
  {code: CreateProductVariantDto.countryOfOrigin.TJ, label: "Tajikistan"},
  {code: CreateProductVariantDto.countryOfOrigin.TZ, label: "Tanzania, United Republic of"},
  {code: CreateProductVariantDto.countryOfOrigin.TH, label: "Thailand"},
  {code: CreateProductVariantDto.countryOfOrigin.TL, label: "Timor-Leste"},
  {code: CreateProductVariantDto.countryOfOrigin.TG, label: "Togo"},
  {code: CreateProductVariantDto.countryOfOrigin.TK, label: "Tokelau"},
  {code: CreateProductVariantDto.countryOfOrigin.TO, label: "Tonga"},
  {code: CreateProductVariantDto.countryOfOrigin.TT, label: "Trinidad and Tobago"},
  {code: CreateProductVariantDto.countryOfOrigin.TN, label: "Tunisia"},
  {code: CreateProductVariantDto.countryOfOrigin.TR, label: "Turkey"},
  {code: CreateProductVariantDto.countryOfOrigin.TM, label: "Turkmenistan"},
  {code: CreateProductVariantDto.countryOfOrigin.TC, label: "Turks and Caicos Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.TV, label: "Tuvalu"},
  {code: CreateProductVariantDto.countryOfOrigin.UG, label: "Uganda"},
  {code: CreateProductVariantDto.countryOfOrigin.UA, label: "Ukraine"},
  {code: CreateProductVariantDto.countryOfOrigin.AE, label: "United Arab Emirates"},
  {code: CreateProductVariantDto.countryOfOrigin.GB, label: "United Kingdom"},
  {code: CreateProductVariantDto.countryOfOrigin.US, label: "United States"},
  {code: CreateProductVariantDto.countryOfOrigin.UM, label: "United States Minor Outlying Islands"},
  {code: CreateProductVariantDto.countryOfOrigin.UY, label: "Uruguay"},
  {code: CreateProductVariantDto.countryOfOrigin.UZ, label: "Uzbekistan"},
  {code: CreateProductVariantDto.countryOfOrigin.VU, label: "Vanuatu"},
  {code: CreateProductVariantDto.countryOfOrigin.VE, label: "Venezuela"},
  {code: CreateProductVariantDto.countryOfOrigin.VN, label: "Viet Nam"},
  {code: CreateProductVariantDto.countryOfOrigin.VG, label: "Virgin Islands, British"},
  {code: CreateProductVariantDto.countryOfOrigin.VI, label: "Virgin Islands, U.S."},
  {code: CreateProductVariantDto.countryOfOrigin.WF, label: "Wallis and Futuna"},
  {code: CreateProductVariantDto.countryOfOrigin.EH, label: "Western Sahara"},
  {code: CreateProductVariantDto.countryOfOrigin.YE, label: "Yemen"},
  {code: CreateProductVariantDto.countryOfOrigin.ZM, label: "Zambia"},
  {code: CreateProductVariantDto.countryOfOrigin.ZW, label: "Zimbabwe"},
  {code: CreateProductVariantDto.countryOfOrigin.UX, label: ""},
];

export const countries: readonly CountryType[] = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
      code: 'AE',
      label: 'United Arab Emirates',
      phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
      code: 'AG',
      label: 'Antigua and Barbuda',
      phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
      code: 'AU',
      label: 'Australia',
      phone: '61',
      suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
      code: 'BA',
      label: 'Bosnia and Herzegovina',
      phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
      code: 'CA',
      label: 'Canada',
      phone: '1',
      suggested: true,
    },
    {
      code: 'CC',
      label: 'Cocos (Keeling) Islands',
      phone: '61',
    },
    {
      code: 'CD',
      label: 'Congo, Democratic Republic of the',
      phone: '243',
    },
    {
      code: 'CF',
      label: 'Central African Republic',
      phone: '236',
    },
    {
      code: 'CG',
      label: 'Congo, Republic of the',
      phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
      code: 'DE',
      label: 'Germany',
      phone: '49',
      suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
      code: 'DO',
      label: 'Dominican Republic',
      phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
      code: 'FK',
      label: 'Falkland Islands (Malvinas)',
      phone: '500',
    },
    {
      code: 'FM',
      label: 'Micronesia, Federated States of',
      phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
      code: 'FR',
      label: 'France',
      phone: '33',
      suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
      code: 'HM',
      label: 'Heard Island and McDonald Islands',
      phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
      code: 'IO',
      label: 'British Indian Ocean Territory',
      phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
      code: 'IR',
      label: 'Iran, Islamic Republic of',
      phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
      code: 'JP',
      label: 'Japan',
      phone: '81',
      suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
      code: 'KN',
      label: 'Saint Kitts and Nevis',
      phone: '1-869',
    },
    {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
      code: 'LA',
      label: "Lao People's Democratic Republic",
      phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
      code: 'MD',
      label: 'Moldova, Republic of',
      phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
      code: 'MF',
      label: 'Saint Martin (French part)',
      phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
      code: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic of',
      phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
      code: 'MP',
      label: 'Northern Mariana Islands',
      phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
      code: 'PM',
      label: 'Saint Pierre and Miquelon',
      phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
      code: 'PS',
      label: 'Palestine, State of',
      phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
      code: 'SJ',
      label: 'Svalbard and Jan Mayen',
      phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
      code: 'ST',
      label: 'Sao Tome and Principe',
      phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
      code: 'SX',
      label: 'Sint Maarten (Dutch part)',
      phone: '1-721',
    },
    {
      code: 'SY',
      label: 'Syrian Arab Republic',
      phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
      code: 'TC',
      label: 'Turks and Caicos Islands',
      phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
      code: 'TF',
      label: 'French Southern Territories',
      phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
      code: 'TT',
      label: 'Trinidad and Tobago',
      phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
      code: 'TW',
      label: 'Taiwan',
      phone: '886',
    },
    {
      code: 'TZ',
      label: 'United Republic of Tanzania',
      phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
      code: 'US',
      label: 'United States',
      phone: '1',
      suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
      code: 'VA',
      label: 'Holy See (Vatican City State)',
      phone: '379',
    },
    {
      code: 'VC',
      label: 'Saint Vincent and the Grenadines',
      phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
      code: 'VG',
      label: 'British Virgin Islands',
      phone: '1-284',
    },
    {
      code: 'VI',
      label: 'US Virgin Islands',
      phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ];

  type LanguageType = {
    code: CreateInLanguageDto.lang;
    label: string;
  }

  export type AgreementLanguageType = {
    code: CreateDataAgreementDto.language;
    label: string;
  }

  export const languages: LanguageType[] = [
    { code: CreateInLanguageDto.lang.ZH, label: 'Chinese' },
    { code: CreateInLanguageDto.lang.ES, label: 'Spanish' },
    { code: CreateInLanguageDto.lang.EN, label: 'English' },
    { code: CreateInLanguageDto.lang.HI, label: 'Hindi' },
    { code: CreateInLanguageDto.lang.UR, label: 'Urdu' },
    { code: CreateInLanguageDto.lang.AR, label: 'Arabic' },
    { code: CreateInLanguageDto.lang.BN, label: 'Bengali' },
    { code: CreateInLanguageDto.lang.PT, label: 'Portuguese' },
    { code: CreateInLanguageDto.lang.NO, label: 'Norwegian' },
    { code: CreateInLanguageDto.lang.JA, label: 'Japanese' },
    { code: CreateInLanguageDto.lang.LA, label: 'Latin' },
    { code: CreateInLanguageDto.lang.DE, label: 'German' },
    { code: CreateInLanguageDto.lang.FR, label: 'French' },
    { code: CreateInLanguageDto.lang.IT, label: 'Italian' },
    { code: CreateInLanguageDto.lang.TR, label: 'Turkish' },
    { code: CreateInLanguageDto.lang.NL, label: 'Dutch' },
    { code: CreateInLanguageDto.lang.PL, label: 'Polish' },
    { code: CreateInLanguageDto.lang.SV, label: 'Swedish' },
    { code: CreateInLanguageDto.lang.CS, label: 'Czech' },
    { code: CreateInLanguageDto.lang.FI, label: 'Finnish' },
    { code: CreateInLanguageDto.lang.RO, label: 'Romanian' },
    { code: CreateInLanguageDto.lang.EL, label: 'Greek' },
    { code: CreateInLanguageDto.lang.DA, label: 'Danish' },
    { code: CreateInLanguageDto.lang.BG, label: 'Bulgarian' },
    { code: CreateInLanguageDto.lang.SL, label: 'Slovenian' },
    { code: CreateInLanguageDto.lang.LT, label: 'Lithuanian' },
    { code: CreateInLanguageDto.lang.SK, label: 'Slovak' },
    { code: CreateInLanguageDto.lang.LV, label: 'Latvian' },
    { code: CreateInLanguageDto.lang.ET, label: 'Estonian' },
    { code: CreateInLanguageDto.lang.HR, label: 'Croatian' },
    { code: CreateInLanguageDto.lang.HU, label: 'Hungarian' },
    { code: CreateInLanguageDto.lang.GA, label: 'Irish' },
    { code: CreateInLanguageDto.lang.CY, label: 'Welsh' },
    { code: CreateInLanguageDto.lang.SQ, label: 'Albanian' },
    { code: CreateInLanguageDto.lang.SR, label: 'Serbian' },
    { code: CreateInLanguageDto.lang.IS, label: 'Icelandic' },
    { code: CreateInLanguageDto.lang.MT, label: 'Maltese' },
    { code: CreateInLanguageDto.lang.MK, label: 'Macedonian' },
    { code: CreateInLanguageDto.lang.EU, label: 'Basque' },
    { code: CreateInLanguageDto.lang.GD, label: 'Scottish Gaelic' },
    { code: CreateInLanguageDto.lang.KO, label: 'Korean' },
    { code: CreateInLanguageDto.lang.UK, label: 'Ukrainian' },
    { code: CreateInLanguageDto.lang.FO, label: 'Faroese' },
    { code: CreateInLanguageDto.lang.KU, label: 'Kurdish' },
    { code: CreateInLanguageDto.lang.BS, label: 'Bosnian' },
    { code: CreateInLanguageDto.lang.UX, label: '' },
 
  ]


  export const agreementLanguages: AgreementLanguageType[] = [
    { code: CreateDataAgreementDto.language.ZH, label: 'Chinese' },
    { code: CreateDataAgreementDto.language.ES, label: 'Spanish' },
    { code: CreateDataAgreementDto.language.EN, label: 'English' },
    { code: CreateDataAgreementDto.language.PT, label: 'Portuguese' },
    { code: CreateDataAgreementDto.language.NO, label: 'Norwegian' },
    { code: CreateDataAgreementDto.language.JA, label: 'Japanese' },
    { code: CreateDataAgreementDto.language.DE, label: 'German' },
    { code: CreateDataAgreementDto.language.FR, label: 'French' },
    { code: CreateDataAgreementDto.language.SV, label: 'Swedish' },
    { code: CreateDataAgreementDto.language.DA, label: 'Danish' },
    { code: CreateDataAgreementDto.language.KO, label: 'Korean' },
    { code: CreateDataAgreementDto.language.UX, label: '' },
 
  ]

  export type UserRoleType = {
    role: CreateUserDto.userRole;
    roleName: string;
  }

  export const userRoles: UserRoleType[] = [
    { role: CreateUserDto.userRole.NONE, roleName: 'None' },
    { role: CreateUserDto.userRole.PLATFORM_ADMINISTRATOR, roleName: 'Administrator'},

  ]

  export type WebshopSolutionType = {
    name: CreateDataAgreementDto.webshopSolution;
    label: string;
  }
  export const webshopSolutions: WebshopSolutionType[] = [
    { name: CreateDataAgreementDto.webshopSolution.NONE, label: 'None' },
    { name: CreateDataAgreementDto.webshopSolution.WOOCOMMERCE, label: 'WooCommerce'},

  ]
