/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ObjectId } from './ObjectId';

export type CreateDataProviderUserDto = {
    userId: ObjectId;
    userRole: CreateDataProviderUserDto.userRole;
};

export namespace CreateDataProviderUserDto {

    export enum userRole {
        USER = 'USER',
        ADMIN = 'ADMIN',
    }


}
