import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { IActionButtonProps } from "../../utilities/types";
import { Link } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0.5),
        minWidth: 140,
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            //   '& .MuiSvgIcon-root': {
            //     fontSize: 6,
            //     // color: theme.palette.text.secondary,
            //     // marginRight: theme.spacing(1.5),
            //   }
            //   ,
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export default function ActionButtonCompanies({
    id,
    collection,
    handleCheckClick,
}: IActionButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUsersClick = () => {
        navigate(`/${collection}/${id}/users`);
    };

    const handleProductsClick = () => {
      navigate(`/providers/${id}/products`);
  };
  const handleImportsClick = () => {
    navigate(`/providers/${id}/imports`);
};
const handleExportsClick = () => {
    navigate(`/consumers/${id}/exports`);
};
const handleAgreementsClick = () => {
    navigate(`/providers/${id}/agreements`);
};
    const onDeleteActionClick = (
        event: React.MouseEvent<unknown>,
        id: string
    ) => {
        handleCheckClick(event, id);
        handleClose();
    };

    return (
        <div>
            <Button
                sx={{
                    bgcolor: "#3e97ff",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                }}
                variant="contained"
                size="small"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Actions
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
               
                {collection === "providers" && (
                   <MenuItem
                   onClick={handleProductsClick}
                   disableRipple
                   sx={{ fontSize: 14, color: "#78829d" }}
               >
                   Maintain Products
               </MenuItem>
                )}
             {collection === "providers" && (
                   <MenuItem
                   onClick={handleAgreementsClick}
                   disableRipple
                   sx={{ fontSize: 14, color: "#78829d" }}
               >
                   Data Access Agreements
               </MenuItem>
                )}
                {collection === "consumers" && (
                 <MenuItem
                 onClick={handleExportsClick}
                 disableRipple
                 sx={{ fontSize: 14, color: "#78829d" }}
             >
                 Export Data
             
             </MenuItem>
                )}
                                <MenuItem
                    onClick={handleUsersClick}
                    disableRipple
                    sx={{ fontSize: 14, color: "#78829d" }}
                >
                    Maintain Users
                </MenuItem>
                {collection === "providers" && (
                 <MenuItem
                 onClick={handleImportsClick}
                 disableRipple
                 sx={{ fontSize: 14, color: "#78829d" }}
             >
                 Import Products
             
             </MenuItem>
                )}
                 <Link
                    to={`/${collection}/${id}/edit`}
                    style={{
                        textDecoration: "none",
                        color: "#78829d",
                    }}
                >
                    <MenuItem disableRipple sx={{ fontSize: 14 }}>
                        Edit
                    </MenuItem>
                </Link>
                <MenuItem
                    onClick={(event) => onDeleteActionClick(event, id)}
                    disableRipple
                    sx={{ fontSize: 14, color: "#78829d" }}
                >
                    Delete
                </MenuItem>
            </StyledMenu>
        </div>
    );
};
