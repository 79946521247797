import { Link } from "react-router-dom";
import { Toolbar, Button, TextField } from "@mui/material";

export function SearchAgreementToolbar() {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
                gap: 2,
                alignItems: "center"
            }}
        >
            <TextField
                id="outlined-search"
                label="Search"
                type="search"
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#eeedf1',
                    },
                  }}
                // onChange={onSearchChangeHandler}
            />
            <Link to="/agreements/create">
            <Button
                variant="contained"
                sx={{
                    bgcolor: "#3e97ff",
                    fontWeight: "bold",
                }}
            >
                Add agreement
            </Button>
            </Link>
        </Toolbar>
    );
};
