import {
    ChangeEvent,
    useState,
    FormEvent,
    useCallback,
    SyntheticEvent,
} from "react";
import { UpdateDataConsumerDto } from "../services/openapi";
import { countries } from "../utilities/utils";
import { SelectChangeEvent } from "@mui/material";
import { consumerElementRefs } from "../utilities/types";

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
    companyName: ErrorFields;
    addressLine1: ErrorFields;
    city: ErrorFields;
    countryCode: ErrorFields;
    webUrl: ErrorFields;
    vatNumber: ErrorFields;
    primaryPhone: ErrorFields;
    primaryEmail: ErrorFields;
    webshopSolution: ErrorFields;
    billingCurrency: ErrorFields;
}
export const useFormEditConsumer = (
    initialValues: UpdateDataConsumerDto,
    onSubmitHandler: (data: UpdateDataConsumerDto) => void,
    elementReferences: consumerElementRefs
) => {
    const [values, setValues] = useState<UpdateDataConsumerDto>(initialValues);
    const [inputValue, setInputValue] = useState("");
    const [inputCurrency, setInputCurrency] = useState("");
    const [formErrors, setFormErrors] = useState<Errors>({
        companyName: {
            error: false,
            errorMessage: "",
        },
        addressLine1: {
            error: false,
            errorMessage: "",
        },
        city: {
            error: false,
            errorMessage: "",
        },
        countryCode: {
            error: false,
            errorMessage: "",
        },
        webUrl: {
            error: false,
            errorMessage: "",
        },
        vatNumber: {
            error: false,
            errorMessage: "",
        },
        primaryPhone: {
            error: false,
            errorMessage: "",
        },
        primaryEmail: {
            error: false,
            errorMessage: "",
        },
        billingCurrency: {
            error: false,
            errorMessage: "",
        },
        webshopSolution: {
            error: false,
            errorMessage: "",
        },
    });

    const onChangeHandler =
        (primaryAddress: string | undefined) =>
        (
            e:
                | SelectChangeEvent
                | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
            if (!primaryAddress) {
                setValues((state) => ({
                    ...state,
                    [e.target.name]: e.target.value,
                }));
            } else {
                setValues((state) => ({
                    ...state,
                    primaryAddress: {
                        ...state.primaryAddress,
                        [e.target.name]: e.target.value,
                    },
                }));
            }
        };

    const onCountryChangeHandler = (
        e: SyntheticEvent,
        value: { code: string; label: string; phone: string } | null
    ) => {
        if (value?.label) {
            setValues((state) => ({
                ...state,
                primaryAddress: {
                    ...state.primaryAddress,
                    countryCode: value.code,
                },
            }));
        }
    };

    const onCurrencySelectHandler = (
        e: SyntheticEvent,
        selectedValue: string | null
    ) => {
        if (selectedValue) {
            setValues((state) => ({
                ...state,
                billingCurrency: selectedValue,
            }));
        }
    };

    const validateValues = () => {
        const urlRegex = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/gm;
        const vatNumberRegex = /^[A-Z]{2}\d+\b/gm;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/gm;
        const newErrors: Errors = {
            companyName: {
                error: false,
                errorMessage: "",
            },
            addressLine1: {
                error: false,
                errorMessage: "",
            },
            city: {
                error: false,
                errorMessage: "",
            },
            countryCode: {
                error: false,
                errorMessage: "",
            },
            webUrl: {
                error: false,
                errorMessage: "",
            },
            vatNumber: {
                error: false,
                errorMessage: "",
            },
            primaryPhone: {
                error: false,
                errorMessage: "",
            },
            primaryEmail: {
                error: false,
                errorMessage: "",
            },
            billingCurrency: {
                error: false,
                errorMessage: "",
            },
            webshopSolution: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;
        let firstErrorField = null;

        if (!values.companyName) {
            newErrors.companyName.error = true;
            newErrors.companyName.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.nameRef;
        }
        if (!urlRegex.test(values.webUrl)) {
            newErrors.webUrl.error = true;
            newErrors.webUrl.errorMessage =
                "Please enter a valid url. The url should match the format: www.example.example";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.homePageRef;
        }
        if (!emailRegex.test(values.primaryEmail)) {
            newErrors.primaryEmail.error = true;
            newErrors.primaryEmail.errorMessage =
                "Please enter a valid email address";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.emailRef;
        }
        if (values.primaryPhone && !values.primaryPhone.startsWith("+")) {
            newErrors.primaryPhone.error = true;
            newErrors.primaryPhone.errorMessage =
                'Please enter a valid phone number. The phone number should start with "+" followed by country code';
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.phoneRef;
        }

        if (!values.primaryAddress.addressLine1) {
            newErrors.addressLine1.error = true;
            newErrors.addressLine1.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.addressOneRef;
        }
        if (!values.primaryAddress.city) {
            newErrors.city.error = true;
            newErrors.city.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField) firstErrorField = elementReferences.cityRef;
        }
        if (!values.primaryAddress.countryCode) {
            newErrors.countryCode.error = true;
            newErrors.countryCode.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.countryRef;
        }

        if (!vatNumberRegex.test(values.vatNumber)) {
            newErrors.vatNumber.error = true;
            newErrors.vatNumber.errorMessage =
                "Please enter a valid VAT number. The VAT number should start with two capital letters";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.vatNumberRef;
        }

        if (!values.billingCurrency) {
            newErrors.billingCurrency.error = true;
            newErrors.billingCurrency.errorMessage = "This field is required";
            isValid = false;
            if (!firstErrorField)
                firstErrorField = elementReferences.currencyRef;
        }

        setFormErrors(newErrors);
        if (firstErrorField) {
            firstErrorField.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
        return isValid;
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateValues()) {
            return;
        }

        onSubmitHandler(values);
    };
    const changeValues = useCallback((newValues: UpdateDataConsumerDto) => {
        setValues(newValues);
        setInputValue(
            countries.filter((country) => {
                return country.code === newValues.primaryAddress.countryCode;
            })[0]?.label
        );
    }, []);

    const onInputChange = (
        e: SyntheticEvent<Element, Event>,
        newValue: string
    ) => {
        setInputValue(newValue);
    };

    const onInputCurrencyChange = (
        e: SyntheticEvent<Element, Event>,
        newValue: string
    ) => {
        setInputCurrency(newValue);
    };

    return {
        values,
        onSubmit,
        inputValue,
        inputCurrency,
        onInputChange,
        onInputCurrencyChange,
        onChangeHandler,
        onCurrencySelectHandler,
        changeValues,
        onCountryChangeHandler,
        formErrors,
    };
};
