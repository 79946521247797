import { DataGrid, GridCellModes, GridCellModesModel, GridCellParams, GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { ProductAttr } from '../../../services/openapi';
import * as React from 'react';
// import EditIcon from '@mui/icons-material/Edit';
// import SaveIcon from '@mui/icons-material/Save';
// import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModesModel,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

type IAttributesGridProps = {
    onAttributesChange: (newAttributes: GridValidRowModel[]) => void;
    productAttributes: ProductAttr[];
}

  
export default function AttributesGrid(props: IAttributesGridProps) {
  const {productAttributes, onAttributesChange} = props;

  const initialRows: GridValidRowModel[] = productAttributes.map((attr, index) => {
    return {
      id: index,
      name: attr.name,
      value: attr.value
    }
  });

  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [],
  );

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    [],
  );

  React.useEffect(() => {
    onAttributesChange(rows);
  }, [rows, onAttributesChange]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    // const handleEditClick = (id: GridRowId) => () => {
    //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    // };
  
    // const handleSaveClick = (id: GridRowId) => () => {
    //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    // };
  
    // const handleDeleteClick = (id: GridRowId) => () => {
    //   setRows(rows.filter((row) => row.id !== id));
    // };
  
    // const handleCancelClick = (id: GridRowId) => () => {
    //   setRowModesModel({
    //     ...rowModesModel,
    //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //   });
  
    //   const editedRow = rows.find((row) => row.id === id);
    //   if (editedRow!.isNew) {
    //     setRows(rows.filter((row) => row.id !== id));
    //   }
    // };
  
    const processRowUpdate = (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
    // const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    //   <Tooltip {...props} classes={{ popper: className }} />
    // ))(({ theme }) => ({
    //   [`& .${tooltipClasses.tooltip}`]: {
    //     backgroundColor: "black",
    //     color: "red",
    //   },
    // }));
    // function NameEditInputCell(props: GridRenderEditCellParams) {
    //   const { error } = props;
    // console.log(error)
    //   return (
    //     <StyledTooltip open={!!error} title={error}>
    //       <GridEditInputCell {...props} />
    //     </StyledTooltip>
    //   );
    // }
    // function renderEditName(params: GridRenderEditCellParams) {
    //   return <NameEditInputCell {...params} />;
    // }
  
    const columns: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Attribute Name',
        minWidth: 300,
        // cellClassName: 'attribute-cell',
      },
      {
        field: 'value',
        headerName: 'Attribute Value',
        minWidth: 300,
        editable: true,
        // preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        //   const hasError = params.props.value !== "hi" ? "There is an error" : "";
        //   return { ...params.props, error: hasError };
        // },
        // renderEditCell: renderEditName,
      },
      // {
      //   field: '',
      //   // type: 'actions',
      //   // headerName: 'Actions',
      //   // minWidth: 50,
      //   // cellClassName: 'actions',
      //   cellClassName: 'attribute-cell',
      //   // getActions: ({ id }) => {
        //   const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        //   if (isInEditMode) {
        //     return [
        //       <GridActionsCellItem
        //         icon={<SaveIcon />}
        //         label="Save"
        //         sx={{
        //           color: 'primary.main',
        //         }}
        //         onClick={handleSaveClick(id)}
        //       />,
        //       <GridActionsCellItem
        //         icon={<CancelIcon />}
        //         label="Cancel"
        //         className="textPrimary"
        //         onClick={handleCancelClick(id)}
        //         color="inherit"
        //       />,
        //     ];
        //   }
  
        //   return [
        //     <GridActionsCellItem
        //       icon={<EditIcon />}
        //       label="Edit"
        //       className="textPrimary"
        //       onClick={handleEditClick(id)}
        //       color="inherit"
        //     />
        //     // <GridActionsCellItem
        //     //   icon={<DeleteIcon />}
        //     //   label="Delete"
        //     //   onClick={handleDeleteClick(id)}
        //     //   color="inherit"
        //     // />,
        //   ];
        // },
      // },
    ];
 
  
    return (
      // <Box
      //   sx={{
      //     height: 500,
      //     width: '100%',
      //     '& .actions': {
      //       color: 'text.secondary',
      //     },
      //     '& .textPrimary': {
      //       color: 'text.primary',
      //     },
      //   }}
      // >
        <DataGrid
        sx={{p: 2}}
          rows={rows}
          columns={columns}
          disableColumnMenu={true}
          initialState={{
            sorting: {
              sortModel:[{field: 'name', sort: 'asc'}],
            },
            pagination: { paginationModel: { pageSize: 5 }  }
          }}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          pageSizeOptions={[5, 10, 25]}
          hideFooterSelectedRowCount={true}
          // editMode="row"
          cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
          
          // slots={{
          //   toolbar: EditToolbar,
          // }}
          // slotProps={{
          //   toolbar: { setRows, setRowModesModel },
          // }}
          
        />
      // </Box>
    );
  }