import "./info-icon.scss";

export const InfoIcon = () => {
    return (
        
            <div className="sa">
                <div className="sa-warning">
                    <div className="sa-warning-body"></div>
                    <div className="sa-warning-dot"></div>
                </div>
            </div>
        
    );
};
