/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ImageMetadata } from './ImageMetadata';
import type { InLanguage } from './InLanguage';
import type { ObjectId } from './ObjectId';
import type { ProductAttr } from './ProductAttr';
import type { ProductCategory } from './ProductCategory';

export type Product = {
    _id: ObjectId;
    dataProviderId: ObjectId;
    importId: ObjectId;
    name: Array<InLanguage>;
    SKU: string;
    brand: string;
    collection: string;
    shortDescription: Array<InLanguage>;
    longDescription: Array<InLanguage>;
    images: Array<ImageMetadata>;
    status: Product.status;
    productAttrs: Array<ProductAttr>;
    variantsCount: Record<string, any>;
    priceTypes: Array<string>;
    productCategories: Array<ProductCategory>;
    variantModified: string;
    created: string;
    modified: string;
};

export namespace Product {

    export enum status {
        DRAFT = 'DRAFT',
        READY = 'READY',
        PUBLISHED = 'PUBLISHED',
        DISCONTINUED = 'DISCONTINUED',
    }


}
