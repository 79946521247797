/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDataProviderDto } from '../models/CreateDataProviderDto';
import type { CreateDataProviderUserDto } from '../models/CreateDataProviderUserDto';
import type { DataProvider } from '../models/DataProvider';
import type { DataProviderPaginatedResult } from '../models/DataProviderPaginatedResult';
import type { UpdateDataProviderDto } from '../models/UpdateDataProviderDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataProvidersService {

    /**
     * @param search 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerFindAll(
search?: string,
): CancelablePromise<Array<DataProvider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dataProviders',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerCreate(
requestBody: CreateDataProviderDto,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dataProviders',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param search companyName,primaryEmail
     * @param limit default: 20
     * @param skip 
     * @param sortby1Direction 
     * @param sortby1 companyName,primaryEmail
     * @returns DataProviderPaginatedResult 
     * @throws ApiError
     */
    public static dataProviderControllerFindAllv1(
search?: string,
limit?: number,
skip?: number,
sortby1Direction?: 'asc' | 'desc',
sortby1?: any,
): CancelablePromise<DataProviderPaginatedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataProviders',
            query: {
                'search': search,
                'limit': limit,
                'skip': skip,
                'sortby1Direction': sortby1Direction,
                'sortby1': sortby1,
            },
        });
    }

    /**
     * @param id 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerFindOne(
id: string,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dataProviders/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerUpdate(
id: string,
requestBody: UpdateDataProviderDto,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/dataProviders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerRemove(
id: string,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dataProviders/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param withConnectedConsumers 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerFindOneV1(
id: string,
withConnectedConsumers: boolean,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataProviders/{id}',
            path: {
                'id': id,
            },
            query: {
                'withConnectedConsumers': withConnectedConsumers,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerAddDataProviderUser(
id: string,
requestBody: CreateDataProviderUserDto,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dataProviders/addProviderUser/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param userId 
     * @param userRole 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerAddDataProviderUserV1(
id: string,
userId: string,
userRole: 'USER' | 'ADMIN',
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataProviders/addProviderUser/{id}',
            path: {
                'id': id,
            },
            query: {
                'userId': userId,
                'userRole': userRole,
            },
        });
    }

    /**
     * @param id 
     * @param userId 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerRemoveDataProviderUser(
id: string,
userId: string,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataProviders/removeProviderUser/{id}',
            path: {
                'id': id,
            },
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param id 
     * @param dataConsumerId 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerAddConsumer(
id: string,
dataConsumerId: string,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataProviders/addConsumer/{id}',
            path: {
                'id': id,
            },
            query: {
                'dataConsumerId': dataConsumerId,
            },
        });
    }

    /**
     * @param id 
     * @param dataConsumerId 
     * @returns DataProvider 
     * @throws ApiError
     */
    public static dataProviderControllerRemoveConsumer(
id: string,
dataConsumerId: string,
): CancelablePromise<DataProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataProviders/removeConsumer/{id}',
            path: {
                'id': id,
            },
            query: {
                'dataConsumerId': dataConsumerId,
            },
        });
    }

    /**
     * @param id 
     * @returns string 
     * @throws ApiError
     */
    public static dataProviderControllerGetDataProviderUniquePriceTypesV1(
id: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataProviders/getDistinctPriceTypes/{id}',
            path: {
                'id': id,
            },
        });
    }

}
