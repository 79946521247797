/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDataConsumerDto } from '../models/CreateDataConsumerDto';
import type { CreateDataConsumerUserDto } from '../models/CreateDataConsumerUserDto';
import type { DataConsumer } from '../models/DataConsumer';
import type { DataConsumerPaginatedResult } from '../models/DataConsumerPaginatedResult';
import type { UpdateDataConsumerDto } from '../models/UpdateDataConsumerDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataConsumersService {

    /**
     * @param search companyName,primaryEmail
     * @param limit default: 20
     * @param skip 
     * @param sortby1Direction 
     * @param sortby1 companyName,primaryEmail
     * @returns DataConsumerPaginatedResult 
     * @throws ApiError
     */
    public static dataConsumerControllerFindAllv1(
search?: string,
limit?: number,
skip?: number,
sortby1Direction?: 'asc' | 'desc',
sortby1?: any,
): CancelablePromise<DataConsumerPaginatedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dataConsumers',
            query: {
                'search': search,
                'limit': limit,
                'skip': skip,
                'sortby1Direction': sortby1Direction,
                'sortby1': sortby1,
            },
        });
    }

    /**
     * @param search 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerFindAll(
search?: string,
): CancelablePromise<Array<DataConsumer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dataConsumers',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerCreate(
requestBody: CreateDataConsumerDto,
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dataConsumers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerFindOne(
id: string,
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dataConsumers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerUpdate(
id: string,
requestBody: UpdateDataConsumerDto,
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/dataConsumers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerRemove(
id: string,
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dataConsumers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerAddDataConsumerUser(
id: string,
requestBody: CreateDataConsumerUserDto,
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dataConsumers/addConsumerUser/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param userId 
     * @param userRole 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerAddConsumerUserV1(
id: string,
userId: string,
userRole: 'USER' | 'ADMIN',
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataConsumers/addConsumerUser/{id}',
            path: {
                'id': id,
            },
            query: {
                'userId': userId,
                'userRole': userRole,
            },
        });
    }

    /**
     * @param id 
     * @param userId 
     * @returns DataConsumer 
     * @throws ApiError
     */
    public static dataConsumerControllerRemoveDataConsumerUser(
id: string,
userId: string,
): CancelablePromise<DataConsumer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dataConsumers/removeConsumerUser/{id}',
            path: {
                'id': id,
            },
            query: {
                'userId': userId,
            },
        });
    }

}
