import {
    ChangeEvent,
    useState,
    FormEvent,
    useCallback,
    SyntheticEvent,
} from "react";
import { UpdateDataAgreementDto } from "../services/openapi";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc"; // import utc plugin
import { AgreementLanguageType } from "../utilities/utils";
dayjs.extend(utc);

interface ErrorFields {
    error: boolean;
    errorMessage: string;
}
interface Errors {
    agreementStartDate: ErrorFields;
    agreementEndDate: ErrorFields;
    priceType: ErrorFields;
    language: ErrorFields;
    priceFactor: ErrorFields;
}

export const useFormEditAgreement = (
    initialValues: UpdateDataAgreementDto,
    onSubmitHandler: (data: UpdateDataAgreementDto) => void
) => {
    const [values, setValues] = useState<UpdateDataAgreementDto>(initialValues);
    const [formErrors, setFormErrors] = useState<Errors>({
        agreementStartDate: {
            error: false,
            errorMessage: "",
        },
        agreementEndDate: {
            error: false,
            errorMessage: "",
        },
        priceType: {
            error: false,
            errorMessage: "",
        },
        language: {
            error: false,
            errorMessage: "",
        },
        priceFactor: {
            error: false,
            errorMessage: "",
        },
    });
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    };

    const onLanguageChangeHandler = (
        e: SyntheticEvent,
        value: AgreementLanguageType | null
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                language: value.code,
            }));
        }
    };

    const onPriceTypeChangeHandler = (
        e: SyntheticEvent,
        value: string | undefined
    ) => {
        if (value) {
            setValues((state) => ({
                ...state,
                priceType: value,
            }));
        }
    };
    const onDimensionsChangeHandler = (
        e: SyntheticEvent,
        value: string | undefined
    ) => {
        if (value) {
            value === "Metric (cm)"
                ? setValues((state) => ({ ...state, isMetric: true }))
                : setValues((state) => ({ ...state, isMetric: false }));
        }
    };

    const onStartDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                agreementStartDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };
    const onEndDateChangeHandler = (date: Dayjs | null) => {
        if (date) {
            setValues((state) => ({
                ...state,
                agreementEndDate: dayjs(date)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
            }));
        }
    };
    const changeValues = useCallback((newValues: UpdateDataAgreementDto) => {
        setValues({
            agreementStartDate: newValues.agreementStartDate,
            agreementEndDate: newValues.agreementEndDate,
            priceType: newValues.priceType,
            language: newValues.language,
            priceFactor: newValues.priceFactor
                .toFixed(2)
                .replace(".", ",") as unknown as number,
            isMetric: newValues.isMetric,
        });
    }, []);

    const validateValues = () => {
        const priceFactorRegex = /^\d+([.,]\d{1,2})?$/;
        const isStartDateBeforeEndDate = dayjs(values.agreementStartDate)
            .startOf("day")
            .isBefore(dayjs(values.agreementEndDate).startOf("day"));

        const newErrors: Errors = {
            agreementStartDate: {
                error: false,
                errorMessage: "",
            },
            agreementEndDate: {
                error: false,
                errorMessage: "",
            },
            priceType: {
                error: false,
                errorMessage: "",
            },
            language: {
                error: false,
                errorMessage: "",
            },
            priceFactor: {
                error: false,
                errorMessage: "",
            },
        };
        let isValid = true;

        if (!isStartDateBeforeEndDate) {
            newErrors.agreementEndDate.error = true;
            newErrors.agreementEndDate.errorMessage =
                "Agreement end date must be greater than agreement start date";
            isValid = false;
        }
        if (!values.priceType) {
            newErrors.priceType.error = true;
            newErrors.priceType.errorMessage = "This field is required";
            isValid = false;
        }
        if (!priceFactorRegex.test(values.priceFactor.toString())) {
            newErrors.priceFactor.error = true;
            newErrors.priceFactor.errorMessage =
                "Please enter a valid price factor format. Only numbers with max 2 decimal places";
            isValid = false;
        }

        if (values.language === "UX") {
            newErrors.language.error = true;
            newErrors.language.errorMessage = "This field is required";
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateValues()) {
            return;
        }
        onSubmitHandler(values);
    };

    return {
        values,
        onSubmit,
        onChangeHandler,
        onStartDateChangeHandler,
        onEndDateChangeHandler,
        onLanguageChangeHandler,
        onDimensionsChangeHandler,
        changeValues,
        onPriceTypeChangeHandler,
        formErrors,
    };
};
