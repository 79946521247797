import Sidebar from "../components/sidebar/Sidebar";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

function RootPage() {
    return (
        <Box
            sx={{
                display: "flex",
                minHeight: "100vh",
                bgcolor: "#fcfcfc",
                color: "black",
                justifyContent: "center",
            }}
        >
            <Sidebar />
            <Box
                component="main"
                sx={{
                    bgcolor: "#ffffff",
                    my: 12,
                    mx: 5,
                    // mb: 5,
                    flex: 1,
                    // alignItems: "center",
                    borderRadius: 2,
                    // borderColor: "#f1f1f2",
                    overflow: "hidden",
                    maxWidth: "1200px",
                    // overflowY: "auto",
                    // maxHeight: "calc(100vh - 120px)",
                    border: "1px solid #f1f1f4",
                    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",
                    px: 2,
                    py: 3,
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}

export default RootPage;
