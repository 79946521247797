/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UploadFileInfo } from '../models/UploadFileInfo';
import type { UploadImageInfo } from '../models/UploadImageInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UploadService {

    /**
     * @param bytes 
     * @param imageExtension 
     * @returns UploadImageInfo 
     * @throws ApiError
     */
    public static uploadControllerUploadImage(
bytes: number,
imageExtension?: string,
): CancelablePromise<UploadImageInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/upload/image',
            query: {
                'bytes': bytes,
                'imageExtension': imageExtension,
            },
        });
    }

    /**
     * @param bytes 
     * @param uploadBundleId 
     * @param dataProviderId 
     * @param imageExtension 
     * @returns UploadImageInfo 
     * @throws ApiError
     */
    public static uploadControllerUploadBundleImageUpload(
bytes: number,
uploadBundleId: string,
dataProviderId: string,
imageExtension?: string,
): CancelablePromise<UploadImageInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/upload/bundleImage',
            query: {
                'bytes': bytes,
                'uploadBundleId': uploadBundleId,
                'dataProviderId': dataProviderId,
                'imageExtension': imageExtension,
            },
        });
    }

    /**
     * @param bytes 
     * @returns UploadFileInfo 
     * @throws ApiError
     */
    public static uploadControllerUploadFile(
bytes: number,
): CancelablePromise<UploadFileInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/upload/file',
            query: {
                'bytes': bytes,
            },
        });
    }

}
