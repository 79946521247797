import { EnhancedTableToolbarProps } from "../../utilities/types";
import { Toolbar, Typography, Button } from "@mui/material";
import { multiSelectCheck } from "../../utilities/utils";
import Swal from "sweetalert2";
import "../../utilities/sweet-alert.css";

export function DeleteAgreementsToolbar(props: EnhancedTableToolbarProps) {
    const handleClickOpen = () => {
        Swal.fire({
            title: "Are you sure you want to delete selected agreements?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, cancel",
            confirmButtonText: "Yes, delete!",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteClick();
            } else {
                handleCloseClick();
            }
        });
    };

    const { numSelected, selected, handleDeleteClick, handleCloseClick } =
        props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
                gap: 2,
            }}
        >
            <Typography variant="subtitle1" component="div" fontWeight={"bold"}>
                {multiSelectCheck(selected)
                    ? `${numSelected} agreements selected`
                    : "1 agreement selected"}
            </Typography>

            <Button
                variant="contained"
                sx={{
                    textTransform: "none",
                    bgcolor: "#F8285A",
                    fontWeight: "bold",
                    ":hover": { bgcolor: "#D81A48" },
                }}
                onClick={handleClickOpen}
            >
                Delete Selected
            </Button>
        </Toolbar>
    );
}
